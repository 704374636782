/*!

=========================================================
* Now UI Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard/Dashboard.jsx";
import Buttons from "views/Components/Buttons.jsx";
import GridSystem from "views/Components/GridSystem.jsx";
import Panels from "views/Components/Panels.jsx";
import SweetAlert from "views/Components/SweetAlertPage.jsx";
import Icons from "views/Components/Icons.jsx";
import Typography from "views/Components/Typography.jsx";
import RegularForms from "views/Forms/RegularForms.jsx";
import ExtendedForms from "views/Forms/ExtendedForms.jsx";
import ValidationForms from "views/Forms/ValidationForms.jsx";
import Wizard from "views/Forms/Wizard/Wizard.jsx";
import RegularTables from "views/Tables/RegularTables.jsx";
import ExtendedTables from "views/Tables/ExtendedTables.jsx";
import ReactTable from "views/Tables/ReactTable.jsx";
import GoogleMaps from "views/Maps/GoogleMaps.jsx";
import FullScreenMap from "views/Maps/FullScreenMap.jsx";
import Charts from "views/Charts/Charts.jsx";
import CalendarX from "views/Calendar/CalendarX.jsx";
import Widgets from "views/Widgets/Widgets.jsx";
import Profile from "views/Users/Profile.jsx";
import TimelinePage from "views/Pages/TimelinePage.jsx";
import RTL from "views/Pages/RTL.jsx";
import PricingPage from "views/Pages/PricingPage.jsx";
import LoginPage from "views/Users/LoginPage.jsx";
import HelpLogin from "views/Users/helpLogin.jsx";
import PasswordRecovery from "views/Users/PasswordRecovery";
import RegisterPage from "views/Users/RegisterPage.jsx";
import LockScreenPage from "views/Users/LockScreenPage.jsx";
import Welcome from "views/Pages/Welcome";
import Schedule from "views/Schedule/Schedule";
import DoctorList from "views/Doctors/DoctorList";
//Public
import PhysicianPage from "views/Pages/PhysicianPage";
import DevicesWizard from "views/Devices/DevicesBooking/DevicesWizard";

import Settings from "views/Users/Settings.jsx";
import Inbox from "views/Messages/Inbox";
import AppointmentsView from "views/Appointments/AppointmentsView";
import Notes from "views/Notes/Notes";
import SessionManager from 'views/Users/SessionManager';
import IFrameComponent from 'components/iframetest/IFrameComponent';
import LaboratoryStudies from 'views/Laboratories/LaboratoryStudies';
import LaboratoryDetail from "views/Laboratories/LaboratoryDetail";
import UserDeleteConfirmation from "views/Users/UserDeleteConfirmation";
import UserDeletePreparation from "views/Users/UserDeletePreparation";
import VerificationPage from "views/Users/VerificationPage";
import ProfileInitial from "views/Users/ProfileInitial";

let routes = [
  {
    path: "/welcome",
    name: "Welcome",
    icon: "now-ui-icons business_bank",
    component: Welcome,
    layout: "/admin",
    invisible: true,
  },
  {
    _id: 14,
    path: "/dashboard",
    name: "Dashboard",
    icon: "icon-d",
    invisible: false,
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/initial",
    name: "Initial popup",
    icon: "now-ui-icons business_bank",
    component: ProfileInitial,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/delete-confirmation",
    name: "Delete confirmation",
    icon: "now-ui-icons business_bank",
    component: UserDeleteConfirmation,
    layout: "/admin",
    invisible: true,
  },
  {
    _id: 1,
    path: '/doctors',
    name: 'Doctors',
    icon: 'icon-user-active',
    invisible: false,
    component: DoctorList,
    layout: '/admin',
  },
  {
    _id: 2,
    path: "/frametest/:doctor?",
    name: "iFrame",
    icon: "now-ui-icons business_bank",
    component: IFrameComponent,
    layout: "/admin",
    invisible: false,
  },
  {
    _id: 3,
    path: "/lab-studies",
    name: "Laboratory",
    icon: "icon-microscope",
    invisible: false,
    component: LaboratoryStudies,
    layout: "/admin",
  },
  {
    _id: 29,
    path: "/inbox",
    name: "Inbox",
    icon: "icon-inbox",
    invisible: false,
    component: Inbox,
    layout: "/admin",
  },
  {
    path: "/schedule",
    name: "Schedule",
    icon: "now-ui-icons  media-1_album",
    component: Schedule,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/sessions",
    name: "Sessions",
    icon: "now-ui-icons  media-1_album",
    component: SessionManager,
    layout: "/admin",
    invisible: true,
  },
  {
    collapse: true,
    path: "/pages",
    name: "Pages",
    state: "openPages",
    icon: "now-ui-icons design_image",
    invisible: true,
    views: [
      {
        path: "/timeline-page",
        name: "Timeline Page",
        mini: "TP",
        component: TimelinePage,
        layout: "/admin",
      },
      {
        path: "/profile",
        name: "User Profile",
        mini: "UP",
        component: Profile,
        layout: "/admin",
      },
      {
        path: "/rtl-support",
        name: "RTL Support",
        mini: "RS",
        component: RTL,
        layout: "/admin",
      },
      {
        path: "/register-page",
        name: "Register Page",
        short: "Register",
        mini: "RP",
        component: RegisterPage,
        layout: "/auth",
      },
      {
        path: "/login-page/:data?",
        name: "Login Page",
        short: "Login",
        mini: "LP",
        component: LoginPage,
        layout: "/auth",
      },
      {
        path: "/login-help",
        name: "Help Page",
        short: "Help",
        mini: "HL",
        component: HelpLogin,
        layout: "/auth",
      },
      {
        path: "/verification-page/:token?",
        name: "Verification Page",
        short: "Verification",
        mini: "VP",
        component: VerificationPage,
        layout: "/auth",
      },
      {
        path: "/delete-preparation/:token?",
        name: "Deletion Preparation",
        short: "Deletion",
        mini: "DC",
        component: UserDeletePreparation,
        layout: "/auth",
      },
      {
        path: '/forgot-password',
        name: 'Password Recovery Page',
        short: 'Recovery',
        mini: 'PRP',
        component: PasswordRecovery,
        layout: '/auth',
      },
      {
        path: "/pricing-page",
        name: "Pricing Page",
        short: "Pricing",
        mini: "PP",
        component: PricingPage,
        layout: "/auth",
        invisible: true,
      },
      {
        path: "/lock-screen-page",
        name: "Lock Screen Page",
        short: "Lock",
        mini: "LSP",
        component: LockScreenPage,
        layout: "/auth",
        invisible: true,
      },
    ],
  },
  {
    collapse: true,
    path: "/components",
    name: "Components",
    state: "openComponents",
    icon: "now-ui-icons education_atom",
    invisible: true,
    views: [
      {
        path: "/buttons",
        name: "Buttons",
        mini: "B",
        component: Buttons,
        layout: "/admin",
      },
      {
        path: "/grid-system",
        name: "Grid System",
        mini: "GS",
        component: GridSystem,
        layout: "/admin",
      },
      {
        path: "/panels",
        name: "Panels",
        mini: "P",
        component: Panels,
        layout: "/admin",
      },
      {
        path: "/sweet-alert",
        name: "Sweet Alert",
        mini: "SA",
        component: SweetAlert,
        layout: "/admin",
      },
      {
        path: "/icons",
        name: "Icons",
        mini: "I",
        component: Icons,
        layout: "/admin",
      },
      {
        path: "/typography",
        name: "Typography",
        mini: "T",
        component: Typography,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    path: "/forms",
    name: "Forms",
    state: "openForms",
    icon: "now-ui-icons design_bullet-list-67",
    invisible: true,
    views: [
      {
        path: "/regular-forms",
        name: "Regular Forms",
        mini: "RF",
        component: RegularForms,
        layout: "/admin",
      },
      {
        path: "/extended-forms",
        name: "Extended Forms",
        mini: "EF",
        component: ExtendedForms,
        layout: "/admin",
      },
      {
        path: "/validation-forms",
        name: "Validation Forms",
        mini: "VF",
        component: ValidationForms,
        layout: "/admin",
      },
      {
        path: "/wizard",
        name: "Wizard",
        mini: "W",
        component: Wizard,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    path: "/tables",
    name: "Tables",
    state: "openTables",
    icon: "now-ui-icons files_single-copy-04",
    invisible: true,
    views: [
      {
        path: "/regular-tables",
        name: "Regular Tables",
        mini: "RT",
        component: RegularTables,
        layout: "/admin",
      },
      {
        path: "/extended-tables",
        name: "Extended Tables",
        mini: "ET",
        component: ExtendedTables,
        layout: "/admin",
      },
      {
        path: "/react-table",
        name: "React Table",
        mini: "RT",
        component: ReactTable,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    path: "/maps",
    name: "Maps",
    state: "openMaps",
    icon: "now-ui-icons location_pin",
    invisible: true,
    views: [
      {
        path: "/google-maps",
        name: "Google Maps",
        mini: "GM",
        component: GoogleMaps,
        layout: "/admin",
      },
      {
        path: "/full-screen-maps",
        name: "Full Screen Map",
        mini: "FSM",
        component: FullScreenMap,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/widgets",
    name: "Widgets",
    icon: "now-ui-icons objects_diamond",
    invisible: true,
    component: Widgets,
    layout: "/admin",
  },
  {
    path: "/charts",
    name: "Charts",
    icon: "now-ui-icons business_chart-pie-36",
    invisible: true,
    component: Charts,
    layout: "/admin",
  },
  {
    path: "/calendar",
    name: "Calendar",
    icon: "now-ui-icons media-1_album",
    invisible: true,
    component: CalendarX,
    layout: "/admin",
  },

  // new views
  {
    path: "/appointments",
    name: "Appointments",
    icon: "icon-appointments",
    invisible: true,
    component: AppointmentsView,
    layout: "/admin",
  },
  {
    _id: 29,
    path: "/inbox/reply",
    name: "Inbox",
    icon: "icon-inbox",
    invisible: true,
    component: Inbox,
    layout: "/admin",
  },
  {
    path: "/lab-studies-detail/:reportId?/:review?",
    name: "LaboratoryDetail",
    icon: "icon-microscope",
    invisible: true,
    component: LaboratoryDetail,
    layout: "/admin",
  },
  {
    path: "/waiting-room",
    name: "Waiting Room",
    icon: "icon-waiting-room",
    invisible: true,
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/notes",
    name: "Notes",
    icon: "icon-note",
    invisible: true,
    component: Notes,
    layout: "/admin",
  },
  /**
   * For public pages
   */
  {
    key: "physician-page",
    path: "/physician-page",
    name: "Physician Profile",
    icon: "now-ui-icons business_bank",
    invisible: true,
    component: PhysicianPage,
    layout: "/public",
  },
  {
    key: "devices-page",
    path: "/devices",
    name: "Devices Page",
    icon: "now-ui-icons icon-appointments",
    invisible: true,
    component: DevicesWizard,
    layout: "/admin",
  },
  {
    _id: 32,
    path: "/profile",
    name: "Profile",
    icon: "icon-profile",
    invisible: false,
    component: Profile,
    layout: "/admin",
  },
  {
    _id: 34,
    path: "/settings",
    name: "User Settings",
    icon: "icon-settings1",
    invisible: false,
    component: Settings,
    layout: "/admin",
  }
];

export default routes;
