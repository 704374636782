import React from 'react';
import { useTranslate } from 'react-translate';
import { Button } from 'reactstrap';
const BtnReply = ({ mobile, setShowForm, disabled }) => {
  const t = useTranslate('messages');
  return (
    <Button
      className="btn-reply"
      color="info"
      name="btn-menu-reply"
      onClick={() => {
        setShowForm(true);
      }}
      disabled={disabled}>
      {!mobile && t('btn-reply')} <i className="fas fa-reply"></i>
    </Button>
  );
};

export default BtnReply;
