import CONSTANTS from "../CONSTANTS";

export const actionProfileSave = (profile) => ({
  type: CONSTANTS.PROFILE_SAVE,
  profile,
});

export const actionEditProfile = (profile) => ({
  type: CONSTANTS.PROFILE_EDIT,
  profile,
});

export const actionGetProfile = () => ({
  type: CONSTANTS.PROFILE_GET,
});

export const actionSetProfile = (profile) => ({
  type: CONSTANTS.PROFILE_SET,
  profile,
});

export const actionSetLoadingProfile = (loading) => ({
  type: CONSTANTS.PROFILE_LOADING,
  loading,
});

//Address
export const actionAddressSave = (address) => ({
  type: CONSTANTS.ADDRESS_SAVE,
  address,
});
export const actionAddressDelete = (id) => ({
  type: CONSTANTS.ADDRESS_DELETE,
  id,
});
