import { useEffect, useState } from 'react';
import nativeSender from 'helpers/nativeSender';

const useNativeReceiver = ( initialState = { 
  action: "init", 
  data: {
    version: localStorage.getItem("ReactNativeAppVersion") 
  }
}) => {
  const [data, setData] = useState(initialState);

  const updateData = (e) => {
    setData(JSON.parse(e.data));
  };

  useEffect(() => {
    window.addEventListener("nativeReceive", updateData);
    nativeSender.send("RECEIVER_READY");

    return () => {
        window.removeEventListener("nativeReceive", updateData);
    }
  }, []);

  return data;
}

export default useNativeReceiver;