import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col
} from 'reactstrap';

import { actionGetProfile } from 'store/actions/profilesActions';
import Icon from 'components/Icons/Icon';
import { useTranslate } from 'react-translate';
import storageDispatcher from "helpers/storageDispatcher";
import { useHistory } from 'react-router-dom';
import moment from "moment";
import Dates from "../../helpers/Dates";

const ProfileInitial = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isMount = useRef(true);

  const { profile } = useSelector((state) => state.profilesReducer);
  const user = useSelector((state) => state.userReducer);

  const user_id = user._id ? user._id : null;

  const companyType = Object.keys(profile).length && profile.company.type ? profile.company.type.toLowerCase().replace(" ", "-") : "";

  const t = useTranslate('profile');
  const addressT = useTranslate('profile-address');

  useEffect(() => {
    if (isMount.current && user_id) {
      dispatch(actionGetProfile());
    }
    return () => {
      isMount.current = false;
    };
  }, [dispatch, user_id]);

  useEffect(() => {
    if (!localStorage.getItem("pending_initial_choice")) {
      history.replace(
        user.on_delete ? 
        "/admin/delete-confirmation" : 
        !user.is_ready ? 
        "/admin/profile" : 
        user.tokenInfo ?
        "/admin/laboratories" :
        "/admin/dashboard"
      );
    }
  }, []);

  return (
    <div className="content dashboard">
      <Card>
        <CardHeader>
          <CardTitle className="d-flex align-content-center">
              <div className="general-card-title-img text-primary mr-2">
                  <i className=" icon-edit-settings" />
              </div>
              <div className="general-card-title text-primary">
                Datencheck
              </div>
          </CardTitle>
        </CardHeader>

        <CardBody>
          <h3 className="d-flex center-buttons">
            Alle Daten korrekt/aktuell?
          </h3>

          <div className="center-buttons">
            <Card className="profile-card">
              <CardHeader>
                <CardTitle className="d-flex align-content-center">
                    <div className="general-card-title-img text-primary mr-2">
                        <i className=" icon-user-active" />
                    </div>
                    <div className="general-card-title text-primary">
                      Ihre Daten
                    </div>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <p><b>{`${user.pronoun ? user.pronoun : ""} ${user.title ? user.title : ""} ${user.firstName} ${user.lastName}`}</b></p>
                <p><b>{t(`gender-${user.gender.toLowerCase()}`)}</b></p>
                <p><b><span>*</span>{moment(Dates.parseDate(user.birthday)).format("DD. MM. YYYY")}</b></p>
                <p><b>{user.phone}</b></p>
                {profile.company ? (
                <>
                  {profile.company.name && (
                    <p><b>{profile.company.name}</b></p>
                  )}

                  {companyType.length ? (
                    <p><b>{addressT(companyType)}</b></p>
                  ): ""}
                
                  {profile.company.number && (
                    <p><b><span>Versich. Nr. </span>{profile.company.number}</b></p>
                  )}
                  
                </>
              ) : (
                <p>No Unternehmen</p>
              )}
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle className="d-flex align-content-center">
                    <div className="general-card-title-img text-primary mr-2">
                        <i className="icon-note" />
                    </div>
                    <div className="general-card-title text-primary">
                      Anschrift
                    </div>
                </CardTitle>
              </CardHeader>
              <CardBody>
              {profile.address?.length ? (
                <>
                  <p><b>{`${profile.address[0].firstName} ${profile.address[0].lastName}`}</b></p>
                  <p>
                    <b>
                      {profile.address[0].scope !== 'packstation' &&
                      `${profile.address[0].street} ${profile.address[0].streetNumber}`}
                      {profile.address[0].scope === 'packstation' &&
                      `${profile.address[0].packstation} ${profile.address[0].packstationNumber}`}
                    </b>
                  </p>
                  <p><b>{profile.address[0].postalCode} - {profile.address[0].city}</b></p>
                  <p>
                    <b>
                    {profile.address[0].country && typeof profile.address[0].country.label !== 'undefined'
                      ? profile.address[0].country.label
                      : isNaN(profile.address[0].country.value) ? profile.address[0].country.value : ''}
                    </b>
                  </p>
                  <p><b>{profile.address[0].phone}</b></p>
                </>
              ) : (
                <p>No anschrift</p>
              )}
              </CardBody>
            </Card>
          </div>

          
          <div className="d-flex center-buttons">
            
            <Button
              color="primary"
              onClick={e => {
                storageDispatcher.removeLocalStorageEvent("pending_initial_choice");
                history.replace("/admin/profile");
              }}
            >
              Korrigieren
            </Button>

            <Button
              color="info"
              onClick={e => {
                storageDispatcher.removeLocalStorageEvent("pending_initial_choice");
                history.replace(
                  user.on_delete ? 
                  "/admin/delete-confirmation" : 
                  !user.is_ready ? 
                  "/admin/profile" : 
                  user.tokenInfo ?
                  "/admin/laboratories" :
                  "/admin/dashboard"
                );
              }}
            >
              Ja, weiter
            </Button>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default ProfileInitial;
