"use strict"

const BOSTable = {
    "Male": 1,
    "Female": 2,
    "Other": 3
};

const getSex = (gender) => {
    return BOSTable[gender];
};

module.exports = { getSex };