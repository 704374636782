import React from "react";
import ContactImage from "./ContactImage";
import ContactAll from "./ContactAll";
const ContactsHotizontal = () => {
  return (
    <div>
      <div className="contact_list">
        <ContactAll active />
        <ContactImage
          doctorName="J.von Braunmühl"
          avatar="/General/jutta-big.png"
          active
        />
        <ContactImage
          doctorName="P.Bergenthal"
          avatar="/General/bergenthal.png"
        />
        <ContactImage doctorName="M.Müller" avatar="/General/muller.png" />
        <ContactImage doctorName="T.Cicekci" avatar="/General/ciceki.png" />
        <ContactImage
          doctorName="A.Schröder"
          avatar="/General/schoedrrer.png"
        />
      </div>
    </div>
  );
};

export default ContactsHotizontal;
