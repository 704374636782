import AxiosService from "./AxiosService";
class MessagesService extends AxiosService {
  constructor() {
    super("messages");
  }

  /**
   * Get messages from inbox
   */
  inbox = async (practiceId, page = 1, limit = 20, search = null) => {
    return await this.list(practiceId, page, limit, "inbox", false, search);
  };

  /**
   * Get messages from priority box
   */
  priority = async (practiceId, page = 1, limit = 20, search = null) => {
    return await this.list(practiceId, page, limit, "inbox", true, search);
  };

  /**
   * Get messages from inbox
   */
  outbox = async (practiceId, page = 1, limit = 20, search = null) => {
    return await this.list(practiceId, page, limit, "outbox", false, search);
  };

  /**
   * Get message list
   * @param {integer} page page number
   * @param {number} limit max documents about page
   * @param {string} box_type box name
   * @param {boolean} priority it's priority?
   * @param {string} search search param
   */
  list = async (
    practiceId,
    page = 1,
    limit = 20,
    box_type = "inbox",
    priority = false,
    search = null
  ) => {
    let url = `/${box_type}?page=${page}&limit=${limit}&priority=${priority}`;
    url = search ? url + `&search=${search}` : url;
    let headers = {
      Authorization: `Bearer ${this.getToken()}`,
    };
    if (practiceId) {
      headers.practice = practiceId;
    }
    const response = await this.get(url, {
      headers,
    });
    return response.data.messages;
  };

  /**
   * Delete message
   */
  del = async (practiceId, messages, box_type = "inbox") => {
    let headers = {
      Authorization: `Bearer ${this.getToken()}`,
    };
    if (practiceId) {
      headers.practice = practiceId;
    }
    const response = await this.post(`/delete/${box_type}`, messages, {
      headers,
    });
    return response;
  };

  /**
   * Get mesage
   *
   * box_type = inbox|outbox
   */
  getById = async (practiceId, id = null, box_type = "inbox") => {
    let headers = {
      Authorization: `Bearer ${this.getToken()}`,
    };
    if (practiceId) {
      headers.practice = practiceId;
    }
    const response = await this.get(`/${box_type}/${id}`, {
      headers,
    })
      .then((result) => result.data)
      .catch((error) => {
        return { ...error.response.data, statusCode: error.response.status };
      });
    return response;
  };

  /**
   * Reply message
   */
  reply = async (practiceId, message) => {
    let formData = new FormData();
    Object.keys(message).forEach((key) => {
      if (key !== "attachments") formData.append(key, message[key]);
    });

    if (Array.isArray(message.attachments)) {
      for (let i = 0; i < message.attachments.length; i++) {
        if (message.attachments[i]) {
          formData.append("file." + i, message.attachments[i][0]);
        }
      }
    }
    let headers = {
      Authorization: `Bearer ${this.getToken()}`,
      "Content-Type": "multipart/form-data",
    };
    if (practiceId) {
      headers.practice = practiceId;
    }
    return await this.post(`/`, formData, {
      headers,
    });
  };

  setPriority = async (practiceId, messages) => {
    let headers = {
      Authorization: `Bearer ${this.getToken()}`,
    };
    if (practiceId) {
      headers.practice = practiceId;
    }
    const response = await this.post(
      `/priority`,
      { messages },
      {
        headers,
      }
    );
    return response;
  };

  deletePriority = async (practiceId, messages) => {
    let headers = {
      Authorization: `Bearer ${this.getToken()}`,
    };
    if (practiceId) {
      headers.practice = practiceId;
    }
    const response = await this.put(
      `/priority`,
      { messages },
      {
        headers,
      }
    );
    return response;
  };
}

export default new MessagesService();
