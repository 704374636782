import moment from "moment";

class Dates {
  dateDiff(startDate, endDate) {
    //define moments for the startDate and endDate
    let startDateMoment = moment(startDate);
    let endDateMoment = moment(endDate);

    if (
      startDateMoment.isValid() === true &&
      endDateMoment.isValid() === true
    ) {
      //getting the difference in years
      let years = endDateMoment.diff(startDateMoment, "years");

      //to calculate the months, first get the previous year and then subtract it
      startDateMoment.add(years, "years");
      let months = endDateMoment.diff(startDateMoment, "months");

      //to calculate the days, first get the previous month and then subtract it
      startDateMoment.add(months, "months");
      let days = endDateMoment.diff(startDateMoment, "days");

      //Similar to days go the previous day
      startDateMoment.add(days, "days");
      let hours = endDateMoment.diff(startDateMoment, "hours");

      //Getting minutes
      startDateMoment.add(hours, "hours");
      let minutes = endDateMoment.diff(startDateMoment, "minutes");

      //Similar to days go the previous day
      startDateMoment.add(minutes, "minutes");
      let seconds = endDateMoment.diff(startDateMoment, "seconds");

      return {
        years: years,
        months: months,
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      };
    } else {
      return undefined;
    }
  }

  isThisDay(startDate, endDate) {
    const result = this.dateDiff(startDate, endDate);
    let startDateMoment = moment(startDate);
    let endDateMoment = moment(endDate);
    if (
      startDateMoment.isValid() === true &&
      endDateMoment.isValid() === true
    ) {
      if (
        result.years === 0 &&
        result.months === 0 &&
        startDateMoment.date() === endDateMoment.date()
      ) {
        return true;
      }
    }
    return false;
  }

  getTop(startDate, min) {
    const height = 60 / 3; // Height by hour in px
    let start = moment(startDate);
    if (start.isValid()) {
      let aux =
        parseInt(start.format("HH")) * height + parseInt(start.format("m"));
      return aux - min;
    }
    return 0;
  }

  parseDate(date) {
    let result = "";
    for (let i = 10000000, j = 0; j < 8; i /= 10, j++){
      result += Math.floor((date / i) % 10);
      if ([3,5].includes(j)){
        result += "-";
      }
    }
    return result;
  };
}

export default new Dates();
