import React, { useState } from 'react';

//components
import Actions from '../Actions/Actions';
import InboxViewList from './InboxViewList';
import InboxViewDetail from './InboxViewDetail';
import InboxMobileDetail from './InboxMobileDetail';
import InboxViewForm from './InboxViewForm';
import { useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import InboxSearcher from '../InboxSearcher';

const InboxBody = ({ showForm, setShowForm }) => {
  // eslint-disable-next-line
  const [replyTo, setReplyTo] = useState({
    tags: [],
  });

  const { mobile } = useSelector((state) => state.commonReducer);
  const { message, box } = useSelector((state) => state.messagesReducer);

  const setReplyToTags = (to) => {
    setReplyTo({
      ...setReplyTo,
      tags: to,
    });
  };

  return (
    <>
      {/* <Actions showForm={showForm} setShowForm={setShowForm} mobile={mobile} /> */}
      <Row>
        <Col sm={12} md={4}>
          <InboxSearcher box={box} />
        </Col>
        <Col sm={12} md={8}>
          <Actions setShowForm={setShowForm} mobile={mobile} />
        </Col>
      </Row>
      {!message && !showForm && (
        <InboxViewList setShowForm={setShowForm} mobile={mobile} />
      )}
      {message &&
        !showForm &&
        (mobile ? (
          <InboxMobileDetail
            setReplyToTags={setReplyToTags}
            setShowForm={setShowForm}
            mobile={mobile}
          />
        ) : (
          <InboxViewDetail
            key={0}
            setReplyToTags={setReplyToTags}
            mobile={mobile}
          />
        ))}

      {showForm && (
        <InboxViewForm
          setReplyToTags={setReplyToTags}
          setShowForm={setShowForm}
          mobile={mobile}
        />
      )}
    </>
  );
};

export default InboxBody;
