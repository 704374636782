import CONSTANTS from "../CONSTANTS";

export const actionSetResponse = response => ({
  type: CONSTANTS.RESPONSE,
  response
});

export const actionResponseClean = () => ({
  type: CONSTANTS.RESPONSE_CLEAN
});
