import React, { Fragment } from "react";
import { translate } from "react-translate";

const ContactAll = () => {
  const t = translate('contact-all');
  return (
    <Fragment>
      <div className="contact-filter-all">
        <div className="contact-all">
          <div className="text-truncate">ALL</div>
          <span className="icon-doctor-active"></span>
        </div>
        <div className="contact_name text-center text-truncate pl-2">
          {t('all-doctors')}
        </div>
      </div>
    </Fragment>
  );
};

export default translate('contact-all')(ContactAll);