import React from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardText,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import PaypalButton from "components/Payments/PaypalButton";

const PaymentSummary = () => {
  return (
    <Row>
      <Col sm={12} md={6} className="d-flex">
        <Card>
          <CardBody>
            <CardTitle tag="h5" className="device text-center text-info">
              Summary
            </CardTitle>
            <CardText>
              Address shipping Lorem ipsum dolor sit amet consectetur
              adipisicing elit. Corporis atque quibusdam animi, eaque eum
              necessitatibus.
            </CardText>
            <CardText>
              Note: Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Labore ipsam iste laborum distinctio nam rerum?
            </CardText>
          </CardBody>
        </Card>
      </Col>
      <Col sm={12} md={6} className="d-flex">
        <Card>
          <CardBody>
            <CardTitle tag="h5" className="amount text-center text-info">
              Total amount 1.22 €
            </CardTitle>
          </CardBody>
          <CardFooter className="text-muted">
            <PaypalButton amount={5} />
          </CardFooter>
        </Card>
      </Col>
    </Row>
  );
};

export default PaymentSummary;
