import React, { useEffect } from 'react';
import { Input, Label, FormGroup } from 'reactstrap';
import { useTranslate } from 'react-translate';

const ProfileInitials = ({ setShortname, shortname}) => {
  const t = useTranslate('profile');
  const validate = (e) => {
    let value = e.target.value;
    value = value.toUpperCase();
    if(value.length > 3){
      value = value.substr(0,3);
    }
    e.target.value = value;
    setShortname(value);
  }

  useEffect(() => {
    document.getElementById("shortname").value = shortname;
  }, [shortname]);
  
  return (
    <div style={{marginTop: "11px"}}>
      <Label>{t('shortname')}</Label>
      <FormGroup className="has-label">
        <Input 
          defaultValue={shortname} 
          name="shortname_input" 
          id="shortname"
          onChange={(e) => {
            validate(e);
          }}
        />
      </FormGroup>
    </div>
  );
};

export default ProfileInitials;
