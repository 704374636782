import React from "react";
import { Button } from "reactstrap";
import { useTranslate } from 'react-translate';

const BtnEditNote = () => {
  const t = useTranslate('buttons');

  return (
    <Button size="sm" color="info">
      {t('edit-note')} <i className="icon-pencil"></i>
    </Button>
  );
};

export default BtnEditNote;
