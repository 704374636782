import React from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { FormGroup, Label, ListGroupItem } from 'reactstrap';
import BtnPriority from '../Actions/BtnPriority';

//Actions
import {
  actionGetMessage,
  actionGetOutboxMessage,
  actionSetSelectedMessages,
} from 'store/actions/messagesActions';
//Helpers
import Dates from 'helpers/Dates';

const InboxMobileViewListItem = (props) => {
  const { item } = props;

  const dispatch = useDispatch();

  const { selectedMessages, box } = useSelector(
    (state) => state.messagesReducer
  );

  const showMessage = (id) => {
    if (box === 'outbox') {
      dispatch(actionGetOutboxMessage(id));
    } else {
      dispatch(actionGetMessage(id));
    }
  };

  const handleCheckbox = (event, message_id) => {
    let newSelectedMessages = [];

    if (event.target.checked) {
      newSelectedMessages = [...selectedMessages, message_id];
    } else {
      newSelectedMessages = selectedMessages.filter((item) => {
        return item !== message_id;
      });
    }

    dispatch(actionSetSelectedMessages(newSelectedMessages));
  };

  let diff = Dates.dateDiff(item.date, moment());
  let dateCondition = diff.years === 0 && diff.months === 0 && diff.days <= 2;
  const key = item._id;

  return (
    <ListGroupItem
      id={`item-${key}`}
      data-id={key}
      className="inbox-message"
      onClick={(e) => {
        if (
          e.target.nodeName !== 'INPUT' &&
          e.target.nodeName !== 'SPAN' &&
          e.target.nodeName !== 'IMG'
        ) {
          showMessage(item._id, key);
        }
      }}
    >
      <div className="d-flex flex-wrap">
        <FormGroup check className="message-select">
          <Label check>
            <input
              className="form-control"
              type="checkbox"
              data-id={key}
              checked={selectedMessages.includes(item._id)}
              onChange={(e) => handleCheckbox(e, item._id)}
            />
            <span className="form-check-sign" />
          </Label>
        </FormGroup>

        {/* Toggle priority */}
        {box !== 'outbox' && (
          <React.Fragment>
            <div className={`message-name ml-1`}>
              {item.from.firstName} {item.from.lastName}
            </div>
          </React.Fragment>
        )}

        {box === 'outbox' && (
          <React.Fragment>
            <div className={`message-name ml-1`}>
              {item.to[0].firstName} {item.to[0].lastName}
            </div>
          </React.Fragment>
        )}

        {dateCondition && (
          <div className="message-date ml-auto">
            {moment(item.date).fromNow()}
          </div>
        )}
        {!dateCondition && (
          <div className="message-date ml-auto">
            {moment().format('D') === moment(item.date).format('D')
              ? moment(item.date).format('HH:mm')
              : moment(item.date).format('D MMM')}
          </div>
        )}

        <div className="w-100 mb-1"></div>

        <div className="message-subject">{item.subject}</div>

        {box !== 'outbox' && (
          <React.Fragment>
            <div className="message-priority ml-auto">
              <BtnPriority message={item} />
            </div>
          </React.Fragment>
        )}
      </div>
    </ListGroupItem>
  );
};

export default InboxMobileViewListItem;
