/*!

=========================================================
* Now UI Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState } from "react";
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from "react-redux";
import SweetAlert from 'react-bootstrap-sweetalert';

// reactstrap components
import { Form, FormGroup, Label, Button } from "reactstrap";
import BtnAttach from "components/Messages/Actions/BtnAttach";

import moment from "moment";
import LoadingLocal from "plugins/TS-lib-utils-public/components/Loading/LoadingLocal";
import { Fragment } from "react";
import { useForm } from "react-hook-form";
import { actionSendMessage } from "store/actions/messagesActions";
import { actionSweetAlert } from 'store/actions/commonActions';
import { useTranslate } from "react-translate";
import InboxAsyncRecipients from "./InboxAsyncRecipients";

const recipients = (to) => {
  let recipients = "";
  to.tags.forEach((user) => {
    recipients += user.text + ", ";
  });
  if (recipients.length > 0) {
    recipients = recipients.slice(recipients, recipients.length - 2);
  }
  return recipients;
};

const InboxViewForm = ({ setShowForm }) => {
  const t = useTranslate("messages");
  const dispatch = useDispatch();

  const box = useSelector((state) => state.messagesReducer.box);
  const users = useSelector((state) => state.userDataReducer.users);

  const isDirect = window.location.href.includes("reply");

  const [attachmentList, setAttachmentList] = useState([
    `attachment-${Date.now()}`,
  ]);

  const { message, loading_msg } = useSelector(
    (state) => state.messagesReducer
  );

  let tags = message
    ? [
      {
        id: message.from.auth_user_id,
        text: message.from.firstName + " " + message.from.lastName,
      },
    ]
    : [];

  const defaultValues = {
    to: message
      ? recipients({
        tags: tags,
        suggestions: [],
      })
      : "",
    subject: message && message.subject ? `Re: ${message.subject}` : "",
    body: message && message.subject && message.body
      ? "\n------" +
      "\nFrom: " +
      message.from.firstName +
      " " +
      message.from.lastName +
      "\nSubject: " +
      message.subject +
      "\nDate: " +
      moment(message.date).format("L HH:mm") +
      "\n" +
      message.body
      : "",
  };

  const { register, handleSubmit, control, errors } = useForm({
    defaultValues,
  });

  if (loading_msg) {
    return <LoadingLocal />;
  }
  //this function is used to call an alert with a legal message when a message is sent, please, place it after every dispatch of a sendMessageAction
  const callSweetAlert = () => {
    dispatch(
      actionSweetAlert(
        <SweetAlert
          custom
          customIcon="/General/icons/warning-sign-orange.svg"
          title={t('please-be-patient')}
          onConfirm={() => { }}
          customButtons={
            <>
              {/*Custom buttons for more control*/}
              <Button
                className="mb-3"
                color="info"
                size="lg"
                onClick={() => {
                  dispatch(actionSweetAlert(null));
                }}
              >
                {t('btn-ok')}
              </Button>

              {/* <Button
                  className="mb-3 ml-2"
                  color="danger"
                  size="lg"
                  onClick={() => dispatch(actionSweetAlert(null))}
                >
                  {t('btn-no')}
                </Button> */}
            </>
          }
        ></SweetAlert>
      )
    );
  }

  const onSubmit = (data) => {
    //For a single recipient
    if (data.hasOwnProperty("isNew")) {
      const _id = data.to.value ? data.to.value : data.to;
      const recipient = users.find((user) => {
        return (_id === user.auth_user_id) || (_id === `${user.firstName} ${user.lastName}`);
      });
      if (!recipient) {
        return toast.error(t("alert-error-recipient-invalid"));
      }
      let to = JSON.stringify([
        {
          user_id: recipient.auth_user_id,
          type: recipient.type,
        },
      ]);

      dispatch(actionSendMessage({ ...data, to }, recipient.practice._id));
      callSweetAlert();
      setShowForm(false);
      return null;
    }

    let to = JSON.stringify([
      {
        user_id: message.from.auth_user_id,
        type: message.from.type,
      },
    ]);
    let message_answered = JSON.stringify({
      _id: message.message_global_id,
      box,
      date: message.date,
    });
    dispatch(actionSendMessage({ ...data, to, message_answered }, message.practice._id));
    callSweetAlert();
    setShowForm(false);
    //showReplyForm([]);
  };

  return (
    <Fragment>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Label for="to">{t("recipients")}</Label>
          {isDirect && message && (
            <>
              <input type="hidden" name="isNew" ref={register} defaultValue={true} />
              <input type="hidden" name="to" ref={register} defaultValue={message.from.auth_user_id} />
            </>
          )}
          {(() => {
            if (message) {
              return (
                <input
                  type="text"
                  className="form-control"
                  readOnly={true}
                  defaultValue={
                    message.from.firstName + " " + message.from.lastName
                  }
                />
              );
            } else {
              return (
                <InboxAsyncRecipients
                  register={register}
                  control={control}
                  focus
                />
              );
            }
          })()}
        </FormGroup>
        <FormGroup>
          <Label for="to">{t("subject")}</Label>
          <input
            className="form-control"
            type="text"
            name="subject"
            id="subject"
            placeholder={`${t("subject")}`}
            ref={register({ required: true, maxLength: 255 })}
          />
          {errors.subject && (
            <p className="alert alert-danger mt-2 w-50">{t("error-subject")}</p>
          )}
        </FormGroup>
        <FormGroup>
          <Label for="message">{t("message")}</Label>
          <textarea
            className="form-control"
            type="textarea"
            name="body"
            autoFocus={!!message}
            ref={register({ required: true })}
            rows={6}
          ></textarea>
          {errors.text && (
            <div className="alert alert-danger mt-2 w-50">
              {t("error-message")}
            </div>
          )}
        </FormGroup>

        <FormGroup>
          <Label>{t("attachments")}</Label>
          <br />
          {attachmentList.map((value, i) => (
            <BtnAttach
              key={i}
              id={value}
              register={register}
              setAttachmentList={setAttachmentList}
              attachmentList={attachmentList}
            />
          ))}
        </FormGroup>

        <Button id="btn-reply" size="sm" className="btn-info pull-right">
          {t("btn-send")} <span className="icon-send"></span>
        </Button>
        <Button
          size="sm"
          id="btn-cancel"
          type="reset"
          className="btn-primary pull-right mr-3"
          onClick={(e) => {
            e.preventDefault();
            setShowForm(false);
          }}
        >
          {t("btn-cancel")} <i className="fas fa-times" aria-hidden="true"></i>
        </Button>
      </Form>
    </Fragment>
  );
};

export default InboxViewForm;
