import { eventChannel } from "redux-saga";
import { fork, take, call, put, select, cancel } from "redux-saga/effects";
//Service
import WsService from "plugins/TS-lib-utils-public/services/WsService";
//Selectors
import CONSTANTS from "../CONSTANTS";
import { getUser, getMessages } from "store/selectors/selectors";
import { actionLogout } from "store/actions/usersActions";
import { actionGetMessages } from "store/actions/messagesActions";
import { actionGetNotifications } from "store/actions/notificationsActions";
import { actionGetUser } from "store/actions/usersActions";
import AuthService from 'plugins/TS-lib-utils-public/services/AuthService';
import { actionResetApp } from 'store/actions/rootActions';

function subscribe(socket) {
  return eventChannel((emitter) => {
    socket.on("messages", (message) => {
      if (message.data && message.data.action) {
        emitter(message.data);
      }
    });
    return () => {};
  });
}

function* listenAction(socket) {
  try {
    const channel = yield call(subscribe, socket);
    const { box } = yield select(getMessages);
    while (true) {
      const data = yield take(channel);
      const { action } = data;
      if (CONSTANTS.hasOwnProperty(action)) {
        switch (action) {
          case CONSTANTS.USERS_FORCE_LOGOUT:
            yield put(actionLogout())
            yield put(actionResetApp());
            AuthService.removeToken();
            break;
          case CONSTANTS.WS_NOTIFICATION:
            const { notification } = data;
            yield put(actionGetNotifications("messages"));
            switch (notification.module) {
              case "messages":
                if (box === "inbox") {
                  yield put(actionGetMessages());
                }
                break;
              default:
                break;
            }
            break;

          case CONSTANTS.WS_ALERT:
            const { module, info } = data;

            switch (module) {
              case "profile":
                if (info === "profile-updated") {
                  yield put(actionGetUser());
                }
              break;

              case "laboratory": {
                yield put(actionGetNotifications('laboratory'));
              }
              break;
            }

          break;
          default:
            break;
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}

// function* emitterAction(socket) {
//   while (true) {
//     const { action } = yield take(CONSTANTS.WS_EMIT_ACTION);
//     console.log("saga", action);
//     socket.emit("message", action);
//   }
// }

function* flow() {
  try {
    while (true) {
      yield take(CONSTANTS.USERS_SET);
      const user = yield select(getUser);
      if (user._id) {
        const socket = yield call(WsService.connect);
        const task = yield fork(listenAction, socket);
        // yield fork(emitterAction, socket);
        console.log("* ===> WS Saga online");
        yield take(CONSTANTS.USERS_LOGOUT);
        yield cancel(task);
        console.log("* <=== WS Saga offline");
        yield call(WsService.disconnect);
      }
    }
  } catch (error) {
    console.log("* <=== WS Saga ERROR: " + error.message);
    yield call(WsService.disconnect);
  }
}

export function* wsSaga() {
  console.log("*Main WS Saga");
  yield fork(flow);
}
