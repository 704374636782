import { takeEvery, take, put, call } from "redux-saga/effects";
import { toast } from "react-toastify";
//Actions
import CONSTANTS from "../CONSTANTS";
import {
  actionSetProfile,
  actionSetLoadingProfile,
  actionGetProfile,
} from "../actions/profilesActions";
//Selectors
import { select } from "redux-saga/effects";
import { getUser } from "../selectors/selectors";
//Services
import ProfileService from "../../services/ProfilesService";
import { deleteUrl } from 'helpers/urlsBlob';
import { actionRemoveAvatars } from 'store/actions/avatarsActions';
import { actionRefreshUser } from 'store/actions/usersActions';
import utilTranslations from "helpers/utilTranslations";
ProfileService.setBaseUrl("/patients");

function* sagaSaveProfile({ profile }) {
  //Translations
  const user = yield select(getUser);
  const t = utilTranslations(user.language || 'en', 'profiles-saga');
  try {
    deleteUrl(user?.auth_user_id);
    //Saga's logic
    let result;
    if (profile?._id) {
      result = yield call(ProfileService.edit, user.auth_user_id, profile);
    } else {
      result = yield call(ProfileService.add, user.auth_user_id, profile);
    }
    if (result.status >= 200 || result.status <= 205) {
      yield put(actionRemoveAvatars(user.auth_user_id));
      yield put(actionRefreshUser());
      toast.success(t("profile-has-been-saved"));
    } else {
      throw new Error(t("profile-couldnt-be-saved"));
    }
  } catch (error) {
    toast.error(error.message);
  }
}
// function* sagaDeletePatient(patient) {
//   try {
//     const result = yield PatientsService.del(patient.id);
//     yield put(
//       actionSetResponse({
//         statusCode: result.statusCode,
//         status: result.status,
//         message: result.status,
//       })
//     );
//   } catch (error) {
//     yield actionSetResponse({
//       status: "error",
//       message: "Patient not removed",
//     });
//   }
// }

// function* sagaPatient({ id }) {
//   try {
//     const patient = yield PatientsService.getById(id);
//     yield put(actionSetPatient(patient.data));
//   } catch (error) {
//     yield put(
//       actionSetResponse({
//         status: "error",
//         message: "Cannot get data",
//       })
//     );
//   }
// }

function* sagaGetProfile() {
  try {
    const user = yield select(getUser);
    yield put(actionSetLoadingProfile(true));
    const result = yield call(ProfileService.getProfile, user.auth_user_id);
    if (result && result.auth_user_id) {
      yield put(actionSetProfile(result));
    }
  } catch (error) {
    toast.error(error.message);
  } finally {
    yield put(actionSetLoadingProfile(false));
  }
}

/**
 * For address
 */
function* sagaSaveAddress({ address }) {
  try {
    //Translations
    const user = yield select(getUser);
    const t = utilTranslations(user.language || 'en', 'profiles-saga');
    console.log("Entering");
    let result;
    if (typeof address._id !== "undefined" && address._id.length > 0) {
      result = yield call(ProfileService.editAddress, address);
    } else {
      result = yield call(ProfileService.addAddress, address);
    }
    yield put(actionGetProfile());
    if (result.status === 201 || result.status === 200) {
      toast.success(t("address-has-been-saved"));
    } else {
      throw new Error(t("the-address-was-not-saved"));
    }
  } catch (error) {
    toast.error(error.message);
  }
}
/**
 * Delete address
 * @param {string} id
 */
function* sagaDeleteAddress({ id }) {
  try {
    //Translations
    const user = yield select(getUser);
    const t = utilTranslations(user.language || 'en', 'profiles-saga');
    const result = yield call(ProfileService.deleteAddress, id);
    if (result.status === 201 || result.status === 200) {
      yield put(actionGetProfile());
      toast.success(t("address-has-been-deleted"));
    } else {
      if (result.response) {
        throw new Error(result.response);
      }
      throw new Error(t("the-address-was-not-deleted"));
    }
  } catch (error) {
    toast.error(error.message);
  }
}

export function* profilesSaga() {
  console.log("*Main Profiles Saga");
  yield takeEvery(CONSTANTS.PROFILE_GET, sagaGetProfile);
  yield takeEvery(CONSTANTS.PROFILE_SAVE, sagaSaveProfile);
  yield takeEvery(CONSTANTS.ADDRESS_SAVE, sagaSaveAddress);
  yield takeEvery(CONSTANTS.ADDRESS_DELETE, sagaDeleteAddress);
}
