
import PaymentSummary from "components/Devices/Booking/PaymentSummary";
import React from "react";

class StepPayment extends React.Component {
  isValidated() {
    return true;
  }
  render() {
    return <PaymentSummary />;
  }
}

export default StepPayment
