import { takeEvery, put, call, select, takeLatest, take } from "redux-saga/effects";
import { toast } from "react-toastify";
import momentjsI18n from "helpers/momentjsI18n";
//Redux Actions
import CONSTANTS from "../CONSTANTS";
import {
  actionLogin,
  actionSetUser,
  actionGetUser,
  actionSetQr2fa,
  actionSetLoadingUsers,
  actionSetLang,
  actionSetDateFormat,
  actionSetActiveSessions,
  actionGetActiveSessions,
  actionRefreshUser,
  actionLogout,
} from "../actions/usersActions";
import { actionSetLoading } from "store/actions/loadingActions";
import { actionResetApp } from "store/actions/rootActions";
import { getUser, getNativeWebView } from 'store/selectors/selectors';
//Helpers
import nativeSender from 'helpers/nativeSender';
//Services
import AuthService from "../../plugins/TS-lib-utils-public/services/AuthService.js";
import ProfileService from "../../services/ProfilesService";
import { actionGetProfile } from "store/actions/profilesActions";
import historyRouter from 'historyRouter';
import utilTranslations from '../../helpers/utilTranslations';
import PatientsService from "services/PatientsService";
import { actionLogoutBase } from "../actions/usersActions";
import storageDispatcher from "helpers/storageDispatcher";
import AuthPatientsService from "services/AuthPatientsService";
AuthService.setBaseUrl("/patients");

function* sagaGetUser() {
  const userActive = yield select(getUser);
  const t = utilTranslations(userActive.language || 'de', 'users-saga');
  try {
    let user = yield call(AuthService.getUser);
    if (user.status === 401 || !user?.data) {
      yield call(AuthService.forceRemoveToken());
      historyRouter.push('/');
      throw new Error("unauthorized");
    }
    if (user && user.data) {
      const pifsData = yield call(PatientsService.getPifsProfile, user.data.auth_user_id);
      const profileData = yield call(ProfileService.getProfile, user.data.auth_user_id);

      yield put(actionSetUser({
        ...pifsData.data,
        ...user.data,
        ...profileData
      }));
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put(actionSetLoading(false));
  }
}

function* sagaRegister({ user }) {
  const userActive = yield select(getUser);
  const t = utilTranslations(userActive.language || 'de', 'users-saga');
  try {
    //Register
    const result = yield AuthService.register(user);
    if (result && result.status === 201) {
      yield put(actionLogin(user));
    } else {
      throw new Error(t("user-not-registered"));
    }
  } catch (error) {
    toast.error(t("user-not-registered"));
    console.log(error);
  }
}

function* sagaLogoutBase() {
  const userActive = yield select(getUser);
  const t = utilTranslations(userActive.language || 'de', 'users-saga');
  try {
    yield AuthService.logout();
    yield put(actionResetApp());
  } catch (error) {
    console.log(error);
    toast.error(t("logout-failed"));
  }
}

function* sagaLogout() {
  const userActive = yield select(getUser);
  const t = utilTranslations(userActive.language || 'de', 'users-saga');
  try {
    const mobile = yield select(getNativeWebView);
    if (mobile) {
      //Send logout event to webview
      yield call(nativeSender.send, 'LOGOUT');
    } else {
      yield put(actionLogoutBase());
    }
  } catch (error) {
    console.log(error);
    toast.error(t("logout-failed"));
  }
}

function* sagaLogin({ user }) {
  const userActive = yield select(getUser);
  const t = utilTranslations(userActive.language || 'de', 'users-saga');
  try {
    yield put(actionSetLoading(true));
    const login = yield call(AuthService.login, user);
    if (login.status === "success") {
      yield call(nativeSender.send, 'LOGIN', login);
      //Append pifs id
      const pifsData = yield call(PatientsService.getPifsProfile, login.auth_user_id);
      const profileData = yield call(ProfileService.getProfile, login.auth_user_id);
      return yield put(actionSetUser({ ...pifsData.data, ...login, ...profileData }));
    } else if (login.status === "pending") {
      return yield put(actionSetUser({ ...login, auth_2fa: login.status }));
    }
    throw new Error(t("login-failed"));
  } catch (error) {
    toast.error(t("login-failed"));
    historyRouter.push("/auth/login-page")
  } finally {
    yield put(actionSetLoading(false));
  }
}

function* sagaCheckSession() {
  const userActive = yield select(getUser);
  const t = utilTranslations(userActive.language || 'de', 'users-saga');
  try {
    yield call(AuthService.checkSession);
  } catch (error) {
    console.log(error);
    toast.error(t("session-not-found"));
  }
}

function* sagaGetSessions({ }) {
  try {
    const sessions = yield call(AuthService.getSessionList);

    return yield put(actionSetActiveSessions(sessions.data));
  } catch (error) {
    console.log(error);
    toast.error(error.message);
  }
}

function* sagaDeleteSession({ session }) {
  try {
    yield call(AuthService.deleteSession, session);
    return yield put(actionGetActiveSessions());
  } catch (error) {
    console.log(error);
    toast.error(error.message);
  }
}

function* sagaRefreshUser() {
  const userActive = yield select(getUser);
  const t = utilTranslations(userActive.language || 'de', 'users-saga');
  //Translations
  try {
    // yield put(actionSetLoading(true));
    let user = yield call(AuthService.getUser);
    if (user.status === 401) {
      yield call(AuthService.forceRemoveToken());
      throw new Error(t("not-authorized"));
    }
    if (user.data) {
      const pifsData = yield call(PatientsService.getPifsProfile, user.data.auth_user_id);
      yield put(actionGetProfile());
      yield put(actionSetUser({ ...pifsData.data, ...user.data }));
    }
  } catch (error) {
    console.log(error);
    // toast.error(error.message || 'Bad request');
  } finally {
    // yield put(actionSetLoading(false));
  }
}

//Auth2fa
function* sagaCheck2fa({ code }) {

  //Translations
  const user = yield select(getUser);
  const t = utilTranslations(user.language || 'de', 'users-saga');

  try {
    toast.info(t("checking-code"));
    const check2fa = yield AuthService.check2fa(code);
    if (check2fa.status === 200) {
      //yield put(actionGetUser());
      return window.location.reload();
    } else {
      toast.error(t("failed"));
    }
  } catch (error) {
    toast.error(t("bad-request"));
  }
}

function* sagaActivate2fa({ code }) {

  //Translations
  const user = yield select(getUser);
  const t = utilTranslations(user.language || 'de', 'users-saga');

  try {
    const activate2fa = yield AuthService.activate2fa(code);
    if (activate2fa.status === 200) {
      toast.success(t("successfuly-activation"));
      yield put(actionGetUser());
    } else {
      toast.error(t("failed-activation"));
    }
  } catch (error) {
    console.log(error);
  }
}


function* sagaInactivate2fa({ pass }) {

  //Translations
  const user = yield select(getUser);
  const t = utilTranslations(user.language || 'de', 'users-saga');

  try {
    const inactivate2fa = yield AuthService.inactivate2fa(pass);
    if (inactivate2fa.status === 200) {
      yield put(actionGetUser());
      toast.success(t("auth-2fa-was-disabled"));
    } else {
      toast.error(t("auth-2fa-was-not-disabled"));
    }
  } catch (error) {
    toast.error(t("bad-request"));
  }
}

function* sagaCreate2fa() {

  //Translations
  const user = yield select(getUser);
  const t = utilTranslations(user.language || 'de', 'users-saga');

  try {
    yield put(actionSetLoadingUsers(true));
    const auth2fa = yield AuthService.create2fa();
    if (auth2fa.data.key) {
      yield put(
        actionSetQr2fa({ key: auth2fa.data.key, uri: auth2fa.data.uri })
      );
    }
    yield put(actionSetLoadingUsers(false));
  } catch (error) {
    toast.error(t("2fa-creation"));
  }
}

function* sagaUpdateLang({ language }) {

  //Translations
  const user = yield select(getUser);
  const t = utilTranslations(user.language || 'de', 'users-saga');

  try {
    const user = { language };
    const result = yield AuthService.edit(user);
    if (result && result.status === 200) {
      toast.success(t("user-data-updated"));
      yield put(actionSetLang(language));
    }
  } catch (error) {
    toast.error(t("update-failed"));
  }
}

function* sagaUpdateDateFormat({ dateFormat }) {

  //Translations
  const user = yield select(getUser);
  const t = utilTranslations(user.language || 'de', 'users-saga');

  try {
    const user = { dateFormat };
    const result = yield AuthService.edit(user);
    if (result && result.status === 200) {
      toast.success(t("user-data-updated"));
      momentjsI18n.setFormat(dateFormat);
      yield put(actionSetDateFormat(dateFormat));
    }
  } catch (error) {
    toast.error(t("update-failed"));
  }
}

function* sagaChangePassword({ user }) {

  const userActive = yield select(getUser);
  const t = utilTranslations(userActive.language || 'de', 'users-saga');

  try {
    //Change password
    const result = yield AuthService.changePassword(user);
    if (result) {
      if (result.status >= 400) {
        throw new Error(t("an-error-ocurred-while-changing-the-password"));
      }
      yield call(nativeSender.send, 'LOGIN', userActive);
      yield put(actionGetUser());
      toast.success(t("password-changed-successfully"));
      if (userActive.password_expired) {
        historyRouter.push('/admin/profile');
      }
    }
  } catch (error) {
    console.log(error);
    toast.error(t("an-error-ocurred-while-changing-the-password"));
  }
}

function* sagaUserEdit({ user }) {

  const userActive = yield select(getUser);
  const t = utilTranslations(userActive.language || 'de', 'users-saga');

  try {
    const result = yield AuthService.edit(user);
    if (result && result.status === 200) {
      toast.success(t("credentials-updated"));
      
    }
  } catch (error) {
    toast.error(t("credentials-could-not-be-updated"));
  }
}

function* sagaRecoverPassword({ email }) {

  const userActive = yield select(getUser);
  //Translations
  let t = utilTranslations(userActive.language || 'de', 'users-saga');

  try {
    //Send recovery password to email
    const result = yield AuthService.recoverPassword(email);
    if (result && result.status === 200) {
      toast.success(t('alert-success-password-recovery'));
      // toast.success('A recovery password has been sent to your email.');
      historyRouter.push('/auth/login-page');
    } else {
      toast.warning(t('alert-warning-password-recovery'));
    }
  } catch (error) {
    toast.error(t('alert-error-password-recovery'));
    // toast.error('An error ocurred while processing your request.');
  }
}


function* sagaSendHelp({ email }) {
  const userActive = yield select(getUser);
  //Translations
  let t = utilTranslations(userActive.language || 'de', 'users-saga');
  try {
    //Send recovery password to email
    const result = yield AuthPatientsService.sendHelp(email);
    if (result && result.status === 200) {
      toast.success(t('alert-success-help'));
      // toast.success('A recovery password has been sent to your email.');
      historyRouter.push('/auth/login-page');
    } else {
      toast.warning(t('alert-warning-help'));
    }
  } catch (error) {
    toast.error(t('alert-error-help'));
    // toast.error('An error ocurred while processing your request.');
  }
}

function* sagaDeleteAccountRequest({ }) {

  const userActive = yield select(getUser);
  //Translations
  let t = utilTranslations(userActive.language || 'de', 'users-saga');

  try {
    //Send recovery password to email
    const result = yield AuthService.requestAccountDeletion("patient");
    if (result && result.status === 200) {
      toast.success(t("alert-confirmation-email-sended"));//"Eine Bestätigungs-E-Mail wurde an Sie gesendet.");
      // toast.success('A recovery password has been sent to your email.');
    }
  } catch (error) {
    toast.error(t('alert-error-password-recovery'));
    // toast.error('An error ocurred while processing your request.');
  }
}


function* sagaDeleteAccountPrepare({ user }) {
 
  const userActive = yield select(getUser);
  //Translations
  let t = utilTranslations(userActive.language || 'de', 'users-saga');

  try {
    //Prepare account for deletion
    const result = yield AuthService.prepareAccountDeletion("patient", user);

    if (result && result.status === 200) {
      yield put(actionLogout());
      yield put(actionSetLoading(false));
      toast.success(t("alert-init-session"));//"Bitte melden Sie sich an, um Ihre Auswahl zu bestätigen.");
      // toast.success('A recovery password has been sent to your email.');
    }
  } catch (error) {
    console.log(error);
    // toast.error('An error ocurred while processing your request.');
  }
}

function* sagaConfirmAccountDeletion({ userId, choice }) {

  const userActive = yield select(getUser);
  //Translations
  let t = utilTranslations(userActive.language || 'de', 'users');

  try {
    //Confirm account deletion
    const result = yield AuthService.confirmAccountDeletion("patient", userId, choice);
    if (result && result.status === 200) {
      toast.success(choice ? t('alert-account-deleted') : t('alert-account-restored'));
      if (!choice) {
        storageDispatcher.removeLocalStorageEvent("pending_initial_choice"); //TODO, implementar constante para no utilizar string como flag
        yield put(actionRefreshUser());
        historyRouter.push("/admin");
      }
    }
  } catch (error) {
    console.log(error);
    // toast.error('An error ocurred while processing your request.');
  }
}

function* sagaTokenSendVerification({ data }) {

  const userActive = yield select(getUser);
  //Translations
  let t = utilTranslations(userActive.language || 'de', 'users-saga');

  try {
    const result = yield PatientsService.sendVerification(data);
    if (result && result.status === 200) {
      historyRouter.replace(`/auth${result.data.split("/auth")[1]}`);
      toast.success(t('alert-account-verified'));//"Konto verifiziert!");
    } else {
      throw new Error(t('alert-account-not-verified'));//"Verification failed");
    }
  } catch (e) {
    console.log(e);
    const res = yield PatientsService.verifyToken(data.token);

    if (!res || res.status >= 400) {
      historyRouter.replace("/auth/login-page");
      toast.error(t('alert-verification-attempts-failed'));// "Alle Verifizierungsversuche sind fehlgeschlagen");
    } else {
      toast.error(t('alert-wrong-answer'));
    }
    
  }
}

export function* usersSaga() {
  console.log("*Main Users Saga");
  yield takeEvery(CONSTANTS.USERS_LOGIN, sagaLogin);
  yield takeEvery(CONSTANTS.USERS_LOGOUT, sagaLogout);
  yield takeEvery(CONSTANTS.USERS_LOGOUT_BASE, sagaLogoutBase);
  yield takeEvery(CONSTANTS.USERS_CHECK_SESSION, sagaCheckSession);
  yield takeEvery(CONSTANTS.USERS_REGISTER, sagaRegister);
  yield takeEvery(CONSTANTS.USERS_GET, sagaGetUser);
  yield takeEvery(CONSTANTS.USERS_EDIT, sagaUserEdit);
  yield takeLatest(CONSTANTS.USERS_REFRESH_DATA, sagaRefreshUser);
  yield takeEvery(CONSTANTS.USERS_CHANGE_PASSWORD, sagaChangePassword);
  yield takeEvery(CONSTANTS.USERS_RECOVER_PASSWORD, sagaRecoverPassword);
  yield takeEvery(CONSTANTS.USERS_SEND_HELP, sagaSendHelp);
  //Auth2fa
  yield takeEvery(CONSTANTS.AUTH2FA_CREATE, sagaCreate2fa);
  yield takeEvery(CONSTANTS.AUTH2FA_ACTIVATE, sagaActivate2fa);
  yield takeEvery(CONSTANTS.AUTH2FA_INACTIVATE, sagaInactivate2fa);
  yield takeEvery(CONSTANTS.AUTH2FA_CHECK, sagaCheck2fa);
  //Settings
  yield takeEvery(CONSTANTS.USERS_UPDATE_LANG, sagaUpdateLang);
  yield takeEvery(CONSTANTS.USERS_UPDATE_DATE_FORMAT, sagaUpdateDateFormat);
  yield takeEvery(CONSTANTS.USERS_GET_SESSIONS, sagaGetSessions);
  yield takeEvery(CONSTANTS.USERS_DELETE_SESSION, sagaDeleteSession);
  yield takeEvery(CONSTANTS.USERS_DELETE_ACCOUNT_REQUEST, sagaDeleteAccountRequest);
  yield takeEvery(CONSTANTS.USERS_DELETE_ACCOUNT_PREPARE, sagaDeleteAccountPrepare);
  yield takeEvery(CONSTANTS.USERS_DELETE_ACCOUNT, sagaConfirmAccountDeletion);
  //Token
  yield takeEvery(CONSTANTS.TOKEN_SEND_DATA, sagaTokenSendVerification);
}