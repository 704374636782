import CONSTANTS from "../CONSTANTS";

const patientsReducer = (state = [], action) => {
  switch (action.type) {
    case CONSTANTS.SET_PATIENTS:
      return { ...state, patients: action.patients };
    case CONSTANTS.SET_PATIENT:
      return { ...state, patient: action.patient };
    default:
      return state;
  }
};

export default patientsReducer;
