export default {
  types: [
    '.txt',
    '.doc',
    '.docx',
    '.xls',
    '.xlsx',
    '.pdf',
    '.gif',
    '.png',
    '.jpg',
    '.jpeg',
    '.tiff',
    '.bmp',
    '.mp3',
    '.mp4',
    '.avi',
    '.wav',
    '.flac',
    '.aiff',
    '.zip',
    '.rar',
  ],
  maxSize: '50', //In MegaBytes
};
