import FileValidator from 'helpers/FileValidator';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import MultimediaService from 'services/MultimediaService';
import { actionPushAvatar } from 'store/actions/avatarsActions';
import { useTranslate } from 'react-translate';

var requestsActive = {};

const useFileUtils = (
  inputElement = null,
  defaultFile = '/General/avatar.png',
  label = 'Attach file'
) => {
  const [defaultImage, setDefaultImage] = useState(defaultFile);
  const [avatarExists, setAvatarExists] = useState(false);
  const [file, setFile] = useState({
    file: defaultFile,
    label: label,
  });
  const t = useTranslate("use-file-utils");

  const avatars = useSelector((state) => state.avatarsReducer);
  const dispatch = useDispatch();

  const [isFetching, setIsFetching] = useState(false);

  /**
   * Reset input data
   */
  const resetInput = () => {
    setFile({
      file: defaultImage,
      label: '',
    });
  };

  /**
   * Show image when changing input file
   */
  const handleSelectFile = (e) => {
    const file = e.target.files[0];
    if (!file) {
      inputElement.value = '';
      return resetInput();
    }
    /**
     * File validation
     */
    const validator = new FileValidator(file);
    let src = URL.createObjectURL(file);
    let { name } = file;
    /**
     * For resolution's validation
     */
    const width = 1500;
    const heigth = 1500;
    validator
      .isValidImageResolution(width, heigth)
      .then((isValid) => {
        // if (!validator.isValidWeight(1024) || !isValid) {
        if (!isValid) {
          toast.error(`${t("max-resolution-allowed-is")} ${width} x ${heigth}`);
          inputElement.value = '';
          return setFile({ file: defaultImage, label: '' });
        }
        setFile({ file: src, label: name });
      })
      .catch(() => toast.error(`${t("something-has-gone-wrong")}`));
  };

  /**
   * Get file from ms-multimedia
   */
  const getServerFile = async (fileId = null, thumb = null) => {
    if (!fileId) return null;
    const response = await MultimediaService.getFile(fileId, thumb);
    setFile({ file: response });
    return response;
  };

  /**
   * Get avatar
   */
  const getAvatar = async (avatar, thumb, makeRequest = true) => {
    if (!avatar) return setDefaultImage(defaultFile);
    // Search in state
    if (!makeRequest) {
      // const avatarStored = avatars.find((a) => a.id && a.id === avatar);
      if (avatars && typeof avatars[avatar] !== 'undefined') {
        setAvatarExists(true);
        setDefaultImage(avatars[avatar]);
        return avatars.avatar;
      }
    }
    //block the request if there is one active for the same user;
    if (requestsActive[avatar]) {
      return null;
    }
    requestsActive[avatar] = await MultimediaService.getAvatar(
      avatar,
      thumb,
      makeRequest
    );
    const response = requestsActive[avatar];
    delete requestsActive[avatar];
    if (response) {
      setAvatarExists(true);
      dispatch(actionPushAvatar(avatar, response));
      setDefaultImage(response);
    }
    return response;
  };

  /**
   * Delete avatar
   */
  const deleteAvatar = async (auth_user_id, thumb) => {
    setIsFetching(true);
    if (!auth_user_id) return defaultFile;
    const response = await MultimediaService.deleteAvatar(auth_user_id, thumb);
    if (response) {
      setDefaultImage('/General/avatar.png');
      setAvatarExists(false);
      toast.success(t("avatar-deleted"));
    }
    setIsFetching(false);
    return response;
  };

  return {
    file,
    resetInput,
    handleSelectFile,
    defaultImage,
    setDefaultImage,
    getServerFile,
    getAvatar,
    deleteAvatar,
    setFile,
    isFetching,
    avatarExists,
  };
};
export default useFileUtils;
