import React from "react";
import avatarDefault from "assets/img/default-avatar.png";
const ContactImage = (props) => {
  const { avatar, doctorName } = props;
  return (
    <div className="contact_people text-center">
      <div className="contact_img">
        {/* <img src="https://ptetutorials.com/images/user-profile.png" alt="sunil">  */}
        <img
          src={avatar ? avatar : avatarDefault}
          alt="Contact"
          className={`${props.active ? "active_contact" : ""}`}
        />
      </div>
      <div className="contact_name text-truncate">{doctorName}</div>
    </div>
  );
};

export default ContactImage;
