import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import moment from 'moment';
// reactstrap components
import { Row, Col, Input, FormGroup, Button, Form } from 'reactstrap';
import { useTranslate } from 'react-translate';
import { actionProfileSave } from 'store/actions/profilesActions';
import LoadingLocal from 'plugins/TS-lib-utils-public/components/Loading/LoadingLocal';
import ProfileAvatar from './ProfileAvatar';
import { useHistory } from 'react-router-dom';
import Dates from "../../helpers/Dates";

const UserSchema = yup.object().shape({
  auth_user_id: yup.string().required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  username: yup.string().required(),
  year: yup.string().required().notOneOf(["year"]),
  month: yup.string().required().notOneOf(["month"]),
  day: yup.string().required().notOneOf(["day"]),
  gender: yup.mixed().oneOf(['Male', 'Female', 'Other']).required(),
  phone: yup.string().required(),
  // address: yup.object({
  //   street: yup.string().required(),
  //   postalCode: yup.number().required(),
  //   city: yup.string().required(),
  //   apartment: yup.string().required(),
  //   country: yup.string().required(),
  //   phone: yup.string().required(),
  // }),
  company: yup.object({
    type: yup.mixed().oneOf(['Private', 'Public', 'Self Payer']),
    name: yup.string().when("type", {
      is: 'Self Payer',
      then: (schema) => schema,
      otherwise: (schema) => schema.required()
    }),
    //number: yup.number().transform(value => !isNaN(value) ? value : undefined),
    number: yup.string(),
  }),
});

//Date functionalities
const isLeapYear = (year) => {
  return (Number(year) % 100) && (!(Number(year) % 4));
};

const ProfileForm = (props) => {
  const history = useHistory();
  const { profile, loading } = useSelector((state) => state.profilesReducer);
  const user = useSelector((state) => state.userReducer);
  const [avatar, setAvatar] = useState(null);
  //const [shortname, setShortname] = useState(user.nameInitials);

  //Date settings
  const [day, setDay] = useState("day");
  const [month, setMonth] = useState("month");
  const [year, setYear] = useState("year");
  const baseYear = Number(moment().subtract(120, 'years').format('YYYY'));
  const dateOrder = user.dateFormat ? user.dateFormat.split(/[^A-Z]/) : "DD-MM-YYYY".split("-");

  const t = useTranslate("profile-form");
  const addressT = useTranslate("profile-address");
  const dateT = useTranslate("dates");

  const companyTypeOptions = [
    { value: 'Private', label: addressT("private") },
    { value: 'Public', label: addressT("public") },
    { value: 'Self Payer', label: addressT("self-payer") },
  ];

  //Search select value
  const selectFinder = (options, value) => {
    if (value && options) {
      return options.find((option) => option.value === value);
    }
    return null;
  };

  //Company Type
  let companyType = '';
  if (profile.company && profile.company.type) {
    companyType = selectFinder(companyTypeOptions, profile.company.type);
  }

  const isMount = useRef(true);
  const dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    setValue,
    errors,
    setError,
    clearError,
    triggerValidation,
    watch,
    reset,
  } = useForm({
    validationSchema: UserSchema,
    mode: 'onChange',
    defaultValues: React.useMemo(() => {
      return {
        ...user
      }
    }, [profile, user])
  });

  //Select States
  // const [companyTypeSelect, setCompanyTypeSelect] = useState(companyType);

  // //Company Type handler
  // let companyTypeOptionsModifed = companyTypeOptions;
  // let compName = watch('company.name');
  // if (compName) {
  //   companyTypeOptionsModifed = companyTypeOptions.filter((option) => {
  //     return option.value !== 'Self Payer' ? option : null;
  //   });
  //   if (companyTypeSelect && companyTypeSelect.value === 'Self Payer') {
  //     setCompanyTypeSelect(null);
  //   }
  // } else {
  //   companyTypeOptionsModifed = companyTypeOptions;
  // }

  const [selfPayerState, setSelfPayerState] = useState(false);
  const handleCompanyChangeType = (e) => {
    clearError('company')
    if (e.target.value == 'Self Payer') {
      setSelfPayerState(true);

    } else {
      setSelfPayerState(false);
    }
  }

  useEffect(() => {
    if (isMount.current) {
      register({ name: 'firstName' });
      register({ name: 'lastName' });
      register({ name: 'year' });
      register({ name: 'day' });
      register({ name: 'month' });
      register({ name: 'gender' });
      register({ name: 'username' });
      // register({ name: 'address.city' });
      // register({ name: 'address.country' });
      register({ name: 'company.type' });
      register({ name: 'phone' });
      register({ name: 'title' });
      //register({ name: 'email' });

      if (user.birthday) {
        const date = Dates.parseDate(Number(user.birthday)).split("-");
        setDay(date[2]);
        setMonth(date[1]);
        setYear(date[0]);
      }
    }
    return () => {
      isMount.current = false;
    };
  }, [register, dispatch, reset]);

  useEffect(() => {
    window.scrollTo(0, 0);
    user.company = profile.company;
    if (profile.birthday && !profile.is_ready) {
      user.birthday = `${moment.utc(profile.birthday).year()}-${String(moment.utc(profile.birthday).month()+1).padStart(2,'0')}-${String(moment(profile.birthday).date()).padStart(2,'0')}`//moment.utc(profile.birthday).format('YYYY-MM-DD');
    } else if (user.birthday) {
      const date = Dates.parseDate(Number(user.birthday)).split("-");
      setDay(date[2]);
      setMonth(date[1]);
      setYear(date[0]);
    }
    reset(user);
  }, [profile, user]);

  useEffect(() => {
    if (user && user.company?.type == 'Self Payer') {
      setSelfPayerState(true)
    } else {
      setSelfPayerState(false)
    }
  }, [profile]);

  //Effect to change pronoun based on gender values
  const solvePronoun = (e) => {
    e.preventDefault();
    const pronounField = document.getElementById("pronoun");
    if (e.target.value === "Male") {
      pronounField.value = t("pronoun-male");
    } else {
      pronounField.value = t("pronoun-female");
    }
  }

  if (loading || !Object.keys(profile).length) {
    return <LoadingLocal />;
  }

  return (
    <Form
      onSubmit={handleSubmit((data) => {
        data.avatar_input = avatar;
        data.birthday = moment(`${data.year}-${data.month}-${data.day}`).format('YYYY-MM-DD');

        dispatch(actionProfileSave(data));

        if (!profile.is_ready) {
          props.toggle('2');
        } else {
          history.push("/admin/dashboard");
        }
      })}
      className="d-flex profile-form"
    >
      {user._id && <input type="hidden" name="_id" ref={register} />}
      <input type="hidden" name="auth_user_id" ref={register} />
      {profile._id && <Input name="_id" type="hidden" innerRef={register} />}


      <Row>
        <Col>
          <ProfileAvatar
            firstName={user.firstName}
            lastName={user.lastName}
            setAvatar={setAvatar}
            avatar={avatar}
          />
        </Col>

        <div className="form-col">
          <Col>
            <Row>
              <Col sm="12" md="6">
                <FormGroup
                  className={`has-label ${errors.firstName && 'has-danger'}`}>
                  <label>{t("name")} *</label>
                  <Input
                    name="firstName"
                    id="firstName"
                    placeholder={t("name")}
                    type="text"
                    innerRef={register}
                    defaultValue={
                      profile.firstName ? profile.firstName : user.first_name
                    }
                  />
                </FormGroup>
              </Col>
              <Col sm="12" md="6">
                <FormGroup
                  className={`has-label ${errors.lastName && 'has-danger'} `}>
                  <label>{t("last-name")} *</label>
                  <Input
                    name="lastName"
                    id="lastName"
                    placeholder={t("last-name")}
                    type="text"
                    innerRef={register}
                    defaultValue={
                      profile.lastName ? profile.lastName : user.last_name
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>

          <Col>
            <Row>
              <Col sm="12" md="6">
                <FormGroup
                  className={`has-label ${errors.birthday && 'has-danger'}`}>
                  <label>{t("birthday")} *</label>

                  <div className={`date-picker has-label `}>

                    {dateOrder.map((order, i) => {
                      switch(order) {
                        case "DD": 
                          return (
                            <div key={`day-picker-${i}`} className={`w-100 ${(errors.day) ? 'has-danger' : ""}`}>
                              <Input
                                name="day"
                                type="select"
                                defaultValue={day}
                                innerRef={register}
                                onChange={(e) => {
                                  setDay(e.target.value);
                                }}
                              >
                                <option hidden value="day">{dateT("day")}</option>
                                {[...Array(
                                  month === "02" 
                                  ? isLeapYear(year) 
                                  ? 29 
                                  : 28 
                                  : ["09", "04", "06", "11"].includes(month) 
                                  ? 30 
                                  : 31).keys()].map((x, i) => {
                                  return (
                                    <option key={`day-${i}`}>{String(++x).padStart(2, '0')}</option>
                                  )
                                })}
                              </Input>
                            </div>
                          )

                        case "MM":
                          return (
                            <div key={`month-picker-${i}`} className={`w-100 ${(errors.month) ? 'has-danger' : ""}`}>
                              <Input
                                name="month"
                                type="select"
                                defaultValue={month}
                                innerRef={register}
                                onChange={(e) => {
                                  setMonth(e.target.value);
                                }}
                              >
                                <option hidden value="month">{dateT("month")}</option>
                                {[...Array(12).keys()].map((x,i) => {
                                  let finalMonth = String(++x).padStart(2, '0');
                                  return (
                                    <option key={`month-${i}`} value={finalMonth}>{dateT(finalMonth)}</option>
                                  )
                                })}
                              </Input>
                            </div>
                          )

                        case "YYYY":
                          return (
                            <div key={`year-picker-${i}`} className={`w-100 ${(errors.year) ? 'has-danger' : ""}`}>
                            <Input
                              name="year"
                              type="select"
                              defaultValue={year}
                              innerRef={register}
                              onChange={(e) => {
                                setYear(e.target.value);
                              }}
                            >
                              <option hidden value="year">{dateT("year")}</option>
                              {[...Array(120).keys()].reverse().map((x, i) => {
                                let finalDate = baseYear + (++x);
                                return (
                                  <option key={`year-${i}`} value={finalDate}>{finalDate}</option>
                                )
                              })}
                            </Input>
                          </div>
                          )
                      }
                    })}

                    

                  </div>
                </FormGroup>
              </Col>
              <Col sm="12" md="6" className={`has-label ${errors.gender && 'has-danger'
                } `}>
                <label>{t("gender")} *</label>
                <FormGroup>
                  <Input
                    type="select"
                    name="gender"
                    id="gender"
                    innerRef={register}
                    onChange={solvePronoun}
                  >
                    {/* <option value="" selected disabled hidden>{t("gender")}</option> */}
                    <option value="Male">{t("male")}</option>
                    <option value="Female">{t("female")}</option>
                    <option value="Other">{t("other")}</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
          </Col>


          <Col>
            <Row>
              {/*
              <Col sm="12" md="6">
                <FormGroup
                  className={`has-label ${errors.email && 'has-danger'
                    } `}>
                  <label>Email *</label>
                  <Input
                    name="email"
                    placeholder="Email"
                    type="email"
                    innerRef={register}
                  />
                </FormGroup>
              </Col>
              */}
              <Col sm="12" md="6">
                <FormGroup
                  className={`has-label ${errors.phone && 'has-danger'
                    } `}>
                  <label>{t("phone-number")} *</label>
                  <Input
                    name="phone"
                    id="phone"
                    placeholder={t("phone-number")}
                    type="phone"
                    // minLength="6"
                    innerRef={register}
                  />
                </FormGroup>
              </Col>

              <Col sm="12" md="6" className={`has-label ${errors.pronoun && 'has-danger'
                } `}>
                <label>{t("pronoun")}</label>
                <FormGroup>
                  <Input
                    type="select"
                    name="pronoun"
                    id="pronoun"
                    innerRef={register}
                  >
                    {/* <option value="" selected disabled hidden>{t("gender")}</option> */}
                    <option value={t("pronoun-male")}>{t("pronoun-male")}</option>
                    <option value={t("pronoun-female")}>{t("pronoun-female")}</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col sm="12" md="6" className={`has-label ${errors.title && 'has-danger'
                } `}>
                <label>{"Titel"}</label>
                <FormGroup>
                  <Input
                    type="text"
                    name="title"
                    id="title"
                    innerRef={register}
                  >
                  </Input>
                </FormGroup>
              </Col>
            </Row>
          </Col>


          <Col className="d-none">
            <Row className="mb-4">
              <Col sm="12" md="6">
                <FormGroup
                  className={`has-label ${errors.username && 'has-danger'
                    } `}>
                  <label>{t("username")}</label>
                  <Input
                    name="username"
                    id="username"
                    placeholder="Username"
                    type="text"
                    innerRef={register}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </div>

      </Row>


      {/* Insurance Information */}
      <Row>
        <Col sm="12" className="mb-4">
          <div className="profile-title pb-4">{t("insurance-information")}</div>
        </Col>
        <Col sm="12">
          <FormGroup
            className={`has-label ${errors.company && errors.company.type && 'has-danger'
              } `}>
            <label>{t("company-type")} *</label>
            <Input
              type="select"
              className="react-select info"
              placeholder={t("company-type")}
              name="company.type"
              innerRef={register}
              onChange={handleCompanyChangeType}
            >
              {companyTypeOptions && companyTypeOptions.map((data, i) => (
                <option value={data.value} key={i}>
                  {data.label}
                </option>
              ))}
            </Input>
          </FormGroup>
        </Col>
      </Row>
      {selfPayerState == true ?
        null
        :
        <Row>
          <Col sm="12" md="6">
            <FormGroup
              className={`has-label ${errors.company && errors.company.name && 'has-danger'
                } `}>
              <label>{t("company-name")} *</label>
              <Input
                name="company.name"
                placeholder={t("company-name")}
                type="text"
                innerRef={register}
              />
            </FormGroup>
          </Col>
          <Col sm="12" md="6">
            <FormGroup
              className={`has-label ${errors.company && errors.company.number && 'has-danger'
                } `}>
              <label>{t("number")}</label>
              <Input
                name="company.number"
                placeholder={t("number")}
                type="text"
                innerRef={register}
              />
            </FormGroup>
          </Col>
        </Row>
      }
      <div className="d-flex profile-buttons" style={{ alignItems: "center" }}>

        <div className=" mr-3" >
          <span>* {t("mandatory-fields")}</span>
        </div>
        {!profile.is_ready ? (<>
          <Button type="submit" name="btn-next" size="sm" color="info" className="mr-1">
            {t("next")}
          </Button>
        </>) : (<>
          <Button disabled={Object.keys(errors).length ? true : false} type="submit" name="btn-save" size="sm" color="info" className="mr-1">
            {t("save")}
          </Button>
          <Button
            type="button"
            size="sm"
            color="primary"
            name="btn-cancel"
            onClick={props.history.goBack}>
            {t("cancel")}
          </Button>
        </>)}

      </div>
    </Form>
  );
};

export default ProfileForm;
