import React, { useState } from 'react';
//import { useSelector, useDispatch } from "react-redux";
//import { actionSetLang } from "store/actions/usersActions";
//import languages from "configs/lang";
import Icon from 'components/Icons/Icon';
import { useDispatch } from 'react-redux';
import { actionLogout } from 'store/actions/usersActions';
import { useTranslate } from "react-translate";

import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from 'reactstrap';

function UserMenu(props) {
  const dispatch = useDispatch();
  const [dropdownOpenUser, setdropdownOpenUser] = useState(false);
  const t = useTranslate('menu');

  const dropdownToggleUser = () => {
    setdropdownOpenUser(!dropdownOpenUser);
  };

  // User Logout
  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(actionLogout());
  };

  const handleSettings = (e) => {
    e.preventDefault();
    props.history.push('/admin/settings');
  };

  return (
    <Dropdown nav isOpen={dropdownOpenUser} toggle={dropdownToggleUser}>
      <DropdownToggle caret nav>
        <Icon file="user-header.svg" width="21px" title="User" />
        <p className="">
          <span className="d-none">{t('account')}</span>
        </p>
      </DropdownToggle>
      
      <DropdownMenu right>
        {/*<DropdownItem tag="a" href="#">
                Action
            </DropdownItem> */}
        
        {!localStorage.getItem("pending_initial_choice") && (
          <>
          <DropdownItem
            tag="a"
            href="#"
            name="action_goto_profile"
            onClick={(e) => {
              e.preventDefault();
              props.history.push('/admin/profile');
            }}
          >
            {t('profil')}
          </DropdownItem>
          <DropdownItem tag="a" href="#" name="action_goto_settings" onClick={handleSettings}>
            {t('einstellungen')}
          </DropdownItem>
        </>
      )}
      
        <DropdownItem tag="a" href="#" name="action_goto_professionals" onClick={(e) => { window.location.href = process.env.REACT_APP_HOST_DOCTORS; }}>
          {t('fur-professional')}
        </DropdownItem>
        <DropdownItem tag="a" href="#" name="action_logout" onClick={handleLogout}>
          {t('abmelden')}
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}

export default UserMenu;
