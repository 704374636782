import { call, put, takeEvery } from 'redux-saga/effects';
import CONSTANTS from '../CONSTANTS';
//Actions
import {
    actionSetDoctors,
} from "../actions/doctorsActions";
//services
import PatientsService from "services/PatientsService";

function* sagaDoctors() {
    try {
        const result = yield call(PatientsService.getDoctors);
        if (result.status >= 400) {
            throw new Error("Error retrieving doctors list");
        }
        yield put(actionSetDoctors(result.data));
    } catch (error) {
        console.log(error);
    }
}

export function* doctorsSaga() {
    console.log("Main Doctors Saga");
    yield takeEvery(CONSTANTS.DOCTORS_GET_LIST, sagaDoctors);
}