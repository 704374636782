import ShippingForm from "components/Devices/Booking/ShippingForm";
import React from "react";

class StepShipping extends React.Component {
  isValidated() {
    return true;
  }
  render() {
    return (
      <div className="container">
        <ShippingForm />
      </div>
    );
  }
}

export default StepShipping;
