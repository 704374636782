/*!

=========================================================
* Now UI Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { TranslatorProvider } from "react-translate";
import "react-toastify/dist/ReactToastify.css";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar";
import Footer from "components/Footer/Footer";
//Other
import routes from "routes.js";
import { tokenName } from "configs/app.json";
import { useSelector, useDispatch } from "react-redux";
import { actionGetUser } from "store/actions/usersActions";
import { useCallback } from "react";
import Loading from "plugins/TS-lib-utils-public/components/Loading/LoadingGlobal";

var ps;

const Auth = (props) => {
  // const fullPagesRef = useRef(null);
  const { language, _id } = useSelector((state) => state.userReducer);
  const loading = useSelector((state) => state.loadingReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      let fullPage = document.getElementById("full-page");
      ps = new PerfectScrollbar(fullPage);
    }
    return () => {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let token = localStorage.getItem(tokenName);
    if (token && !_id) {
      dispatch(actionGetUser());
    }
    return () => {
      token = null;
    };
  }, [dispatch, _id]);

  const getRoutes = useCallback((routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  }, []);

  return (
    <TranslatorProvider
      translations={require(`assets/i18n/${language || "en"}.json`)}
    >
      <Loading loading={loading} />
      <AuthNavbar {...props} authNavbar />
      <div className="wrapper wrapper-full-page animated fadeIn" id="full-page">
        <div className="full-page section-image" filter-color="blue">
          <Switch>
            {getRoutes(routes)}
            <Redirect from="/auth" to="/auth/login-page" />
          </Switch>
          <Footer fluid />
        </div>
      </div>
    </TranslatorProvider>
  );
};

export default Auth;
