/*!

=========================================================
* Now UI Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { translate, TranslatorProvider } from "react-translate";
import languages from "configs/lang";
import FormChangePassword from 'components/Users/FormChangePassword';
import CredentialsForm from 'components/Users/CredentialsForm';
import SessionManagerSection from './SessionManagerSection';
import UserDeleteForm from "components/Users/UserDeleteForm";
import PushNotifications from 'components/Notifications/PushNotifications';

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Row,
  FormGroup,
  Input,
  CardTitle,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  CardText,
} from "reactstrap";
import Main2fa from "components/Users/Main2fa";
import moment from "moment";
import {
  actionUpdateLang,
  actionUpdateDateFormat,
} from "store/actions/usersActions";
import LegalContent from "views/Components/LegalContent";

const Settings = (props) => {
  const { t } = props;
  const user = useSelector((state) => state.userReducer);
  const { profile } = useSelector((state) => state.profilesReducer);
  const dispatch = useDispatch();
  const { language, dateFormat } = useSelector((state) => state.userReducer);

  const handleLang = (e) => {
    let lang = e.target.value;
    dispatch(actionUpdateLang(lang));
  };

  const handleDateFormat = (e) => {
    let dateFormat = e.target.value;
    dispatch(actionUpdateDateFormat(dateFormat));
  };

  return (
    <div className="content">

          <Card>
            <CardHeader>
              <CardTitle className="d-flex align-content-center">
                  <div className="general-card-title-img text-primary mr-2">
                      <i className="icon-settings1" />
                  </div>
                  <div className="general-card-title text-primary">
                      {profile.is_ready ? "Settings" : t("step-1-set-up-a-password")}
                  </div>
              </CardTitle>
            </CardHeader>
            <CardBody style={{ paddingTop: "0px" }}>
              <div className="setting-forms">
                <Row>
                  <Col>
                    {/* Change Credentials */}
                    {(profile.is_ready && !user.password_expired) && (
                      <>
                        {/* edit formats of date and language */}
                        <h5 className="general-card-subtitle">
                          {t('user-settings')}
                        </h5>
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <label>{t('date-format')}</label>
                              <Input
                                type="select"
                                name="dateFormat"
                                defaultValue={dateFormat}
                                onChange={handleDateFormat}
                              >
                                <option value="DD.MM.YYYY">
                                  DD.MM.YYYY ({moment().format('DD.MM.YYYY')})
                                </option>
                                <option value="MM-DD-YYYY">
                                  MM-DD-YYYY ({moment().format('MM-DD-YYYY')})
                                </option>
                                <option value="DD-MM-YYYY">
                                  DD-MM-YYYY ({moment().format('DD-MM-YYYY')})
                                </option>
                                <option value="YYYY-MM-DD">
                                  YYYY-MM-DD ({moment().format('YYYY-MM-DD')})
                                </option>
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label>{t('language')}</label>
                              <Input
                                type="select"
                                name="date-format"
                                className="disabled"
                                defaultValue={language}
                                onChange={handleLang}
                              >
                                {/* map enabled languages */}
                                {Object.keys(languages).map((val) => {
                                  if (languages[val].abbr !== "DEU") {
                                    return null;
                                  }
                                  return (
                                    <option key={val} value={val}>
                                      {languages[val].name}
                                    </option>
                                  );
                                })}
                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        {/* SESSION MANAGER SECTION FUE OCULTADO PARA EL ONBOARDING NOMAS, LUEGO DEBE SER DESCOMENTADO */}
                        {/* <SessionManagerSection /> */}
                        <PushNotifications />
                        {/* componente of Two Factor Authentications  */}
                        <Main2fa />
                        {/* edit mail user  */}
                        <CredentialsForm />
                      </>
                    )}

                    {/* Change Password */}
                    <FormChangePassword view={!profile.is_ready ? "onboarding" : "settings"} />

                    {/* Delete account */}
                    {(profile.is_ready && !user.password_expired) && (
                      <UserDeleteForm />
                    )}

                    {/* Legals */}
                    <div className="setting-forms setting-section">
                      <h5 className="general-card-subtitle">{t('information')}</h5>
                      {/* <div className="container-fluid"> */}
                      <Row className="ml-auto mr-auto justify-content-center">

                        {!profile.is_ready && <>
                          <div className="col text-center">
                            <CardText>Mit meiner Anmeldung oder Terminbuchung stimme ich den AGB’s zu. Bitte beachten Sie <br />
                              die Informationen zum „Datenschutz“, zu „Meine Daten“ und zu den AGB’s.
                            </CardText>
                          </div>
                        </>}

                        <TranslatorProvider translations={require(`assets/i18n/terms/${language || 'en'}.json`)}>
                          <LegalContent />
                        </TranslatorProvider>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>

    </div>
  );
};

export default translate("settings")(Settings);
