import CONSTANTS from "../CONSTANTS";

export const actionGetNotifications = (module = null) => ({
  type: CONSTANTS.NOTIFICATIONS_GET,
  module,
});

export const actionSetNotificationsMessages = (notifications) => ({
  type: CONSTANTS.NOTIFICATIONS_MESSAGE_SET,
  notifications,
});

export const actionSetNotificationsChats = (notifications) => ({
  type: CONSTANTS.NOTIFICATIONS_CHAT_SET,
  notifications,
});

export const actionSetNotificationsLaboratory = (notifications) => ({
  type: CONSTANTS.NOTIFICATIONS_LAB_SET,
  notifications,
});

export const actionUpdateNotification = (notification) => ({
  type: CONSTANTS.NOTIFICATIONS_UPDATE,
  notification,
});

/**
 * Read a notification
 * @param {boolean} read it's read true or false
 */
export const actionReadNotification = (id, read) => ({
  type: CONSTANTS.NOTIFICATIONS_READ,
  id,
  read,
});

export const actionReadNotificationsByMessages = (module, messages) => ({
  type: CONSTANTS.NOTIFICATIONS_READBYMESSAGES,
  module,
  messages,
});

export const actionReadAllNotifications = (module) => ({
  type: CONSTANTS.NOTIFICATIONS_READALL,
  module,
});
