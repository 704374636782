import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Row, Col, FormGroup, Input, Fade, Button } from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import Qr2fa from "./Qr2fa";
import { actionActivate2fa } from "store/actions/usersActions";
import Loading from "plugins/TS-lib-utils-public/components/Loading/LoadingLocal";
import { toast } from "react-toastify";
import { useTranslate } from 'react-translate';

const Form2fa = () => {
  const user = useSelector((state) => state.userReducer);
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(true);
  const t=useTranslate('settings');



  const handleChangeCode = (e) => {
    const { name, value } = e.target;
    if (name === "code2fa") {
      if (value.length === 6) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }
  };

  const onSubmit = (data) => {
    if (user.auth_2fa === "true" || user.auth_2fa === true) {
      toast.warning( t('toast-option-activated') /*"This options is activated"*/, t('toast-2fa') /*"Two factor authentication"*/);
    } else {
      toast.info(t('toast-activation-2fa'));
      dispatch(actionActivate2fa(data.code2fa));
    }
  };

  if (user.loading) {
    return <Loading />;
  }

  return (
    <Fade in={user.uri ? true : false}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {user.uri && <Qr2fa user={user} />}
        <FormGroup>
          <label>
            {t('code-generated')}            
          </label>
          <Row>
            <Col xs={12} md={6}>
              <Input
                className="pull-right"
                name="code2fa"
                placeholder="123456"
                type="number"
                onChange={handleChangeCode}
                innerRef={register}
                autoComplete="off"
              />
            </Col>
            <Col xs={12} md={6}>
              <Button
                type="submit"
                className="m-0"
                name="btn-activate-2fa"
                disabled={disabled}
                color="primary"
              >
                {t('btn-activate')}
              </Button>
            </Col>
          </Row>
        </FormGroup>
      </form>
    </Fade>
  );
};

export default Form2fa;
