import { takeEvery, put, call, select } from "redux-saga/effects";
import { toast } from "react-toastify";
//Redux Actions
import CONSTANTS from "../CONSTANTS";
import {
  actionSetMessages,
  actionSetMessage,
  actionSetMessagesScroll,
  actionSetOutboxScroll,
  actionSetPriorityboxScroll,
  actionGetOutboxMessage,
  actionSetBox,
  actionGetMessages,
  actionOutboxGetMessages,
  actionGetPriorityMessages,
  actionOutboxSetMessages,
  actionSetPriorityBox,
} from "../actions/messagesActions";
//Services
import MessagesService from "../../plugins/TS-lib-utils-public/services/MessagesService.js";
import { getMessages, getUser } from "store/selectors/selectors";
import utilTranslations from "helpers/utilTranslations";
MessagesService.setBaseUrl("/patients");
/**
 * Send a menssage
 * @param {object} message
 */
function* sagaSendMessage({ message, practiceId, refresh_outbox }) {
  const userActive = yield select(getUser);
  const t = utilTranslations(userActive.language || 'en', 'messages-saga');
  try {
    const result = yield call(MessagesService.reply, practiceId, message);
    if (result.status > 201) {
      throw new Error(result.responseText);
    }
    if (result.status === 200 && refresh_outbox) {
      const message = result.data.message;
      yield put(actionSetMessage());
      yield put(actionOutboxGetMessages());
      yield put(actionGetOutboxMessage(message._id));
      yield put(actionSetBox("outbox"));
      // toast.success(t('message-has-been-sent'));
    }
  } catch (error) {
    toast.error(error.message || t("message-not-sent"));
  }
}

/**
 * Delete a message
 * @param {array} messages_id messages id
 */
function* sagaDelMessage({ messages }) {
  const userActive = yield select(getUser);
  const t = utilTranslations(userActive.language || 'en', 'messages-saga');
  try {
    const { box } = yield select(getMessages);
    const result = yield call(
      MessagesService.del,
      null,
      messages,
      box === "outbox" ? "outbox" : "inbox"
    );
    if (result.status === 200) {
      switch (box) {
        case "inbox":
          yield put(actionGetMessages());
          break;
        case "outbox":
          yield put(actionOutboxGetMessages());
          break;
        case "priority":
          yield put(actionGetPriorityMessages());
          break;
        default:
          break;
      }
      yield put(actionSetMessage());
      toast.info(t("message-has-been-deleted"));
    }
  } catch (error) {
    toast.error(error.message);
  }
}

/**
 * Get inbox messages
 */
function* sagaMessages({ page, limit, search }) {
  try {
    const result = yield call(MessagesService.inbox, null, page, limit, search);
    yield put(actionSetMessages(result));
  } catch (error) {
    toast.error(error.message);
  }
}
/**
 * Get one message
 * @param {string} id message id
 */
function* sagaGetMessage({ id }) {
  try {
    const message = yield call(MessagesService.getById, null, id, "inbox");
    yield put(actionSetMessage(message));
  } catch (error) {
    toast.error(error.message);
  }
}

/**
 * Get outbox messages
 */
function* sagaMessagesOutbox({ page, limit, search }) {
  try {
    const result = yield call(
      MessagesService.outbox,
      null,
      page,
      limit,
      search
    );
    yield put(actionOutboxSetMessages(result));
  } catch (error) {
    toast.error(error.message);
  }
}
/**
 * Get one message
 * @param {string} id message id
 */
function* sagaGetOutboxMessage({ id }) {
  try {
    const message = yield call(MessagesService.getById, null, id, "outbox");
    yield put(actionSetMessage(message));
  } catch (error) {
    toast.error(error.message);
  }
}

/**
 * Get one message from notifications
 * @param {string} id message id
 */
function* sagaGetMessageNotification({ messageId }) {
  try {
    // yield put(actionSetLoadingMsg(true));
    const message = yield call(
      MessagesService.getById,
      null,
      messageId,
      "inbox"
    );
    yield put(actionSetMessage(message));
    // yield put(actionSetLoadingMsg(false));
  } catch (error) {
    toast.error(error.message);
  }
}

/**
 * Get priority box messages
 */
function* sagaMessagesPriority({ page, limit, search }) {
  try {
    const result = yield call(
      MessagesService.priority,
      null,
      page,
      limit,
      search
    );
    yield put(actionSetPriorityBox(result));
  } catch (error) {
    toast.error(error.message);
  }
}

function* sagaMessagesScroll({ search }) {
  try {
    const { box, inbox, outbox, prioritybox } = yield select(getMessages);
    let result = [];
    switch (box) {
      case "inbox":
        result = yield call(
          MessagesService.inbox,
          null,
          inbox.page + 1,
          20,
          search
        );
        yield put(actionSetMessagesScroll(result));
        break;
      case "outbox":
        result = yield call(
          MessagesService.outbox,
          null,
          outbox.page + 1,
          20,
          search
        );
        yield put(actionSetOutboxScroll(result));
        break;
      case "priority":
        result = yield call(
          MessagesService.priority,
          null,
          prioritybox.page + 1,
          20,
          search
        );
        yield put(actionSetPriorityboxScroll(result));
        break;
      default:
        break;
    }
  } catch (error) {
    toast.error(error.message);
  }
}
/**
 * Set message important
 * @param {array} messages array of id messages
 */
function* sagaRefreshBoxes() {
  try {
    yield put(actionGetMessages(1));
    yield put(actionOutboxGetMessages(1));
    yield put(actionGetPriorityMessages(1));
  } catch (error) {
    toast.error(error.message);
  }
}
/**
 * Set message important
 * @param {array} messages array of id messages
 */
function* sagaSetPriority({ messages }) {
  const userActive = yield select(getUser);
  const t = utilTranslations(userActive.language || 'en', 'messages-saga');
  try {
    const { box } = yield select(getMessages);
    const result = yield call(MessagesService.setPriority, null, messages);

    if (result.status === 200 && result.data.status === "success") {
      let result;
      switch (box) {
        case "inbox":
          result = yield call(MessagesService.inbox, null, 1);
          yield put(actionSetMessages(result));
          break;
        case "outbox":
          result = yield call(MessagesService.outbox, null, 1);
          yield put(actionOutboxSetMessages(result));
          break;
        case "priority":
          break;
        default:
          break;
      }
      result = yield put(actionGetPriorityMessages(1));
      yield put(actionSetPriorityBox(result));
      toast.success(t("priority-updated"));
    }
  } catch (error) {
    toast.error(error.message);
  }
}

export function* messagesSaga() {
  console.log("*Main Messages Saga");
  yield takeEvery(CONSTANTS.MESSAGES_GET_SCROLL, sagaMessagesScroll);
  yield takeEvery(CONSTANTS.MESSAGES_GET_INBOX, sagaMessages);
  yield takeEvery(CONSTANTS.MESSAGES_GET_OUTBOX, sagaMessagesOutbox);
  yield takeEvery(CONSTANTS.MESSAGES_GET_MSG, sagaGetMessage);
  yield takeEvery(CONSTANTS.MESSAGES_GET_OUTBOX_MSG, sagaGetOutboxMessage);
  yield takeEvery(CONSTANTS.MESSAGES_DEL_MSG, sagaDelMessage);
  yield takeEvery(CONSTANTS.MESSAGES_SEND_MSG, sagaSendMessage);
  yield takeEvery(CONSTANTS.MESSAGES_GET_PRIORITY, sagaMessagesPriority);
  yield takeEvery(CONSTANTS.MESSAGES_SET_PRIORITY, sagaSetPriority);
  yield takeEvery(CONSTANTS.MESSAGES_REFRESH_BOXES, sagaRefreshBoxes);
  yield takeEvery(
    CONSTANTS.MESSAGES_GET_MSG_NOTIFICATION,
    sagaGetMessageNotification
  );
  //yield takeEvery(CONSTANTS.MESSAGES_GET_PRIORITY, sagaMessagesPriority);
}
