import QuestionnaryForm from "components/Devices/Booking/QuestionnaryForm";
import React from 'react'

class StepQuestionnary extends React.Component {
  isValidated() {
    return true;
  }
  render() {
    return (
      <div className="container overflow-auto" style={{ maxHeight: "300px" }}>
        <QuestionnaryForm />
      </div>
    );
  }
}

export default StepQuestionnary
