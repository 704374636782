import React from "react";
import { Button } from "reactstrap";
import { useTranslate } from 'react-translate';

const BtnNewNote = () => {
  const t = useTranslate('buttons');

  return (
    <Button size="sm" color="info" disabled>
      {t('new-note')} <i className="fas fa-plus"></i>
    </Button>
  );
};

export default BtnNewNote;
