import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

/** Hook validator */
const useAuthorizationValidator = (practice_id = null) => {
  const user = useSelector((state) => state.userReducer);
  let permissions = useSelector((state) => state.userAuthorizationReducer);

  const setPractice = (id) => (practice_id = id);

  const checkAccess = (moduleKey, actionKey, practice_id) => {
    if (practice_id) {
      permissions = user.practices[practice_id];
    }
    if (!permissions) {
      return false;
    }
    /**
     * Get module access
     */
    if (moduleKey && !actionKey) {
      const module = permissions?.modules?.findIndex(
        (m) => m.key === moduleKey
      );
      return module >= 0;
    }
    /**
     * Get action access
     */
    if (moduleKey && actionKey) {
      const action = permissions?.modules?.findIndex((m) => {
        if (m.key === moduleKey) {
          return m?.actions?.includes(actionKey);
        }
        return false;
      });
      return action >= 0;
    }
    return false;
  };

  return {
    setPractice,
    checkAccess,
  };
};
/** Props validation */
useAuthorizationValidator.propTypes = {
  practice_id: PropTypes.string,
};

export default useAuthorizationValidator;
