import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslate } from 'react-translate';

function WsStatus() {
  const t = useTranslate('navbar');
  const { ws_status } = useSelector((state) => state.commonReducer);

  return (
    <li className="d-flex align-items-center nav-item mr-2">
      <span
        className={`tp-icon icon-signal ws-status ${ws_status}`}
        width="21px"
        title={`${t(ws_status)}`}
      />
      {/* <Icon file="hospital.svg" width="21px" title="Practices" /> */}
      {/* <p className="">
          <span className="d-none">Practices</span>
        </p> */}
    </li>
  );
}

export default WsStatus;
