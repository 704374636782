import CONSTANTS from "../CONSTANTS";

const initialState = {
  profile: {},
  loading: false,
};

const profilesReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.PROFILE_SET:
      return { ...state, profile: action.profile };
    case CONSTANTS.PROFILE_LOADING:
      return { ...state, loading: action.loading };
    default:
      return state;
  }
};

export default profilesReducer;
