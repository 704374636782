import CONSTANTS from '../CONSTANTS';
//Set avatar
export const actionSetAvatars = (avatars) => ({
  type: CONSTANTS.SET_AVATARS,
  avatars,
});
export const actionPushAvatar = (avatar, response) => ({
  type: CONSTANTS.PUSH_AVATAR,
  avatar,
  response
});
export const actionRemoveAvatars = (userId) => ({
  type: CONSTANTS.REMOVE_AVATARS,
  userId,
});
