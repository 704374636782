import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import * as yup from 'yup';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { actionAddressSave } from 'store/actions/profilesActions';
import useCountriesByLang from 'hooks/useCountriesByLang';
import { useState } from 'react';
import history from 'historyRouter';
import { useTranslate } from 'react-translate';
import storageDispatcher from "helpers/storageDispatcher";

const AddressSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  companyName: yup.string(),
  type: yup.string().required(),
  scope: yup.string().required(),
  country: yup.object().shape({
    label: yup.string(),
    value: yup.string(),
  }),
  city: yup.string().required(),
  apartment: yup.string(),
  postalCode: yup.string().required(),
  street: yup.string().required(),
  phone: yup.string(),
  packstation: yup.string(),
  packstationNumber: yup.string(),
  favourite: yup.boolean(),
});

const AddressForm = ({ addressState, setAddressState }) => {
  const dispatch = useDispatch();
  const [favourite, setFavourite] = useState(
    addressState.data && addressState.data.favourite
  );

  const user = useSelector((state) => state.userReducer);
  const { profile } = useSelector((state) => state.profilesReducer);

  const t = useTranslate('profile-address');

  /**
   * Load countries
   */
  const countryOptions = useCountriesByLang('react-select');
  const countryQty = countryOptions.length;

  /**
   * Default country is Germany with id 276
   */
  const findDefaultCountry = () => {
    if (
      countryOptions.length > 0 &&
      Object.keys(addressState.data).length === 0
    ) {
      return countryOptions.find((option) => option.value === 276);
    }
    return null;
  };
  /**
   * Address selected
   */
  const addressId = addressState.data._id ? addressState.data._id : null;
  /**
   *
   */
  let defaultValues = addressState.data;

  /**
   * useFormHook
   */
  const {
    handleSubmit,
    reset,
    control,
    errors,
    register,
    watch,
    setValue,
  } = useForm({
    validationSchema: AddressSchema,
    defaultValues: defaultValues,
  });

  /**
   * Watch scope option
   */

  const scopeWatch = watch('scope');
  const typeWatch = watch('type');

  /**
   * Saving address
   * @param {object} data
   */
  const onSubmit = (data) => {
    console.log(data);
    dispatch(actionAddressSave(data));
    setAddressState({
      data: '',
      showForm: false,
    });
    window.scrollTo(0, 0);
    if (!profile.is_ready) {
      storageDispatcher.removeLocalStorageEvent("pending_initial_choice");
      history.push('/admin/frametest');
    }
  };

  useEffect(() => {
    if (!addressId) {
      const countryDefault = findDefaultCountry();
      if (countryDefault) {
        reset({ ...defaultValues, country: countryDefault });
      }
    } else {
      reset(defaultValues);
    }
    // eslint-disable-next-line
  }, [reset, addressId, countryQty]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="address-form">
      {addressState.data && addressState.data._id && (
        <input type="hidden" name="_id" ref={register} />
      )}

      <input
        type="hidden"
        id="billing"
        name="type"
        value="billing"
        ref={register}
        defaultChecked={true}
      />
      {/*
      <Row>
        <Col>
          <p className="text-center">
            <strong>Choose a kind of address</strong>
          </p>
          <div className="switch-field switch-field-type-address">
            <div className="switch-title text-center d-flex">
              <div className="switch-field-option">
                <input
                  type="radio"
                  id="billing"
                  name="type"
                  value="billing"
                  ref={register}
                  defaultChecked={!addressId}
                />
                <label htmlFor="billing">
                  <span className="icon-billing"></span>
                  <div className="text-center">Billing Address</div>
                </label>
              </div>

              <div className="switch-field-option">
                <input
                  type="radio"
                  id="shipping"
                  name="type"
                  value="shipping"
                  ref={register}
                />
                <label htmlFor="shipping">
                  <span className="icon-shipping"></span>
                  <div className="text-center">Shiping Address</div>
                </label>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      */}

      <Row>
        <Col>
          <div className="switch-field switch-field-type-person">
            <div className="switch-title text-center d-flex">
              <div className="mr-2">
                <input
                  type="radio"
                  id="private"
                  name="scope"
                  value="private"
                  ref={register}
                  defaultChecked={true}
                />
                <label htmlFor="private">
                  <span className="btn btn-sm btn-info">{t("adress-private")}</span>
                </label>
              </div>

              <div className="mr-2">
                <input
                  type="radio"
                  id="company"
                  name="scope"
                  value="company"
                  ref={register}
                />
                <label htmlFor="company">
                  <span className="btn btn-sm btn-info">{t("company")}</span>
                </label>
              </div>

              {/*typeWatch === 'shipping' && (
                <div className="mr-2">
                  <input
                    type="radio"
                    id="packstation"
                    name="scope"
                    value="packstation"
                    ref={register}
                  />
                  <label htmlFor="packstation">
                    <span className="btn btn-sm btn-info">
                      {' '}
                      DHL Packstation
                    </span>
                  </label>
                </div>
              )*/}
            </div>
          </div>
        </Col>
      </Row>

      {/*
      <Row>
        <Col>
          {scopeWatch === 'company' && (
            <>
              <FormGroup
                className={`has-label ${errors.companyName && 'has-danger'} `}>
                <label>{t("company-name")} *</label>
                <Controller
                  as={<Input />}
                  control={control}
                  name="companyName"
                  placeholder={t("company-name")}
                  defaultValue={
                    !addressId
                      ? profile.company.name
                      : addressState.data.companyName
                  }
                  required
                />
              </FormGroup>
            </>
          )}
        </Col>
      </Row>
                */}
      {/*
      <Row>
        <Col className="d-flex">
          <div className="input-radio">
            <input
              id="mr"
              ref={register}
              defaultChecked={addressState.data.type === 'Mr.'}
              defaultValue="Mr."
              name="title"
              type="radio"
              required
            />
            <label className="ml-1" htmlFor="mr">
              {t("mr")}
            </label>
          </div>
          <div className="input-radio">
            <input
              id="mrs"
              ref={register}
              defaultChecked={!addressId || addressState.data.type === 'Mrs.'}
              defaultValue="Mrs."
              name="title"
              type="radio"
              required
            />
            <label className="ml-1" htmlFor="mrs">
              {t("mrs")}
            </label>
          </div>
          <div className="input-radio">
            <input
              id="without"
              ref={register}
              defaultChecked={!addressId || !addressState.data.type}
              defaultValue=""
              name="title"
              type="radio"
              required
            />
            <label className="ml-1" htmlFor="without">
              {' '}
              {t("without-specification")}
            </label>
          </div>
          <span> *</span>
        </Col>
      </Row>
                */}
      <Row>
        <Col>
          <FormGroup
            className={`has-label ${errors.firstName && 'has-danger'} `}>
            <label>{t("first-name")} *</label>
            <Controller
              as={<Input />}
              control={control}
              name="firstName"
              placeholder={t("first-name")}
              defaultValue={
                !addressId ? user.firstName : addressState.data.firstName
              }
              required
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup
            className={`has-label ${errors.lastName && 'has-danger'} `}>
            <label>{t("last-name")} *</label>
            <Controller
              as={<Input />}
              control={control}
              name="lastName"
              placeholder={t("last-name")}
              defaultValue={
                !addressId ? user.lastName : addressState.data.lastName
              }
              required
            />
          </FormGroup>
        </Col>
      </Row>
      {scopeWatch !== 'packstation' && (
        <React.Fragment>
          <Row>
            <Col>
              <FormGroup
                className={`has-label ${errors.street && 'has-danger'} `}>
                <label>{t("street-address")} *</label>
                <Controller
                  as={<Input />}
                  control={control}
                  name="street"
                  placeholder={t("street-address")}
                  defaultValue=""
                  required
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup
                className={`has-label ${errors.streetNumber && 'has-danger'} `}>
                <label>{t("street-number")} *</label>
                <Controller
                  as={<Input />}
                  control={control}
                  name="streetNumber"
                  placeholder={t("street-number")}
                  defaultValue=""
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup
                className={`has-label ${errors.apartment && 'has-danger'} `}>
                <label>{t("apartment-suite-other")}</label>
                <Controller
                  as={<Input />}
                  control={control}
                  name="apartment"
                  placeholder={t("apartment-suite-other")}
                  defaultValue=""
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup
                className={`has-label ${errors.postalCode && 'has-danger'} `}>
                <label>{t("postal-code")} *</label>
                <Controller
                  as={<Input />}
                  control={control}
                  name="postalCode"
                  placeholder={t("postal-code")}
                  defaultValue=""
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup
                className={`has-label ${errors.city && 'has-danger'} `}>
                <label>{t("city")} *</label>
                <Controller
                  as={<Input />}
                  control={control}
                  name="city"
                  placeholder={t("city")}
                  defaultValue=""
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup
                className={`has-label ${errors.country && 'has-danger'} `}>
                <label>{t("country")} *</label>
                <Controller
                  as={Select}
                  name="country"
                  className="react-select info"
                  classNamePrefix="react-select"
                  control={control}
                  placeholder={t("country")}
                  options={countryOptions}
                />
              </FormGroup>
            </Col>
          </Row>
        </React.Fragment>
      )}
      {/* For packstation option */}
      {scopeWatch === 'packstation' && (
        <React.Fragment>
          <Row>
            <Col>
              <FormGroup
                className={`has-label ${errors.packstation && 'has-danger'} `}>
                <label>{t('your-dhl-number')}</label>
                <Controller
                  as={<Input />}
                  control={control}
                  name="packstation"
                  placeholder="Packstation Name"
                  defaultValue=""
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup
                className={`has-label ${errors.packstationNumber && 'has-danger'
                  } `}>
                <label>{t('packstation-number')}</label>
                <Controller
                  as={<Input />}
                  control={control}
                  name="packstationNumber"
                  placeholder="Packstation Number"
                  defaultValue=""
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup
                className={`has-label ${errors.postalCode && 'has-danger'} `}>
                <label>{t('postal-code')}</label>
                <Controller
                  as={<Input />}
                  control={control}
                  name="postalCode"
                  placeholder="Postar Code"
                  defaultValue=""
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup
                className={`has-label ${errors.city && 'has-danger'} `}>
                <label>City</label>
                <Controller
                  as={<Input />}
                  control={control}
                  name="city"
                  placeholder="City"
                  defaultValue=""
                />
              </FormGroup>
            </Col>
          </Row>
        </React.Fragment>
      )}
      <Row>
        <Col className="address-buttons">
          <div className="mr-3">
            <span>* {t("mandatory-fields")}</span>
          </div>
          {profile.is_ready && (
            <div className="mr-3">
              <input
                type="hidden"
                name="favourite"
                ref={register}
                defaultValue={!!favourite}
              />
              <big style={{ cursor: 'pointer' }}>
                <span
                  className={`${favourite ? 'icon-star-active' : 'icon-star'
                    } text-info`}
                  onClick={() => {
                    setValue('favourite', !favourite);
                    setFavourite(!favourite);
                  }}></span>{' '}
              </big>
              <span>{t("my-favourite-address")}</span>
            </div>
          )}

          {profile.is_ready ? (<>
            <Button type="submit" size="sm" color="info" className="mr-1">
              {t("save")}
            </Button>
            <Button
              type="button"
              size="sm"
              color="primary"
              onClick={() => {
                setAddressState({
                  data: {},
                  showForm: false,
                });
              }}>
              {t("cancel")}
            </Button>
          </>) : (
            <Button type="submit" size="sm" color="info" className="mr-1">
              Next
            </Button>
          )}
        </Col>
      </Row>
    </Form>
  );
};

export default AddressForm;
