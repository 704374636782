import React from 'react';
// import PropTypes from "prop-types";
import Icon from 'components/Icons/Icon';
import { Row, Col } from 'reactstrap';

const DoctorInfo = (props) => {
  const { dateChecked, sm, md } = props;
  return (
    <div className="doctor">
      <Row>
        <Col sm={sm || 12} md={md || 6}>
          <a href="#pablo" onClick={(e) => e.preventDefault()}>
            <Icon
              file="jutta-big.png"
              className="avatar border-gray img-responsive"
              width="100px"
            />
          </a>
        </Col>
        <Col sm={sm || 12} md={md || 6}>
          <a href="#pablo" onClick={(e) => e.preventDefault()}>
            <h6 className="doctor-name">Dr. Jutta von Braunmühl</h6>
          </a>
          {dateChecked && <div className="doctor-date">{dateChecked}</div>}
          <div className="doctor-location">
            <span className="icon-pin"></span>
            <div className="address">
              <div className="line-1">Otto-Suhr-Allee 104</div>
              <div className="line-2">10787 Berlin, Germany</div>
              <div className="line-3">+49 30 49089535</div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DoctorInfo;
