import React from "react";
import PropTypes from "prop-types";
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import LoadingLocal from "plugins/TS-lib-utils-public/components/Loading/LoadingLocal";

const PaypalButton = ({ amount }) => {
  const clientId =
    "AbWSVe88FA6SWS42p8UsuVx8ICnsptbFryt36tE3_WHGbDN_xBc69GsCBWVdL206jeKyLjtk7_ml03a7";

  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            amount: {
              value: amount,
            },
          },
        ],
      })
      .then((orderID) => {
        return orderID;
      });
  };

  //Inner component
  const PayPalButton = ({ amount }) => {
    const [{ isPending }] = usePayPalScriptReducer();
    return (
      <React.Fragment>
        {isPending ? <LoadingLocal /> : null}
        <PayPalButtons createOrder={createOrder} forceReRender={amount} />
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <PayPalScriptProvider
        options={{ "client-id": clientId, currency: "EUR" }}
      >
        <PayPalButton amount={amount} />
      </PayPalScriptProvider>
    </React.Fragment>
  );
};

PaypalButton.propTypes = {
  amount: PropTypes.number.isRequired,
};

export default PaypalButton;
