import React, { useState } from 'react';
import { Button } from 'reactstrap';
import AddressCard from './AddressCard';
import AddressForm from './AddressForm';
import { useSelector } from "react-redux";
import { useEffect } from 'react';
import { useTranslate } from 'react-translate';

const Address = ({ address }) => {
  const [addressState, setAddressState] = useState({
    data: {},
    showForm: false,
  });
  const { profile } = useSelector((state) => state.profilesReducer);

  const t = useTranslate('profile-address')

  useEffect(() => {
    if (Object.keys(profile).length && !profile.is_ready) {
      setAddressState({
        data: profile.address.length ? profile.address[0] : {},
        showForm: true
      });
    }
  }, [profile]);

  // const [showAddressForm, setShowAddressForm] = useState(false);
  // const [addressData, setAddressData] = useState({});

  return (
    <div className="address">
      <div className="text-right">
        <Button
          color="info"
          name="btn-new-address"
          className={addressState.showForm ? 'd-none' : ''}
          onClick={() => {
            setAddressState({
              data: {},
              showForm: !addressState.showForm,
            });
          }}>
          {!addressState.showForm ? t("add-new-address") : ''}
        </Button>
      </div>

      {!addressState.showForm &&
        address.map((ad, i) => (
          <AddressCard key={i} address={ad} setAddressState={setAddressState} />
        ))}

      {addressState.showForm && (
        <AddressForm
          setAddressState={setAddressState}
          addressState={addressState}
        />
      )}
    </div>
  );
};

export default Address;
