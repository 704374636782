import CONSTANTS from '../CONSTANTS';

export const actionGetHelps = () => ({
  type: CONSTANTS.HELPS_GET,
});

export const actionSetHelps = ({ data }) => ({
  type: CONSTANTS.HELPS_SET,
  data,
});
