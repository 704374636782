import React, { useEffect, useRef, useState } from 'react';
import Icon from 'components/Icons/Icon';

//Read  last value of variable 'unreaded' 
const usePreviousValue = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

//this component in rendered is component in rendered 
const NotificationIcon = ({ unreaded, module }) => {
  const prevCount = usePreviousValue(unreaded);
  const title = 'TelePraxen';
  const notification_msg = 'New Message!';
  const ctrl_unreaded = unreaded > 99 ? `(${99}+)` : `(${unreaded})`;
  const data = {
    messages: {
      icon: 'inbox-header.svg',
      title: 'Inbox',
    },
    chats: {
      icon: 'message-header.svg',
      title: 'Chat',
    },
    laboratory: {
      icon: 'laboratory.svg',
      title: 'Labor',
    },
  };
  //control when run  interval function 
  const [isRunning, setIsRunning] = useState(false);

  //timer that changes the text of the browser tab
  useInterval(() => {
    let oldTitle = ctrl_unreaded + title;
    document.title = document.title !== notification_msg ? notification_msg : oldTitle;
    //run timer in 1 ms
  }, isRunning ? 1 : null);

  //custom hook 
  function useInterval(callback, isRunning) {
    //'savedCallback' persists across the re-renders. 
    const savedCallback = useRef();

    // Remember the latest function.
    useEffect(() => {
      //After every render, save the latest callback into our ref.
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      function tick() {
        //can read and call 'savecallback' it from inside our interval
        savedCallback.current();
      }
      if (isRunning) {
        //set the interval every one second when there is a new message and I am not in focus in the tab browser
        let id = setInterval(tick, 1000);
        return () => clearInterval(id);
      }
    }, [isRunning]);
  }

  useEffect(() => {
    //check if a message arrives when I'm not in the tab
    if (prevCount < unreaded && document.visibilityState !== 'visible') {
      updateTab('new_msg');
      //init timer
      setIsRunning(true);
    }

    if (unreaded > 0 && document.visibilityState === 'visible') {
      updateTab('count');
    }

    if (unreaded === 0 && document.visibilityState === 'visible') {
      updateTab('no_msg');
    }
    //eslint-disable-next-line
  }, [unreaded], [prevCount]);

  if (!module) {
    return <></>;
  }

  //Modify tab browser
  const updateTab = (type) => {
    switch (type) {
      case 'focus': document.title = (unreaded > 0 ? ctrl_unreaded : '') + title;
        break;
      case 'new_msg': document.title = 'New Message!';
        break;
      case 'count': document.title = (unreaded > 0 ? ctrl_unreaded : '') + title;
        break;
      case 'no_msg': document.title = title;
        break;
      default:
        break;
    }

  }

  //Focus in tab browser
  document.addEventListener("visibilitychange", function () {
    //User to do focus in tab
    if (document.visibilityState === 'visible') {
      updateTab('focus');
      //stop timer
      setIsRunning(false);
    }
  });


  return (
    <>
      <Icon
        file={data[module].icon || ''}
        width="21px"
        title={data[module].title || ''}
      />
      <p className="">
        <span className="d-lg-none d-md-block">
          {/* {data[module].title || ''} */}
        </span>
        {unreaded > 0 && (
          <>
            <span className={`notification-counter${unreaded > 99 ? '-plus' : ''}`}>
              {unreaded > 99 ? "99" : unreaded}
            </span>
            <span className="notification-plus">
              {unreaded > 99 ? '+' : ''}
            </span>
          </>
        )}
      </p>
    </>
  );
};

export default NotificationIcon;

//more information  and examples of useInterval  in https://overreacted.io/making-setinterval-declarative-with-react-hooks/