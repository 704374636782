import CONSTANTS from "../CONSTANTS";

export const actionGetUsersList = (query) => ({
  type: CONSTANTS.USERS_GET_LIST,
  query,
});
export const actionSetUsersList = (users) => ({
  type: CONSTANTS.USERS_SET_LIST,
  users,
});
