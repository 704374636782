import React from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
//Components
import { Row, Col } from 'reactstrap';
import { actionSetBox } from 'store/actions/messagesActions';
import { actionSetMessage } from 'store/actions/messagesActions';
import { useTranslate } from 'react-translate';
import history from 'historyRouter';

const InboxMenu = ({ setShowForm }) => {
  const t = useTranslate('messages');
  const dispatch = useDispatch();

  const { box, inbox, outbox, prioritybox } = useSelector(
    (state) => state.messagesReducer
  );

  return (
    <>
      <ListGroup className="inbox-menu mb-3">
        <ListGroupItem
          tag="a"
          className={box === 'inbox' ? 'active' : ''}
          name="tary_inbox"
          onClick={(e) => {
            e.preventDefault();
            if (window.location.href.includes("reply")) {
              history.push('/admin/inbox');
            }
            dispatch(actionSetBox('inbox'));
            dispatch(actionSetMessage());
            setShowForm(false);
          }}
        >
          <Row className="align-items-center">
            <Col xs="1" className="text-right">
              {' '}
              <span className="icon-allmail"></span>
            </Col>

            <Col xs="10">
              <span className="inbox-menu-text">
                {t('menu-inbox')}
                {`(${inbox.total ? inbox.total : 0})`}
              </span>
            </Col>
          </Row>
        </ListGroupItem>
        <ListGroupItem
          tag="a"
          className={box === 'outbox' ? 'active' : ''}
          name="tary_sended"
          onClick={(e) => {
            e.preventDefault();
            if (window.location.href.includes("reply")) {
              history.push('/admin/inbox');
            }
            dispatch(actionSetBox('outbox'));
            dispatch(actionSetMessage());
            setShowForm(false);
          }}
        >
          <Row className="align-items-center">
            <Col xs="1" className="text-right">
              <span className="icon-send"></span>
            </Col>

            <Col sm="10">
              <span className="inbox-menu-text">
                {' '}
                {t('menu-send')}
                {`(${outbox.total ? outbox.total : 0})`}
              </span>
            </Col>
          </Row>
        </ListGroupItem>
        <ListGroupItem
          tag="a"
          className={box === 'priority' ? 'active' : ''}
          onClick={(e) => {
            e.preventDefault();
            if (window.location.href.includes("reply")) {
              history.push('/admin/inbox');
            }
            dispatch(actionSetBox('priority'));
            dispatch(actionSetMessage());
            setShowForm(false);
          }}
        >
          <Row className="align-items-center">
            <Col xs="1" className="text-right">
              <span className="icon-star"></span>
            </Col>

            <Col xs="10">
              <span className="inbox-menu-text">
                {' '}
                {t('menu-important')}
                {`(${prioritybox.total ? prioritybox.total : 0})`}
              </span>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </>
  );
};

export default InboxMenu;
