import CONSTANTS from "../CONSTANTS";

export const actionSweetAlert = (sweetAlert) => ({
  type: CONSTANTS.SWEET_ALERT,
  sweetAlert,
});

export const actionMobileVersion = (mobile) => ({
  type: CONSTANTS.MOBILE_VERSION,
  mobile,
});

//Native webview is different that mobile view (users can access the site through any mobile navigator)
export const actionNativeWebView = (native) => ({
  type: CONSTANTS.NATIVE_WEBVIEW,
  native,
});
