import React, { useState } from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import Datetime from "react-datetime";
import Image from "components/Icons/Image";

//Variables
const studies = [
  {
    value: "Study type 1",
    label: "Study type 1",
  },
  {
    value: "Study type 2",
    label: "Study type 2",
  },
  {
    value: "Study type 3",
    label: "Study type 3",
  },
  {
    value: "Study type 4",
    label: "Study type 4",
  },
];

const StudyForm = () => {
  const [study, setStudy] = useState(null);
  return (
    <React.Fragment>
      <Row>
        <Col xs={12} md={4}>
          <Label>Select your study</Label>
          <Select
            className="react-select primary"
            classNamePrefix="react-select"
            placeholder="Select a study"
            name="study"
            value={study}
            options={studies}
            onChange={(value) => setStudy(value)}
          />
        </Col>
        <Col xs={12} md={4}>
          <Label>Available device</Label>
          <FormGroup>
            <Input
              type="text"
              readOnly={true}
              placeholder="You must first select a study"
            />
          </FormGroup>
        </Col>
        <Col className="text-center" xs={12} md={4}>
          <Label>Booking date</Label>
          <FormGroup>
            <Datetime
              timeFormat={false}
              inputProps={{
                placeholder: "Datetime Picker Here",
                readOnly: true,
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={3} lg={2}>
          <Image
            file="/logo512.png"
            className="img img-fluid"
            width="100%"
            title="Device image"
          />
        </Col>
        <Col>
          <h4>Device detail</h4>
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eveniet,
            consequatur! Non, suscipit? Minus deserunt fugit alias recusandae
            necessitatibus unde totam iste! Nihil amet magni quam ab voluptas
            sequi distinctio veniam!
          </p>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default StudyForm;
