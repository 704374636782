import React from "react";
import BtnAttachFile from "./BtnAttachFile";
import BtnEditNote from "./BtnEditNote";
import BtnNewNote from "./BtnNewNote";
import BtnDelete from "./BtnDelete";

const Actions = () => {
  return (
    <div className="text-right">
      <BtnNewNote />
      <BtnEditNote />
      <BtnAttachFile />
      <BtnDelete />
    </div>
  );
};

export default Actions;
