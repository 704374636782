import CONSTANTS from "../CONSTANTS";

const responsesReducer = (
  state = {
    time: null,
    statusCode: null,
    status: "info",
    message: null
  },
  action
) => {
  switch (action.type) {
    case CONSTANTS.RESPONSE:
      const { statusCode, status, message } = action.response;
      return {
        ...state,
        time: Date.now(),
        status,
        message,
        statusCode: statusCode
      };
    case CONSTANTS.RESPONSE_CLEAN:
      return { status: "info", message: null };
    default:
      return state;
  }
};

export default responsesReducer;
