import CONSTANTS from "../CONSTANTS";

const commonReducer = (
  state = {
    sweetAlert: null,
    mobile: false,
    nativeWebView: false,
  },
  action
) => {
  switch (action.type) {
    case CONSTANTS.SWEET_ALERT:
      return { ...state, sweetAlert: action.sweetAlert };
    case CONSTANTS.MOBILE_VERSION:
      return { ...state, mobile: action.mobile };
    case CONSTANTS.NATIVE_WEBVIEW:
      return { ...state, nativeWebView: action.native };
    default:
      return state;
  }
};

export default commonReducer;
