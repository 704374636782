import { takeEvery, put, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
//Redux Actions
import CONSTANTS from '../CONSTANTS';
import {
  actionSetUsersList,
} from '../actions/userDataActions';
//Services
import PatientsService from '../../services/PatientsService.js';
import utilTranslations from 'helpers/utilTranslations';
//Selectors
import { select } from "redux-saga/effects";
import { getUser } from "../selectors/selectors";

/**
 * Get a user list for send messages
 */
function* sagaGetUsers({ query }) {
  //Translations
  const user = yield select(getUser);
  const t = utilTranslations(user.language || 'en', 'userdata-saga');
  try {
    const result = yield call(PatientsService.getUsers, query);
    if (result.status >= 400) {
      throw new Error('Get users failed');
    }
    yield put(actionSetUsersList(result.data));
  } catch (error) {
    toast.error(error.message || t("get-invitations-failed"));
  }
}

export function* userDataSaga() {
  console.log('*Main Users Data Saga');
  yield takeEvery(CONSTANTS.USERS_GET_LIST, sagaGetUsers);
}
