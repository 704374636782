import React from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import DoctorInfo from "components/Doctors/DoctorInfo";
import Actions from "components/Notes/Actions/Actions";
import { useTranslate } from 'react-translate';

const NotesHeader = () => {
  const t = useTranslate('notes-header');

  return (
    <Row>
      <Col sm="6" md="3" lg="2">
        <h3 className="notes-title">
          <span className="icon-note"></span> {t('notes')}
        </h3>
        <Link
          to="/admin/appointments"
          className="btn btn-outline-info btn-sm text-truncate"
        >
          <i className="fas fa-arrow-left"></i> {t('appointments')}
        </Link>
      </Col>
      <Col sm="6" md="5" lg="5">
        <DoctorInfo dateChecked="16/12/2019 09:45 HS." alignment="inline" />
      </Col>
      <Col sm="12" md="4" lg="5">
        <Actions />
      </Col>
    </Row>
  );
};

export default NotesHeader;
