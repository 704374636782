/*!

=========================================================
* Now UI Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  CardColumns,
  CardTitle,
  CardSubtitle,
  CardText,
  DropdownItem,
  DropdownToggle,
  Badge,
  UncontrolledDropdown,
  DropdownMenu,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import AppointmentsCalendar from "components/Appointments/AppointmentsCalendar";

const RegularMap = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={8}
      defaultCenter={{ lat: 40.748817, lng: -73.985428 }}
      defaultOptions={{
        scrollwheel: false,
      }}
    >
      <Marker position={{ lat: 40.748817, lng: -73.985428 }} />
    </GoogleMap>
  ))
);

const PhysicianPage = ({history}) => {
  return (
    <>
      <PanelHeader size="sm" />
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-user-public">
              <div className="image">
                <img
                  alt="..."
                  src={require("assets/img/bg5.jpg")}
                  className="img-fluid w-100"
                />
              </div>
              <CardBody>
                <div className="author">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img
                      alt="..."
                      className="avatar border-gray"
                      src={require("assets/img/eva.jpg")}
                    />
                    <h5 className="title">Mike Andrew</h5>
                  </a>
                  <p className="description">michael24</p>
                </div>
                <p className="description text-center">
                  {'"'}Lamborghini Mercy <br />
                  Your chick she so thirsty <br />
                  I'm in that two seat Lambo{'"'}
                </p>
              </CardBody>
              <hr />
              <div className="button-container">
                <Button
                  className="btn-icon btn-round"
                  color="neutral"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  size="lg"
                >
                  <i className="fab fa-facebook-square" />
                </Button>
                <Button
                  className="btn-icon btn-round"
                  color="neutral"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  size="lg"
                >
                  <i className="fab fa-twitter" />
                </Button>
                <Button
                  className="btn-icon btn-round"
                  color="neutral"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  size="lg"
                >
                  <i className="fab fa-google-plus-square" />
                </Button>
                <Button
                  className="btn-icon btn-round"
                  color="neutral"
                  href="#pablo"
                  onClick={
                    (e) => {
                      e.preventDefault();
                      history.push('/public/devices')
                    }
                  }
                  size="lg"
                >
                  <i className="icon-appointments" />
                </Button>
              </div>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="user-detail-container">
              <CardColumns className="card-columns-user-detail">
                <Card>
                  <CardBody>
                    <CardTitle tag="h5">MY SCHEDULE</CardTitle>
                    <AppointmentsCalendar />
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <CardTitle tag="h5">A LITLE ABOUT ME</CardTitle>
                    {/* <CardSubtitle tag="h6" className="mb-2 text-muted">
                      A LITLE ABOUT ME
                    </CardSubtitle> */}
                    <CardText>
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                      Quos dolores eum voluptatem eligendi illo nobis magni
                      temporibus repellendus, minima iusto harum modi? Omnis eos
                      dolore temporibus nisi cumque odio totam?
                    </CardText>
                    <Button>Show more</Button>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <CardTitle tag="h5">I'M SPECIALIZE IN</CardTitle>
                    {/* <CardSubtitle tag="h6" className="mb-2 text-muted">
                      A LITLE ABOUT ME
                    </CardSubtitle> */}
                    <CardText>
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                      Quos dolores eum voluptatem eligendi illo nobis magni
                      temporibus repellendus, minima iusto harum modi? Omnis eos
                      dolore temporibus nisi cumque odio totam?
                    </CardText>
                    <Button>Show more</Button>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <CardTitle tag="h5">I'M LOCATED AT</CardTitle>
                    <RegularMap
                      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyD-ZBPDjrn18_EkbqBFNfOJjsHkNzNujUE"
                      loadingElement={<div style={{ height: `100%` }} />}
                      containerElement={<div style={{ height: `280px` }} />}
                      mapElement={<div style={{ height: `100%` }} />}
                    />
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <CardTitle tag="h5">WHAT MY PATIENTS SAY</CardTitle>
                    <Card className="card-timeline card-plain">
                      <CardBody>
                        <ul className="timeline timeline-simple">
                          <li className="timeline-inverted">
                            <div className="timeline-badge danger">
                              <i className="now-ui-icons objects_planet" />
                            </div>
                            <div className="timeline-panel">
                              <div className="timeline-heading">
                                <Badge color="danger">Some Title</Badge>
                              </div>
                              <div className="timeline-body">
                                <p>
                                  Lorem ipsum, dolor sit amet consectetur
                                  adipisicing elit. Itaque necessitatibus
                                  eveniet amet nostrum incidunt porro similique
                                  quia minima vel quod officia sunt soluta iure
                                  aliquid quibusdam, accusamus dolores nesciunt
                                  quos molestias vitae ratione. In delectus
                                  soluta, quia nobis quidem eveniet quo ea
                                  eligendi aliquid modi fuga quibusdam
                                  recusandae atque, cumque doloremque odit
                                  itaque est quam officiis porro, iusto tempora.
                                </p>
                              </div>
                              <h6>
                                <i className="ti-time" />
                                11 hours ago via Twitter
                              </h6>
                            </div>
                          </li>
                          <li className="timeline-inverted">
                            <div className="timeline-badge success">
                              <i className="now-ui-icons shopping_tag-content" />
                            </div>
                            <div className="timeline-panel">
                              <div className="timeline-heading">
                                <Badge color="success">Another One</Badge>
                              </div>
                              <div className="timeline-body">
                                <p>
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipisicing elit. Beatae ex nam perspiciatis
                                  velit, placeat illo dolores facilis molestiae
                                  eius et quos sequi molestias sapiente nesciunt
                                  exercitationem. Veritatis, assumenda
                                  reiciendis. Corrupti!
                                </p>
                              </div>
                            </div>
                          </li>
                          <li className="timeline-inverted">
                            <div className="timeline-badge info">
                              <i className="now-ui-icons shopping_delivery-fast" />
                            </div>
                            <div className="timeline-panel">
                              <div className="timeline-heading">
                                <Badge color="info">Another Title</Badge>
                              </div>
                              <div className="timeline-body">
                                <p>
                                  Lorem ipsum dolor sit amet consectetur
                                  adipisicing elit. Ratione, fugiat!
                                </p>
                                <p>
                                  Lorem ipsum dolor sit amet consectetur
                                  adipisicing elit. Necessitatibus earum id
                                  animi! Eveniet earum rerum reprehenderit saepe
                                  eius eligendi nesciunt est. Accusantium natus
                                  aut neque non maxime optio totam debitis!
                                </p>
                                <hr />
                              </div>
                              <div className="timeline-footer">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    caret
                                    className="btn-round"
                                    color="info"
                                    data-toggle="dropdown"
                                    type="button"
                                  >
                                    <i className="now-ui-icons design_bullet-list-67" />
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem
                                      href="#pablo"
                                      onClick={(e) => e.preventDefault()}
                                    >
                                      Action
                                    </DropdownItem>
                                    <DropdownItem
                                      href="#pablo"
                                      onClick={(e) => e.preventDefault()}
                                    >
                                      Another action
                                    </DropdownItem>
                                    <DropdownItem
                                      href="#pablo"
                                      onClick={(e) => e.preventDefault()}
                                    >
                                      Something else here
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </CardBody>
                    </Card>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <CardTitle tag="h5">I'M AFFILIATED WITH</CardTitle>
                    <CardSubtitle tag="h6" className="mb-2 text-muted">
                      PRACTICE LIST
                    </CardSubtitle>
                    <ListGroup>
                      <ListGroupItem>Cras justo odio</ListGroupItem>
                      <ListGroupItem>Dapibus ac facilisis in</ListGroupItem>
                      <ListGroupItem>Morbi leo risus</ListGroupItem>
                      <ListGroupItem>Porta ac consectetur ac</ListGroupItem>
                      <ListGroupItem>Vestibulum at eros</ListGroupItem>
                    </ListGroup>
                  </CardBody>
                </Card>
              </CardColumns>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default PhysicianPage;
