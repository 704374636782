import React, { useState, useEffect } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useDispatch } from 'react-redux';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
} from 'reactstrap';
import { actionAddressDelete } from 'store/actions/profilesActions';
import { useTranslate } from 'react-translate';

const AddressCard = ({ address, setAddressState }) => {
  const dispatch = useDispatch();
  const [deleteConfirm, setDeleteConfirm] = useState(null);
  const t = useTranslate("profile-address")

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const alertConfirm = (
    <SweetAlert
      question
      required
      title={t("are-you-sure")}
      onConfirm={() => {
        dispatch(actionAddressDelete(address._id));
        setDeleteConfirm(null);
      }}
      onCancel={() => {
        console.log('cancel');
        setDeleteConfirm(null);
      }}
      confirmBtnBsStyle="info"
      cancelBtnBsStyle="danger"
      confirmBtnText={t("btn-yes")}
      cancelBtnText={t("btn-no")}
      showCancel></SweetAlert>
  );

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <CardTitle tag="h4" className="text-danger profile-header">
            {address.type === 'shipping' ? (
              <>
                <span className="icon-shiping mr-2"></span>
                {t("shipping-address")}
              </>
            ) : (
              ''
            )}
            {address.type === 'billing' ? (
              <>
                <span className="icon-billing mr-2"></span>
                {t("billing-address")}
              </>
            ) : (
              ''
            )}
          </CardTitle>
          <CardBody className="d-block">
            {address.favourite && (
              <div>
                {address.type == 'billing' ? t("favourite-for-billing") : t("favourite-for-shipping")}{' '}
                {/* Favourite for {address.type}{' '} */}
                <span
                  className={`${address.favourite ? 'icon-star-active' : 'icon-star'
                    } text-warning mr-2`}></span>
                <br />
              </div>
            )}
            {/*address.scope === 'company' && (
              <div>{`${address.companyName}`}</div>
            )*/}
            <div>
              {`${address.firstName} ${address.lastName}`}
            </div>

            <div>
              {address.scope !== 'packstation' &&
                `${address.street} ${address.streetNumber}`}
              {address.scope === 'packstation' &&
                `${address.packstation} ${address.packstationNumber}`}
            </div>

            <div>
              {address.postalCode} - {address.city}
            </div>

            <div>
              {address.country && typeof address.country.label !== 'undefined'
                ? address.country.label
                : ''}
            </div>

            <div>{address.aditional}</div>
            <Row>
              <Col className="d-flex align-items-center">
                <div className="address-extras">
                  {address.scope === 'company' ? (
                    <Badge color="success">{t('company')}</Badge>
                  ) : (
                    ''
                  )}
                  {address.scope === 'public' ? (
                    <Badge color="danger">{t('public')}</Badge>
                  ) : (
                    ''
                  )}
                  {address.scope === 'packstation' ? (
                    <Badge color="warning">{t('dhl-packstation')}</Badge>
                  ) : (
                    ''
                  )}
                </div>
              </Col>
              <Col className="text-right">
                <Button
                  size="sm"
                  color="info"
                  name="btn-save"
                  onClick={() => {
                    setAddressState({
                      data: address,
                      showForm: true,
                    });
                  }}>
                  <i className="fas fa-edit"></i>
                </Button>
                <Button
                  size="sm"
                  color="danger"
                   name="btn-delete"
                  onClick={() => {
                    setDeleteConfirm(alertConfirm);
                  }}>
                  <i className="fas fa-ban"></i>
                </Button>
              </Col>
            </Row>
          </CardBody>
        </CardHeader>
      </Card>
      {deleteConfirm}
    </React.Fragment>
  );
};

export default AddressCard;
