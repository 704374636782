// /*!

// =========================================================
// * Now UI Dashboard PRO React - v1.3.0
// =========================================================

// * Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)

// * Coded by Creative Tim

// =========================================================

// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from 'reactstrap';

import classnames from 'classnames';
import ProfileForm from 'components/Users/ProfileForm';
import Address from 'components/Users/Address';
import { actionGetProfile } from 'store/actions/profilesActions';
import Icon from 'components/Icons/Icon';
import { useTranslate } from 'react-translate';

const Profile = (props) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState('1');

  const isMount = useRef(true);
  const { profile } = useSelector((state) => state.profilesReducer);

  const user = useSelector((state) => state.userReducer);

  const user_id = user._id ? user._id : null;

  const t = useTranslate('profile');

  useEffect(() => {
    if (isMount.current && user_id) {
      dispatch(actionGetProfile());
    }
    return () => {
      isMount.current = false;
    };
  }, [dispatch, user_id]);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className="content dashboard">
      <Card>
        <CardHeader>
          <CardTitle className="d-flex align-content-center">
              <div className="general-card-title-img text-primary mr-2">
                  <i className="icon-user-active" />
              </div>
              <div className="general-card-title text-primary">
                  {profile.is_ready ? t("title") : t("step-2-update-personal-information")}
              </div>
          </CardTitle>
        </CardHeader>

        <CardBody>
        
              {profile.is_ready && (
                <Nav pills className="nav-pills-primary mb-4">
                  <NavItem className="profile-item">
                    <NavLink
                      className={classnames({ active: activeTab === '1' })}
                      name="tab-nav-personal-data"
                      onClick={() => {
                        toggle('1');
                      }}>
                      {t("personal-info")}
                    </NavLink>
                  </NavItem>
                  <NavItem className="profile-item">
                    <NavLink
                      className={classnames({ active: activeTab === '2' })}
                      name="tab-nav-address"
                      onClick={() => {
                        toggle('2');
                      }}>
                      {t("address")}
                    </NavLink>
                  </NavItem>
                </Nav>
              )}


              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  {activeTab === '1' && (
                    <ProfileForm
                      profile={profile}
                      user={user}
                      history={props.history}
                      toggle={toggle}
                    />
                  )}
                </TabPane>
                <TabPane tabId="2">
                  {activeTab === '2' && (
                    <Address address={profile.address ? profile.address : []} />
                  )}
                </TabPane>
              </TabContent>

        </CardBody>
      </Card>
    </div>
  );
};

export default Profile;
