import React from "react";
// reactstrap components
import { ListGroup, ListGroupItem } from "reactstrap";
import moment from "moment";
//Dev dependencie
import faker from "faker";

let notes = [];
for (let i = 1; i <= 10; i++) {
  let note = {
    date: moment().format("YYYY-MM-DD H:m"),
    text: faker.lorem.paragraph(),
  };
  notes.push(note);
}

const NotesList = () => {
  const checkItem = (e) => {
    let checkbox = e.target.getAttribute("id");
    let id = checkbox.split("-")[2];
    let note = document.getElementById(`note-${id}`);
    if (e.target.checked) {
      note.classList.add("active");
    } else {
      note.classList.remove("active");
    }
  };

  return (
    <ListGroup className="notes-list">
      {notes.map((note, key) => {
        return (
          <ListGroupItem
            className={`p-1 note-item`}
            key={key}
            id={`note-${key}`}
          >
            <div className="note-actions">
              <label className="container-checkbox">
                <input
                  type="checkbox"
                  id={`note-checkbox-${key}`}
                  onChange={checkItem}
                />
                <span className="checkmark"></span>
              </label>
              <span className="note-date">{note.date}</span>
            </div>
            <div className="text-justify note-text">{note.text}</div>
          </ListGroupItem>
        );
      })}
    </ListGroup>
  );
};

export default NotesList;
