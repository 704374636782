import React from 'react';
import { FormGroup, Button } from 'reactstrap';
import { useTranslate } from 'react-translate';
import { useHistory } from 'react-router-dom';

export default function SessionManagerSection() {
  const t = useTranslate('settings');
  const history = useHistory();

  return (
    <div className="setting-section">
      <h5 className="general-card-subtitle">{t('session-title')}</h5>
      <FormGroup>
        <div className="notification-section">
          <Button
            color="info"
            size="sm"
            onClick={() => {
              history.push('/admin/sessions');
            }}
          >
            {t('view')}
          </Button>
        </div>
      </FormGroup>
    </div>
  );
}
