import React from 'react';
import { useTranslate } from 'react-translate';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

export default function BtnAttachModal({ modal, toggleModal, confirmDelete }) {
  const t = useTranslate("chat");
  return (
    <Modal isOpen={modal} toggle={toggleModal}>
      <ModalBody>{t("are-you-sure")}?</ModalBody>
      <ModalFooter>
        <Button color="info" name="btn-confirm-delete-attach" onClick={confirmDelete}>
          {t("btn-yes")}
        </Button>{' '}
        <Button color="secondary" name="btn-confirm--cancel-delete-attach" onClick={toggleModal}>
          {t("btn-no")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
