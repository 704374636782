import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useSortBy, useTable } from "react-table";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Row,
    Col,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Table,
    Modal,
    ModalBody,
    ModalFooter
} from "reactstrap";
import { useTranslate } from "react-translate";
import LaboratoryService from "../../services/LaboratoryService";
import LoadingLocal from 'plugins/TS-lib-utils-public/components/Loading/LoadingLocal';
import nativeSender from 'helpers/nativeSender';
import classnames from 'classnames';
import { actionReadNotification } from "store/actions/notificationsActions";

const LaboratoryDetail = () => {
    const dispatch = useDispatch();
    const { reportId } = useParams("reportId");
    const [study, setStudy] = useState('loading');
    const user = useSelector((state) => state.userReducer);
    const [emergency, setEmergency] = useState(false);
    const history = useHistory();
    const t = useTranslate('laboratory-detail');
    const thl7 = useTranslate('hl7-status');
    const { nativeWebView } = useSelector(state => state.commonReducer);

    const notifications = useSelector(state => state.notificationsReducer["laboratory"]);

    const [modal, setModal] = useState(false);
    const toggleModal = () => {
        setModal(!modal);
    }

    const [activeTab, setActiveTab] = useState(window.location.href.includes("review") ? '2' : '1');

    useEffect(() => {
        notifications.notifications.map(n => {
            if (activeTab === "2") {
                if (n.notification.text.toLowerCase().match(/review|kommentar/)) {
                    dispatch(actionReadNotification(n.notification._id, true));
                }
            } else {
                if (n.notification.text.toLowerCase().match(/studie|laborbefund/)) {
                    dispatch(actionReadNotification(n.notification._id, true));
                }
            }
        });
        
    }, [notifications, activeTab]);

    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const fetchData = async (_reportId, patientId) => {
        try {
            const { data, status } = await LaboratoryService.getStudyDetail(_reportId, patientId);
            
            if (status >= 400 || !data) {
                history.replace("/admin/lab-studies");
            }

            if (data) {
                const { doctor_id, patient_id, hl7, doctor, reviews } = data;
                let medicalValues = await JSON.parse(hl7[0].reportData);
                let metadata = hl7[0];

                const reportFile = hl7[0].reportFile ? hl7[0].reportFile : null;

                setStudy({
                    doctor_id,
                    patient_id,
                    medicalValues,
                    doctor,
                    metadata,
                    reportFile,
                    reviews: [...reviews]
                });
            }
            
        } catch (error) {
            console.log(error);
        };
    }
    useEffect(() => {
        //console.log(reportId);
        fetchData(reportId, user.patient_pifs_id);
    }, [reportId]);

    // useEffect(() => {
    //     console.log(study);
    // }, [study])

    let data = useMemo(
        //|| ('Set ID - NTE' in el)
        () => study != 'loading' && study.medicalValues ? study.medicalValues.map(p => {
            const tableData = {
                c1: p?.['Observation Identifier'].replace(/^([\S]{1,})\s/, ""),
                c2: p?.['Units'],
                c3: p?.['Observation Value'],
                c4: p?.['References Range'],
                c5: p?.['Abnormal Flags'],
                c6: p?.['Comment'].map((commentLine, index) => {
                    return <><p key={index}>{commentLine}</p></>
                }),
            };

            if (p?.['Abnormal Flags']) {
                setEmergency(true);
            }

            return { ...tableData };

            //}

        }) : [],
        //eslint-disable-next-line
        [study]
    );

    const columns = useMemo(
        () => [
            {
                Header: `${t('observation-identifier')}`,
                accessor: "c1",
                defaultCanSort: true
            },
            {
                Header: `${t('units')}`,
                accessor: "c2",
                disableSortBy: true,
            },
            {
                Header: `${t('observation-value')}`,
                accessor: "c3",
                disableSortBy: true,
            },
            {
                Header: `${t('references-range')}`,
                accessor: "c4",
                disableSortBy: true,
            },
            {
                Header: `${t('abnormal-flags')}`,
                accessor: "c5",
                disableSortBy: true,
            },
            {
                Header: `${t('comment')}`,
                accessor: "c6",
                disableSortBy: true,
            },
        ],
        //eslint-disable-next-line
        [study]
    );


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data, initialState: { hiddenColumns: ["c5"] } }, useSortBy);

    function base64ToArrayBuffer(base64) {
        var binaryString = window.atob(base64);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }
    // Function will execute on click of button
    const convertPDFToUrl = () => {
        // using Java Script method to get PDF file
        var bytes = base64ToArrayBuffer(study.reportFile);
        var blob = new Blob([bytes], { type: "application/pdf" });
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        return fileURL

    }


    return (
        <>
            <Card>
                <CardBody>
                    <Card>
                        <CardHeader>
                            <CardTitle className="d-flex align-content-center">
                                <div className="general-card-title-img text-primary mr-2">
                                    <i className="icon-microscope" />
                                </div>
                                <div className="general-card-title text-primary">
                                    {t('laboratory-details')}
                                </div>
                            </CardTitle>
                        </CardHeader>
                        {study == 'loading' ? (<LoadingLocal />)
                            : (

                                <CardBody>
                                    <Button
                                        size="sm"
                                        color="info"
                                        onClick={
                                            (e) => {
                                                e.preventDefault();
                                                history.push(`/admin/lab-studies`);
                                            }
                                        }>
                                        {t('go-back')}
                                    </Button>

                                    {
                                        study.reportFile && (
                                            <Button
                                                size="sm"
                                                color="info"
                                                href={!nativeWebView ? convertPDFToUrl() : null}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                onClick={(e) => {
                                                    if (nativeWebView) {
                                                        e.preventDefault();
                                                        return nativeSender.send('DOWNLOAD_REPORT', { reportId: reportId, doctorId: study.doctor_id, patientId: study.patient_id });
                                                    }
                                                }}
                                            >
                                                {t('show-pdf')}
                                            </Button>
                                        )
                                    }
                                    {
                                        (
                                            <Button
                                                size="sm"
                                                color="success"
                                                onClick={
                                                    (e) => {
                                                        e.preventDefault();
                                                        toggleModal();
                                                    }
                                                }>
                                                {"Fragen?"}
                                            </Button>
                                        )
                                    }

                                    {
                                        study.metadata && study.doctor && (
                                            <table border="0" className="metadata-table">
                                                <thead>
                                                    <tr>
                                                        <th colSpan={2}>
                                                            <b>{t("doctor")}: </b> {study.doctor.title} {study.doctor.firstName} {study.doctor.lastName}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                
                                                <tbody>
                                                    <tr>
                                                        <td><b>{t("order")}: </b> {study.metadata.adminValues.order_number}</td>
                                                        <td><b>{t("control")}: </b> {study.metadata.adminValues.control_number}</td>
                                                        
                                                    </tr>
                                                    
                                                    <tr>
                                                        <td><b>{t("report-date")}: </b> {moment(study.metadata.updated_at, "YYYYMMDDhhmmss").format(user.dateFormat)}</td>
                                                        <td><b>{t("reception-date")}: </b> {moment(study.metadata.created_at, "YYYYMMDDhhmmss").format(user.dateFormat)}</td>
                                                    </tr>

                                                    <tr>
                                                        <td><b>{t("status")}: </b> {thl7(study.metadata.reportStatus)}</td>
                                                        <td><b>{t("study-type")}: </b>{study.metadata.adminValues.study_type ? t(study.metadata.adminValues.study_type.toLowerCase()) : t("unknown")}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        )
                                    }
                                    
                                    <Nav pills className="nav-pills-primary mb-4 mt-4">
                                        <NavItem className="profile-item">
                                            <NavLink
                                                className={classnames({ active: activeTab === '1' })}
                                                onClick={() => {
                                                    toggle('1');
                                                }}>
                                                {t('detail')}
                                            </NavLink>
                                        </NavItem>

                                        <NavItem className="profile-item">
                                            <NavLink
                                                className={classnames({ active: activeTab === '2' })}
                                                onClick={() => {
                                                    toggle('2');
                                                }}>
                                                {t('doctor-review')}
                                            </NavLink>
                                        </NavItem>
                                    </Nav>

                                    <TabPane tabId="1">
                                        {activeTab === '1' && (
                                            <>
                                                
                                                <div className="table-responsive">
                                                    <Table {...getTableProps()}>
                                                        <thead>
                                                            {headerGroups.map(headerGroup => (
                                                                <tr {...headerGroup.getHeaderGroupProps()}>
                                                                    {headerGroup.headers.map(column => (
                                                                        <th
                                                                            className={`session-table-header`}
                                                                            {...column.getHeaderProps(column.getSortByToggleProps())}
                                                                        >
                                                                            {column.render('Header')}
                                                                        </th>
                                                                    ))}
                                                                </tr>
                                                            ))}
                                                        </thead>
                                                        <tbody {...getTableBodyProps()}>
                                                            {rows.map(row => {
                                                                prepareRow(row)
                                                                return (
                                                                    <tr {...row.getRowProps((state, rowInfo) => {
                                                                        if (rowInfo && rowInfo.row) {
                                                                            return {
                                                                                style: {
                                                                                    backgroundColor: rowInfo.row.values.c5 !== '' ? '#ffdddd' : '',
                                                                                }
                                                                            }
                                                                        } else return {}
                                                                    })}>
                                                                        {row.cells.map(cell => {
                                                                            return (
                                                                                <td
                                                                                    {...cell.getCellProps()}
                                                                                >
                                                                                    {cell.render('Cell')}
                                                                                </td>
                                                                            )
                                                                        })}
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </>
                                        )}
                                    </TabPane>
                                    

                                    <TabPane tabId="2">
                                        {activeTab === '2' && (
                                            <>
                                                {study.reviews.length ? study.reviews.map((r, i) => {

                                                    return (
                                                        <div className="review-card" key={`review-card-${i}`}>
                                                            <Row key={`review-row-${i}`}>
                                                                <Col key={`review-col-${i}`} sm="9">
                                                                    <div className="review-left-header" key={`review-left-header-${i}`}>
                                                                        <p>{moment(r.updated_at ? r.updated_at : r.created_at).format(`${user.dateFormat} HH:mm`)}</p>
                                                                        {/*study.review.isBilling && (<span className="euro-symbol">€</span>)*/}
                                                                    </div>
                                                                    <div key={`review-content-${i}`} className="review-content" dangerouslySetInnerHTML={{__html: r && r.content ? r.content :  t('no-comments')}}>
                                                                    </div>
                                                                </Col>

                                                                <Col>
                                                                {r && r.folgenValues && r.folgenValues.length ? (
                                                                    <>
                                                                        <div className="review-right-header" key={`review-right-header-${i}`}>
                                                                            <div>Zum Zeitpunkt der ärztlichen Befundung liegen folgende Werte noch nicht vor:</div>
                                                                        </div>
                                                                        <div className="folgen-section" key={`review-folgen-section-${i}`}>
                                                                            {r.folgenValues.map((fv, j) => {
                                                                                let value = fv["Observation Identifier"].replace(/^([\s]*)\s/, '').split(' ');
                                                                                return (
                                                                                <div className="folgen-pill" key={`review-folgen-pill-${i}-${j}`}>
                                                                                    {`${value[1]} ${value[2]}`}
                                                                                </div>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                <div className="folgen-section" key={`review-folgen-section-no-values`}>
                                                                    Alle Werte wurden verarbeitet
                                                                </div>
                                                                )}
                                                                    
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    );
                                                }) : (
                                                <div className="review-card">
                                                    {t('no-comments')}
                                                </div>
                                                )}
                                            </>
                                            
                                        )}
                                    </TabPane>

                                </CardBody>
                            )}
                    </Card>
                </CardBody>
            </Card >

            <Modal isOpen={modal} toggle={toggleModal}>
                <ModalBody>
                    <p>{`Von der Norm nur leicht abweichende Laborwerte können Sie ruhig bei der nächsten Konsultation mit Ihrem Arzt besprechen.`}</p>
                    <p>{`Bei Verschlechterung und/oder anhaltenden Beschwerden ist eine Wiedervorstellung bei Ihrem Arzt anzuraten. Außerhalb der Öffnungszeiten der Praxis wenden Sie sich in dringlichen Fällen an ärztliche Notdienste.`}</p>
                    <p>{`Ich möchte einen Termin vereinbaren und den Befund mit meinem Arzt besprechen.`}</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={() => {
                        toggleModal();
                        history.push(`/admin/frametest/${study.doctor_id}`);
                    }}>
                        {"Befund besprechen"}
                    </Button>{' '}
                    <Button color="error" onClick={() => {
                        toggleModal();
                    }}>
                        {"Abbrechen"}
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default LaboratoryDetail;
