import moment from "moment";

const useAppointmentNext = (appointments, date = null) => {
  let next = null;
  const today = date ? moment(date) : moment();
  if (Array.isArray(appointments)) {
    let aux = 0;
    appointments.map((ap, index) => {
      let days = today.diff(ap.startDate, "days");
      if (Math.abs(days) <= aux) {
        next = `item-${index}`;
      }
      return null;
    });
  }
  return next;
};
export default useAppointmentNext;
