import AxiosService from 'plugins/TS-lib-utils-public/services/AxiosService';

class LaboratoryService extends AxiosService {
    constructor() {
        super("patient-user");
    }
    /**
  * Get all studies linked to the current doctor and practice
  */
    getStudiesList = async (page = 0, count = 5, sortDate = 0) => {
        const response = await this.get(`/laboratory/?page=${page}&limit=${count}&datesort=${sortDate}`, {
            headers: {
                Authorization: `Bearer ${this.getToken()}`,
            },
        });
        return response;
    };
    /**
    * Get get study detail by reportId
    */
    getStudyDetail = async (reportId, patientId) => {
        const response = await this.get(`/laboratory/${reportId}`, {
            headers: {
                Authorization: `Bearer ${this.getToken()}`,
                patientid: patientId
            },
        });
        return response;
    };
    /* Get get study reportFile by reportId
    */
    getStudyReportFile = async (reportId, patientId, doctorId) => {
        const response = await this.get(`/laboratory/getpdf/${reportId}`, {
            headers: {
                Authorization: `Bearer ${this.getToken()}`,
                patientid: patientId,
                doctorid: doctorId
            },
        });
        return response;
    };
    /**
    * Get historic values from studies
    */
    getStudyHistory = async () => {
        const response = await this.get(`/laboratory/history`, {
            headers: {
                Authorization: `Bearer ${this.getToken()}`,
            },
        });
        return response;
    };

}

export default new LaboratoryService();

