import CONSTANTS from "../CONSTANTS";

const initialState = {
  appointments: [],
  appointment: {},
};

const appointmentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.APPOINTMENTS_SET:
      return { ...state, appointments: action.appointments };
    case CONSTANTS.APPOINTMENTS_SET_AP:
      return { ...state, appointment: action.appointment };
    default:
      return state;
  }
};

export default appointmentsReducer;
