import React, { Fragment } from "react";
// reactstrap components
import { Row, Col } from "reactstrap";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
// import { BryntumScheduler } from "bryntum-react-shared";
const Schedule = () => {
  return (
    <Fragment>
      <PanelHeader size="sm" />
      <div className="content">
        <Row>
          <Col md="12">Agenda</Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default Schedule;
