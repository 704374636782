import CONSTANTS from '../CONSTANTS';

const initialState = {
  keys: [],
  pagination: {},
  loading: false,
};

const helpsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.HELPS_GET:
      return { ...state, loading: true };
    case CONSTANTS.HELPS_SET:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

export default helpsReducer;
