import React from "react";
import moment from "moment";
import Toolbar from "react-big-calendar/lib/Toolbar";
import { Button, Row, Col } from "reactstrap";
import { translate } from "react-translate";

class CustomToolbar extends Toolbar {
  constructor(props) {
    super(props);
    this.btnTodayRef = React.createRef();
    this.labelToday = moment().format("MMMM Y");
  }
  componentDidMount() {
    // console.log(this.view);
  }

  componentDidUpdate() {
    // console.log(this.props.view);
  }

  render() {
    return (
      <Row>
        <Col className="text-center">
          <span className="mr-5 text-right">{this.props.label}</span>
          <Button color="info" size="sm" onClick={() => this.navigate("PREV")}>
            {"<"}
          </Button>
          <Button
            color="info"
            size="sm"
            onClick={() => this.navigate("TODAY")}
            disabled={this.props.label === this.labelToday}
          >
            {this.props.t('today')}
          </Button>
          <Button color="info" size="sm" onClick={() => this.navigate("NEXT")}>
            {">"}
          </Button>
          {this.props.view === "month" ? (
            <Button color="primary" size="sm" onClick={() => this.view("week")}>
              {this.props.t('week')}
            </Button>
          ) : (
            <Button
              color="primary"
              size="sm"
              onClick={() => this.view("month")}
            >
              {this.props.t('month')}
            </Button>
          )}
        </Col>
      </Row>
    );
  }
}

export default translate('custom-toolbar')(CustomToolbar);