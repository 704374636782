import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { translate, TranslatorProvider } from 'react-translate';
import { CardTitle, Row } from 'reactstrap';
// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Form,
  Container,
  Col,
  Input,
  Button,
  FormGroup,
} from 'reactstrap';
import LegalContent from 'views/Components/LegalContent';
import PatientsService from 'services/PatientsService';
import { actionSendVerification } from 'store/actions/tokenActions';
import { actionLogout } from 'store/actions/usersActions';
import { actionSetLoading } from 'store/actions/loadingActions';

const VerificationPage = (props) => {
  const { t } = props;
  const { language } = useSelector((state) => state.userReducer);

  const { token } = useParams();

  const dispatch = useDispatch();

  const fetchToken = async (history, token) => {
    const res = await PatientsService.verifyToken(token);
    if (!res) {
      history.replace("/auth/login-page");
    } else {
      dispatch(actionLogout());
      dispatch(actionSendVerification({
        token: token
      }));
    }
  }

  useEffect(() => {
    if (token) {
      fetchToken(props.history, token);
    }
  }, [token]);


  return (
    <></>
  );
};

export default translate('login-page')(VerificationPage);
