import React from "react";
import moment from "moment";

const CustomEventWrapperWeek = (props) => {
  const data = props.event;

  const customDiv = (
    <div className="event-custom">
      <span>{moment(data.start).format("HH:mm")}</span>
    </div>
  );

  const wrapper = React.cloneElement(props.children, {}, customDiv);

  return <div className="event-wrapper-week">{wrapper}</div>;
};

export default CustomEventWrapperWeek;
