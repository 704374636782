import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop(props) {
    const { pathname } = useLocation();
    const { mainPanel } = props;
    useEffect(() => {

        mainPanel.current.scroll({
            top: 0,
            behavior: "smooth"
        });
        console.log("scrolled")
    }, [pathname]);

    return null;
}
