import AxiosService from "plugins/TS-lib-utils-public/services/AxiosService";

class PatientsService extends AxiosService {
  constructor() {
    super("patient-user");
  }
  searchDoctor = async (query) => {
    const response = await this.get(
      `/doctors/search/${query}`,
      {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      }
    );
    return response;
  };
  getDoctors = async () => {
    const response = await this.get(
      `/doctors`,
      {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      }
    );
    return response;
  };
  getPifsProfile = async (patientId) => {
    const response = await this.get(
      `/profile/${patientId}`
    );
    return response;
  };
  giveConsent = async (patientId, consent) => {
    const response = await this.get(
      `/profile/consent/${patientId}`,
      {
        headers: {
          consent: `${consent}`
        }
      }
    );
    return response;
  };
  verifyToken = async (token) => {
    try {
      //Verify if request doesn't return 404
      const response = await this.get(
        `/profile/token/${token}`,
        {
          headers: {
            token: this.getToken()
          }
        }
      );

      return response.status === 200;
    } catch (e) {
      return false;
    }
  };

  sendVerification = async (data) => {
    try {
      const response = await this.post(
        `/profile/token/resolve`,
        data
      );
      return response;
    } catch (e) {
      return null;
    }
  };
}

export default new PatientsService();
