import React, { useEffect, useRef } from 'react';
import { useFileUtils } from 'hooks';

const NotificationsAvatar = ({ user }) => {
  const isMounted = useRef();

  const inputAvatar = document.getElementById(`notif-avatar-${user}`);
  const { getAvatar, defaultImage, setFile } = useFileUtils(inputAvatar);
  const auth_user_id = user ? user.auth_user_id : null;
  const avatar = user ? user.avatar : null;
  useEffect(() => {
    isMounted.current = true;
    if (user && user.auth_user_id && user.avatar) {
      const avatarResponse = getAvatar(user.auth_user_id, 'small');
      avatarResponse.then((avatar) => {
        if (isMounted.current && avatar) {
          setFile({ file: avatar });
        }
      });
    }
    return () => (isMounted.current = false);
    //eslint-disable-next-line
  }, [setFile, auth_user_id, avatar]);

  return (
    <div className="photo text-center">
      {avatar ? (
        <img
          src={defaultImage}
          alt="Avatar"
          id={`notif-avatar-${auth_user_id}`}
        />
      ) : (
        <div className="inbox-avatar-name ">
          <span>{user ? user.nameInitials:''}</span>
        </div>
      )}
    </div>
  );
};

export default React.memo(NotificationsAvatar, (prevProps, nextProps) => {
  return prevProps?.user?.avatar === nextProps?.user?.avatar;
});
