import React from "react";
import moment from "moment";

const CustomEventWrapperMonth = (props) => {
  const data = props.event;
  const customDiv = (
    <div className="event-custom-month text-center h-100">
      <span>{data.title}</span> <br />
      <span>{moment(data.start).format("HH:mm")}</span>
    </div>
  );

  const wrapper = React.cloneElement(props.children, {}, customDiv);

  return <div className="event-wrapper-month">{wrapper}</div>;
};

export default CustomEventWrapperMonth;
