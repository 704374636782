import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Col,
  Button,
  CardFooter
} from "reactstrap";

// core components
import bgImage from "assets/img/bg13.jpg";
import { actionConfirmDeletion } from "store/actions/usersActions";
import historyRouter from "historyRouter";

const UserDeleteConfirmation = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.userReducer);

  const [choice, setChoice] = useState(null);

  //Proccess choice
  useEffect(() => {
    if (choice !== null) {
      dispatch(actionConfirmDeletion(user.auth_user_id, choice));
    }
  }, [choice]);

  if (!user.on_delete) {
    historyRouter.push("/admin/profile");
  }

  return (
    <>
      <div className="content">
        <div className="login-page">
          <Container>
            <Col lg={4} md={8} xs={12} className="mr-auto ml-auto">
              <Card className="card-lock text-center">
                <CardHeader tag="h4">
                Schritt 3
                </CardHeader>
                <CardBody>
                	Möchten Sie Ihr Konto und Ihre Daten unwiderruflich löschen?
                </CardBody>
                <CardFooter>
                  <Button 
                    color="info" 
                    size="lg" 
                    className="mb-3"
                    onClick={() => {
                      setChoice(false);
                    }}
                  >
                    Nein
                  </Button>
                  <Button 
                    color="primary" 
                    size="lg" 
                    className="mb-3 ml-2"
                    onClick={() => {
                      setChoice(true);
                    }}
                  >
                    Ja
                  </Button>
                </CardFooter> 
              </Card>
            </Col>
          </Container>
        </div>
      </div>
      <div
        className="full-page-background"
        style={{
          backgroundImage: "url(" + bgImage + ")",
        }}
      />
    </>
  );
};

export default UserDeleteConfirmation;
