import CONSTANTS from "../CONSTANTS";

const initialState = {
  messages: {
    limit: 10,
    page: 1,
    total: 0,
    total_page: 0,
    unreaded: 0,
    notifications: [],
  },
  chats: {
    limit: 10,
    page: 1,
    total: 0,
    total_page: 0,
    unreaded: 0,
    notifications: [],
  },
  laboratory: {
    limit: 10,
    page: 1,
    total: 0,
    total_page: 0,
    unreaded: 0,
    notifications: [],
  },
};

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.NOTIFICATIONS_MESSAGE_SET:
      return {
        ...state,
        messages: {
          limit: action.notifications.limit,
          page: action.notifications.page,
          total: action.notifications.total,
          total_page: action.notifications.total_page,
          unreaded: action.notifications.unreaded,
          notifications: action.notifications.notifications,
        },
      };
    case CONSTANTS.NOTIFICATIONS_CHAT_SET:
      return {
        ...state,
        chats: {
          limit: action.notifications.limit,
          page: action.notifications.page,
          total: action.notifications.total,
          total_page: action.notifications.total_page,
          unreaded: action.notifications.unreaded,
          notifications: action.notifications.notifications,
        },
      };
    case CONSTANTS.NOTIFICATIONS_LAB_SET:
      return {
        ...state,
        laboratory: {
          limit: action.notifications.limit,
          page: action.notifications.page,
          total: action.notifications.total,
          total_page: action.notifications.total_page,
          unreaded: action.notifications.unreaded,
          notifications: action.notifications.notifications,
        },
      };
    default:
      return state;
  }
};

export default notificationsReducer;
