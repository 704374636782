/*!

=========================================================
* Now UI Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Row, Col } from "reactstrap";
import moment from "moment";
import LoadingLocal from "plugins/TS-lib-utils-public/components/Loading/LoadingLocal";
import InboxFileList from "components/Messages/View/InboxFileList";
import { actionSetSelectedMessages } from "store/actions/messagesActions";
import { useTranslate } from "react-translate";

const InboxViewDetail = () => {
  const t = useTranslate("messages");
  const t2 = useTranslate('inbox-mobile-detail');
  const dispatch = useDispatch();

  const { message, loading_msg, box } = useSelector(
    (state) => state.messagesReducer
  );

  useEffect(() => {
    if (message && !window.location.href.includes("reply")) {
      dispatch(actionSetSelectedMessages([message._id]));
    }

    return () => {
      // hide:true;
    };
  }, [loading_msg, message, dispatch]);

  if (loading_msg) {
    return <LoadingLocal />;
  }

  if (!loading_msg && !message) {
    return (
      <Row>
        <Col sm={12} className="p-5">
          <Alert color="info">{t2('select-a-message')}</Alert>
        </Col>
      </Row>
    );
  }

  const formatText = (text) => {
    return text.split("\n").map((item, i) => {
      return (
        <React.Fragment key={i}>
          {item}
          <br />
        </React.Fragment>
      );
    });
  };

  return (
    <Row className="inbox-view-detail">
      <Col sm={12}>
        <div className="inbox-view-detail-header pt-3 pb-3">
          <div className="pt-1 pb-1 pl-2 pr-2">
            <div className="inbox-message-subject">
              <strong>{t("subject")}: </strong>
              {message.subject}
            </div>
            <div className="inbox-message-from">
              <div>
                <strong>{t("from")}: </strong>
                {message.from.firstName} {message.from.lastName}
              </div>
              {box === "outbox" && message.to && (
                <React.Fragment>
                  <div>
                    <strong>{t("to")}: </strong>
                    {message.to.map(
                      (user_to, i) =>
                        `${user_to.firstName} ${user_to.lastName} ${
                          i + 1 !== message.to.length ? ", " : ""
                        }`
                    )}
                  </div>
                </React.Fragment>
              )}
              <div className="message-date">
                <strong>{t("date")}: </strong>
                {moment(message.date).format("L HH:mm")}
              </div>
            </div>
            {message.priority ? (
              <div>
                <strong>{t("important")}</strong>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </Col>
      <Col sm={12}>
        <div className="inbox-message-text p-3 overflow-auto">
          <p className="text-justify">{formatText(message.body)}</p>
        </div>
      </Col>
      <Col sm={12}>
        <InboxFileList files={message.files ? message.files : []} />
      </Col>

      {message.thread.map(th => {
        return (
          <>
            <Col sm={12}>
              <div className="inbox-message-text p-3 overflow-auto">
                <p className="text-justify">{formatText(th?.body)}</p>
              </div>
            </Col>
            <Col sm={12}>
              <InboxFileList files={th.files ? th.files : []} />
            </Col>
          </>
        )
      })}
    </Row>
  );
};

export default InboxViewDetail;
