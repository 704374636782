import React, { useState } from "react";
import { toast } from "react-toastify";
import { useTranslate } from "react-translate";
import { Button, Tooltip } from "reactstrap";
import fileRules from "plugins/TS-lib-utils-public/configs/fileRules";
import BtnAttachModal from "./BtnAttachModal";

const BtnAttach = ({ register, id, attachmentList, setAttachmentList }) => {
  const t = useTranslate("messages");
  const [file, setFile] = useState(null);
  const [removed, setRemoved] = useState(false);

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const handleChange = (e) => {
    let file = e.target.files[0];
    if (!validateFileSize(file, fileRules.maxSize)) {
      return toast.error(
        `${t("alert-error-size-file")} ${fileRules.maxSize} Mb `
      );
    }
    if (file && file.name) {
      setFile(file.name);
      const thereEmpty = attachmentList.find(
        (att) =>
          document.getElementById(att) && !document.getElementById(att).value
      );
      if (!thereEmpty) {
        setAttachmentList([...attachmentList, `attachment-${Date.now()}`]);
      }
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    const element = e.target.id;
    if (!element) {
      if (document.getElementById(id).value) {
        toggleModal();
        return;
        /* setRemoved(true);
        return document.getElementById(id).value; */
      }
      return document.getElementById(id).click();
    }
    // setRemoved(true);
    toggleModal();
    if (document.getElementById(id)) {
      /*  document.getElementById(id).value = ''; */
    }
  };

  /**
   *  Validate file size
   * @param {object} file selected
   * @param {number} size Mb
   */
  const validateFileSize = (file, size) => {
    if (file && file.size) {
      return file.size <= parseInt(size) * 1024 * 1024;
    }
    return false;
  };

  const [modal, setModal] = useState(false);

  const toggleModal = () => setModal(!modal);

  const confirmDelete = () => {
    setRemoved(true);
    return document.getElementById(id).value;
  };

  return (
    <React.Fragment>
      {!removed && (
        <React.Fragment>
          <input
            type="file"
            name={`attachments[${attachmentList.length}]`}
            className={`d-none attach-group-${id}`}
            ref={register}
            id={id}
            onChange={handleChange}
            accept={fileRules.types.toString()}
          />
          <Button
            color={file ? "info" : "light"}
            onClick={handleClick}
            name="btn-attach-doc"
            className={`attach-group-${id}`}
          >
            {file ? file : t("btn-add-file")}
            {file && <i className="fas fa-times ml-2" id={`remove-${id}`}></i>}
          </Button>
          {file && (
            <Tooltip
              placement="top"
              target={`remove-${id}`}
              isOpen={tooltipOpen}
              toggle={toggle}
            >
              {t("btn-remove-file")}
            </Tooltip>
          )}
          <BtnAttachModal
            modal={modal}
            name="btn-confirm-attach-modal"
            toggleModal={toggleModal}
            confirmDelete={confirmDelete}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default BtnAttach;
