import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-translate';
import { InputGroup, Input, Button, InputGroupAddon } from 'reactstrap';
import {
  actionGetMessages,
  actionOutboxGetMessages,
  actionGetPriorityMessages,
} from 'store/actions/messagesActions';

const InboxSearcher = ({ box }) => {
  const t = useTranslate('messages');
  const dispatch = useDispatch();
  const handleSearch = () => {
    const searcher = document.getElementById('inbox-searcher').value;
    let search =
      searcher && searcher.split(' ').toString().length > 0 ? searcher : null;
    switch (box) {
      case 'inbox':
        dispatch(actionGetMessages(1, 20, search));
        break;
      case 'outbox':
        dispatch(actionOutboxGetMessages(1, 20, search));
        break;
      case 'priority':
        dispatch(actionGetPriorityMessages(1, 20, search));
        break;
      default:
        break;
    }
  };

  const handleKeyUp = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <InputGroup className="inbox-input-searcher">
      <Input
        id="inbox-searcher"
        placeholder={t('searcher')}
        className="searcher"
        onKeyUp={(e) => handleKeyUp(e)}
      />
      <InputGroupAddon addonType="append">
        <Button color="info" onClick={handleSearch}>
          <i className="fas fa-search"></i>
        </Button>
      </InputGroupAddon>
    </InputGroup>
  );
};

InboxSearcher.propTypes = {
  box: PropTypes.oneOf(['inbox', 'outbox', 'priority']),
};

export default InboxSearcher;
