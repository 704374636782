import CONSTANTS from "../CONSTANTS";

const messagesReducer = (
  state = {
    inbox: {
      page: 1,
      limit: 20,
      total_page: 0,
      total: 0,
      messages: [],
    },
    outbox: {
      page: 1,
      limit: 20,
      total_page: 0,
      total: 0,
      messages: [],
    },
    prioritybox: {
      page: 1,
      limit: 20,
      total_page: 0,
      total: 0,
      messages: [],
    },
    message: null,
    loading: false,
    loading_msg: false,
    browser: { prev: -1, next: 1 },
    selectedMessages: [],
    box: "inbox", //box active. Options = inbox,outbox,priority
  },
  action
) => {
  switch (action.type) {
    //INBOX
    case CONSTANTS.MESSAGES_GET_INBOX:
      return { ...state, loading: true };
    case CONSTANTS.MESSAGES_SET_INBOX:
      return { ...state, inbox: action.messages, loading: false };
    case CONSTANTS.MESSAGES_SET_INBOX_SCROLL:
      let inbox = {
        page: action.messages.page,
        limit: action.messages.limit,
        total_page: action.messages.total_page,
        total: action.messages.total,
        messages: [...state.inbox.messages, ...action.messages.messages],
      };
      return { ...state, inbox, loading: false };
    //OUTBOX
    case CONSTANTS.MESSAGES_GET_OUTBOX:
      return { ...state, loading: true };
    case CONSTANTS.MESSAGES_GET_OUTBOX_MSG:
      return { ...state, loading_msg: true };
    case CONSTANTS.MESSAGES_SET_OUTBOX:
      return { ...state, outbox: action.messages, loading: false };
    case CONSTANTS.MESSAGES_SET_OUTBOX_SCROLL:
      let outbox = {
        page: action.messages.page,
        limit: action.messages.limit,
        total_page: action.messages.total_page,
        total: action.messages.total,
        messages: [...state.outbox.messages, ...action.messages.messages],
      };
      return { ...state, outbox, loading: false };
    //PRIORITY BOX
    case CONSTANTS.MESSAGES_GET_PRIORITY:
      return { ...state, loading: true };
    case CONSTANTS.MESSAGES_SET_PRIORITY:
      return { ...state, loading: true };
    case CONSTANTS.MESSAGES_SET_PRIORITY_BOX:
      return { ...state, prioritybox: action.messages, loading: false };
    case CONSTANTS.MESSAGES_DELETE_PRIORITY:
      return { ...state, loading: true };
    case CONSTANTS.MESSAGES_SET_PRIORITYBOX_SCROLL:
      let prioritybox = {
        page: action.messages.page,
        limit: action.messages.limit,
        total_page: action.messages.total_page,
        total: action.messages.total,
        messages: [...state.prioritybox.messages, ...action.messages.messages],
      };
      return { ...state, prioritybox, loading: false };
    //GENERAL
    case CONSTANTS.MESSAGES_GET_MSG:
      return { ...state, loading_msg: true };
    case CONSTANTS.MESSAGES_SET_MSG:
      const message = action.message ? action.message.message : null;
      return { ...state, message: message, loading_msg: false };
    case CONSTANTS.MESSAGES_SET_BROWSER:
      return { ...state, browser: action.browser };
    case CONSTANTS.MESSAGES_LOADING_INBOX:
      return { ...state, loading: action.loading };
    case CONSTANTS.MESSAGES_LOADING_MSG:
      return { ...state, loading: action.loading };
    case CONSTANTS.MESSAGES_GET_SELECTED:
      return { ...state };
    case CONSTANTS.MESSAGES_SET_SELECTED:
      return { ...state, selectedMessages: action.messages };
    case CONSTANTS.MESSAGES_SET_BOX:
      return { ...state, box: action.box };

    default:
      return state;
  }
};

export default messagesReducer;
