class storageDispatcher {

    setLocalStorageEvent (name, value) {
        localStorage.setItem(name, value);
        const event = new Event('storage');
        event[name] = value;
        window.dispatchEvent(event);
    }
    
    removeLocalStorageEvent (name) {
        localStorage.removeItem(name);
        const event = new Event('storage');
        event[name] = null;
        window.dispatchEvent(event);
    }
}


export default new storageDispatcher();