import React, { useState, useMemo, useRef } from 'react';
import { Table } from 'reactstrap';
import { useTranslate } from 'react-translate';
import { useTable } from 'react-table';
import { useEffect } from 'react';
import LaboratoryService from "../../services/LaboratoryService";
import moment from "moment";

const HistoryList = () => {
  const t = useTranslate('history-list');

  const [laboratoryHistory, setLaboratoryHistory] = useState({});
  const refreshTable = useRef(false);

  const data = useMemo(
    () => laboratoryHistory && Object.keys(laboratoryHistory).length ? Object.keys(laboratoryHistory).map(id => {

      const finalData = {
        c1: laboratoryHistory[id].fullName,
        c2: laboratoryHistory[id].range,
      };

      laboratoryHistory[id].data.map(cell => {
        finalData[`date-${cell.date}`] = { val: cell.value, abnormal: cell.abnormal }
      });

      return { ...finalData };
    }) : [],
    //eslint-disable-next-line
    [laboratoryHistory, t]
  );


  const columns = useMemo(
    () => {

      const headers = [
        {
          Header: t("identifier"),
          accessor: "c1",
          defaultCanSort: false
        },
        {
          Header: t("range"),
          accessor: "c2",
          defaultCanSort: false
        }
      ];

      if (laboratoryHistory && Object.keys(laboratoryHistory).length) {
        Object.keys(laboratoryHistory).map(id => {
          laboratoryHistory[id].data.map(cell => {
            const date = moment(cell.date, "YYYYMMDDhhmmss").format("DD/MM/YYYY");

            if (!headers.find(h => h.Header === date)) {
              headers.push({
                Header: date,
                accessor: `date-${cell.date}`,
                defaultCanSort: false,
                Cell: (props) => {
                  return (
                    <div style={{ backgroundColor: props.cell.value && props.cell.value.abnormal ? "#ffdddd" : "" }}>
                      {props.cell.value ? String(props.cell.value.val) : ""}
                    </div>
                  );
                }
              });

              headers.sort((l, r) => {
                if (l.accessor.includes("date") && r.accessor.includes("date")) {
                  const date1 = new Date(moment(l.accessor.split("date-")[1], "YYYY-MM-DD"));
                  const date2 = new Date(moment(r.accessor.split("date-")[1], "YYYY-MM-DD"));

                  return date1.getTime() < date2.getTime() ? 1 : date1.getTime() > date2.getTime() ? -1 : 0;
                }
              })
            }
          });
        });
      }
      return headers;
    },
    //eslint-disable-next-line
    [laboratoryHistory, t]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  useEffect(() => {
    LaboratoryService.getStudyHistory()
      .then((res) => {
        refreshTable.current = true;
        setLaboratoryHistory(res.data);
      })
  }, []);

  useEffect(() => {
    refreshTable.current = false;
  }, []);

  return (
    <div className="mobile-x-overflow">
      <Table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  className={`session-table-header`}
                  {...column.getHeaderProps()}
                >
                  {column.render('Header')}

                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default HistoryList;
