import React, { useState } from "react";
import {
  Button,
  ButtonGroup,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
// import Select from "react-select";
import ReactFlagsSelect from "react-flags-select";
import "react-flags-select/css/react-flags-select.css";
// import Datetime from "react-datetime";
// import Image from "components/Icons/Image";
//Variables
// const countries = [
//   {
//     value: "Study type 1",
//     label: "Study type 1",
//   },
//   {
//     value: "Study type 2",
//     label: "Study type 2",
//   },
//   {
//     value: "Study type 3",
//     label: "Study type 3",
//   },
//   {
//     value: "Study type 4",
//     label: "Study type 4",
//   },
// ];

const flagCountries = ["US", "GB", "FR", "DE", "IT", "AR", "VE"];

const ShippingForm = () => {
  // const [country, setCountry] = useState(null);
  const [shippingType, setShippingType] = useState(null);

  const onSelectFlag = (countryCode) => {
    console.log(countryCode);
  };

  return (
    <React.Fragment>
      <Row>
        <Col xs={12} md={6}>
          <ReactFlagsSelect
            countries={flagCountries}
            placeholder="Select your country"
            defaultCountry="DE"
            onSelect={onSelectFlag}
          />
        </Col>
        <Col xs={12} md={6}>
          <ButtonGroup className="d-flex">
            <Button
              color="info"
              onClick={() => setShippingType(1)}
              active={shippingType === 1}
              className="mr-1"
            >
              Simple
            </Button>
            <Button
              color="info"
              onClick={() => setShippingType(2)}
              active={shippingType === 2}
            >
              Express
            </Button>
          </ButtonGroup>
        </Col>
        <Col xs={12} md={6}>
          <Label>City</Label>
          <FormGroup>
            <Input type="text" name="city" placeholder="City" />
          </FormGroup>
        </Col>
        <Col className="text-center" xs={12} md={6}>
          <Label>Postal Code</Label>
          <FormGroup>
            <Input type="text" name="postalCode" placeholder="Postal Code" />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Input
              type="address"
              name="addressLine1"
              placeholder="Address Line 1"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Input
              type="address"
              name="addressLine2"
              placeholder="Address Line 2"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Input
              type="textarea"
              name="notes"
              id="notes"
              placeholder="Delivery note"
            />
          </FormGroup>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ShippingForm;
