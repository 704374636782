class nativeSender {

    validEvents = [
        "LOGIN", 
        "LOGOUT", 
        "SUBSCRIBE", 
        "UNSUBSCRIBE", 
        "READED", 
        "RECEIVER_READY",
        "DOWNLOAD_BLOB",
        "DOWNLOAD_REPORT"
    ];

    /**
     * Send data to React Native Webview, using a global script that webview injects
     * @param {String} event Type of event to trigger in native Webview. Must be one of validEvents
     * @param {Object} data Data object to send
     */
    send = (event, data = {}) => {
        if ("ReactNativeSend" in window && this.validEvents.includes(event)) {
            window.ReactNativeSend({
                event: event,
                data: data
            });
        }
    }
};

export default new nativeSender();