import React, { Fragment } from "react";
import { ListGroup, ListGroupItem } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
//Actions
import { actionSetMessage } from "store/actions/messagesActions";
import { actionSetBox } from "store/actions/messagesActions";

const InboxMenuMobile = React.memo(({ showComponent, setShowForm }) => {
  const dispatch = useDispatch();

  const { box, inbox, outbox, prioritybox } = useSelector(
    (state) => state.messagesReducer
  );

  return (
    <Fragment>
      {showComponent !== "inbox-detail" && (
        <ListGroup className="inbox-menu inbox-menu-mobile list-group-horizontal">
          <ListGroupItem
            tag="a"
            className={box === "inbox" ? "active" : ""}
            onClick={(e) => {
              e.preventDefault();
              dispatch(actionSetBox("inbox"));
              dispatch(actionSetMessage());
              setShowForm(false);
            }}
          >
            <div className="align-items-center">
              <span className="icon-allmail h3"></span>
              <span className="inbox-menu-text ml-2">
                {`${inbox.total ? inbox.total : 0}`}
              </span>
            </div>
          </ListGroupItem>
          <ListGroupItem
            tag="a"
            className={box === "outbox" ? "active" : ""}
            onClick={(e) => {
              e.preventDefault();
              dispatch(actionSetBox("outbox"));
              dispatch(actionSetMessage());
              setShowForm(false);
            }}
          >
            <div className="align-items-center">
              <span className="icon-send h3"></span>
              <span className="inbox-menu-text ml-2">
                {`${outbox.total ? outbox.total : 0}`}
              </span>
            </div>
          </ListGroupItem>

          <ListGroupItem
            tag="a"
            className={box === "priority" ? "active" : ""}
            onClick={(e) => {
              e.preventDefault();
              dispatch(actionSetBox("priority"));
              dispatch(actionSetMessage());
              setShowForm(false);
            }}
          >
            <div className="align-items-center">
              <span className="icon-star h3"></span>
              <span className="inbox-menu-text ml-2">
                {`${prioritybox.total ? prioritybox.total : 0}`}
              </span>
            </div>
          </ListGroupItem>
        </ListGroup>
      )}
    </Fragment>
  );
});

export default InboxMenuMobile;
