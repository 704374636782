import StudyForm from "components/Devices/Booking/StudyForm";
import React from 'react'

class StepStudy extends React.Component {
  isValidated() {
    return true;
  }
  render() {
    return (
      <div className="container">
        <StudyForm />
      </div>
    );
  }
}

export default StepStudy
