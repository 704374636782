import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import BtnReply from './BtnReply';
import BtnDelete from './BtnDelete';
import BtnNew from './BtnNew';
import { actionSetMessage } from 'store/actions/messagesActions';

const Actions = ({ setShowForm, mobile }) => {
  const dispatch = useDispatch();
  const { message } = useSelector((state) => state.messagesReducer);

  const handleNewMessage = () => {
    dispatch(actionSetMessage());
  };

  return (
    <div className={mobile ? 'btn-group-inbox' : 'text-right'}>
      <BtnNew mobile={mobile} setShowForm={setShowForm} handleNewMessage={handleNewMessage} />

      <BtnReply mobile={mobile} setShowForm={setShowForm} disabled={!message} />

      <BtnDelete mobile={mobile} setShowForm={setShowForm} />

      {/* <BtnBack
        setShowForm={setShowForm}
        mobile={mobile}
        disabled={!message && setShowForm !== "inbox-form"}
      /> */}
    </div>
  );
};

export default Actions;
