import AxiosService from 'plugins/TS-lib-utils-public/services/AxiosService';

const axiosService = new AxiosService('notifications');
class PushNotificationService {
  static cancelRequest = () => {
    return axiosService.cancel();
  };

  // urlB64ToUint8Array is a magic function that will encode the base64 public key
  // to Array buffer which is needed by the subscription option
  static urlB64ToUint8Array = (base64String) => {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      // .replace(/\-/g, '+')
      .replace(/-/g, '+')
      .replace(/_/g, '/');
    const rawData = atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  };

  /**
   * checks if Push notification and service workers are supported by your browser
   */
  static isPushNotificationSupported = () => {
    return 'serviceWorker' in navigator && 'PushManager' in window;
  };
  /**
   * asks user consent to receive push notifications and returns the response of the user, one of granted, default, denied
   */
  static askUserPermission = async () => {
    return await Notification.requestPermission();
  };
  /**
   * shows a notification
   */
  static sendNotification = () => {
    const img = '/images/jason-leung-HM6TMmevbZQ-unsplash.jpg';
    const text = 'Take a look at this brand new t-shirt!';
    const title = 'New Product Available';
    const options = {
      body: text,
      icon: '/images/jason-leung-HM6TMmevbZQ-unsplash.jpg',
      vibrate: [200, 100, 200],
      tag: 'new-product',
      image: img,
      badge: 'https://spyna.it/icons/android-icon-192x192.png',
      actions: [
        {
          action: 'Detail',
          title: 'View',
          icon: 'https://via.placeholder.com/128/ff0000',
        },
      ],
    };
    navigator.serviceWorker.ready.then(function (serviceWorker) {
      serviceWorker.showNotification(title, options);
    });
  };
  /**
   *
   */
  static registerServiceWorker = () => {
    return navigator.serviceWorker.register('/sw.js');
  };
  /**
   *
   * using the registered service worker creates a push notification subscription and returns it
   *
   */
  static createNotificationSubscription = async () => {
    try {
      //wait for service worker installation to be ready
      const serviceWorker = await navigator.serviceWorker.ready;
      // subscribe and return the subscription
      const publicKey = await this.getPublicKey();
      const applicationServerKey = this.urlB64ToUint8Array(publicKey.data);
      const options = { applicationServerKey, userVisibleOnly: true };
      return await serviceWorker.pushManager.subscribe(options);
    } catch (error) {
      console.log(error);
    }
  };
  /**
   * returns the subscription if present or nothing
   */
  static getUserSubscription = () => {
    //wait for service worker installation to be ready, and then
    if (!navigator.serviceWorker || !navigator.serviceWorker.ready) {
      return null;
    }
    return navigator.serviceWorker.ready
      .then(function (serviceWorker) {
        return serviceWorker.pushManager.getSubscription();
      })
      .then(function (pushSubscription) {
        return pushSubscription;
      });
  };

  /**
   * Unsubscribe
   */
  static userUnsubscribe = (subscription) => {
    if (!navigator.serviceWorker || !navigator.serviceWorker.ready) {
      return null;
    }
    subscription
      .unsubscribe()
      .then(function (successful) {
        console.log('unsbuscribed');
      })
      .catch(function (e) {
        console.log(e.message);
      });
  };

  static getPublicKey = async () => {
    const response = await axiosService.get(`/push/public-key`, {
      headers: {
        Authorization: `Bearer ${axiosService.getToken()}`,
      },
    });
    return response;
  };

  static pushSusbcribe = async (subscription) => {
    const response = await axiosService.post(
      `/push/subscribe`,
      { subscription: JSON.stringify(subscription) },
      {
        headers: {
          Authorization: `Bearer ${axiosService.getToken()}`,
        },
      }
    );
    return response;
  };

  static pushUnsusbcribe = async (subscription) => {
    this.userUnsubscribe(subscription);
    const response = await axiosService.post(
      `/push/unsubscribe`,
      {
        subscription: JSON.stringify(subscription),
      },
      {
        headers: {
          Authorization: `Bearer ${axiosService.getToken()}`,
        },
      }
    );
    return response;
  };
}

export default PushNotificationService;
