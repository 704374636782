/**
 * For image validation
 */
class FileValidator {
  validImageTypes = ["image/jpeg", "image/png"];

  constructor(file) {
    this.file = file;
  }

  loadImage = (src) => {
    return new Promise((resolve, reject) => {
      let image = new Image();
      image.onload = (e) => resolve(e.target);
      image.onerror = reject;
      image.src = src;
    });
  };

  /**
   * Validate file's size
   * @param {number} size kb expected
   */
  isValidWeight = (size) => {
    if (this.validImageTypes.includes(this.file.type)) {
      const fsize = Math.round(this.file.size / 1024);
      return fsize <= size;
    }
    return false;
  };

  /**
   * Validate image's resolution
   * @param {number} width expected in Kb
   * @param {number} heigth expected in Kb
   */
  isValidImageResolution = async (width, heigth) => {
    if (this.validImageTypes.includes(this.file.type)) {
      const image = await this.loadImage(URL.createObjectURL(this.file));
      /**
       * Validate width & heigth
       */
      return (
        image.width > 0 &&
        image.width <= width &&
        image.height > 0 &&
        image.height <= heigth
      );
    }
    return false;
  };
}

export default FileValidator;
