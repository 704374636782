import CONSTANTS from "../CONSTANTS";

const initialState = {
    doctors: [],
};

const doctorsReducer = (state = initialState, action) => {
    switch (action.type) {
        case CONSTANTS.DOCTORS_SET_LIST:
            return { ...state, doctors: action.doctors };
        default:
            return state;
    }
};

export default doctorsReducer;