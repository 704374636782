import React from "react";
import { Button } from "reactstrap";
import { translate } from "react-translate";

const BtnDelete = () => {
  const t = translate('buttons');

  return (
    <Button size="sm" color="danger">
      {t('delete')} <i className="icon-trash"></i>
    </Button>
  );
};

export default BtnDelete;
