import { createStore, combineReducers, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
//Sagas
import { messagesSaga } from "./sagas/messagesSaga";
import { appointmentsSaga } from "./sagas/appointmentsSaga";
import { usersSaga } from "./sagas/usersSaga";
import { profilesSaga } from "./sagas/profilesSaga";
import { notificationsSaga } from "./sagas/notificationsSaga";
import { wsSaga } from "./sagas/wsSaga";
import { userDataSaga } from "./sagas/userDataSaga";
import { doctorsSaga } from "./sagas/doctorsSaga";
import { helpsSaga } from "./sagas/helpsSaga";
//Reducers
import messagesReducer from "./reducers/messagesReducer";
import appointmentsReducer from "./reducers/appointmentsReducer";
import patientsReducer from "./reducers/patientsReducer";
import responsesReducer from "./reducers/responsesReducer";
import loadingReducer from "./reducers/loadingReducer";
import userReducer from "./reducers/userReducer";
import commonReducer from "./reducers/commonReducer";
import profilesReducer from "./reducers/profilesReducer";
import notificationsReducer from "./reducers/notificationsReducer";
import userDataReducer from "./reducers/userDataReducer";
import helpsReducer from "./reducers/helpsReducer";

import doctorsReducer from "./reducers/doctorsReducer";

const allReducers = combineReducers({
  responsesReducer,
  patientsReducer,
  doctorsReducer,
  messagesReducer,
  appointmentsReducer,
  loadingReducer,
  userReducer,
  commonReducer,
  profilesReducer,
  notificationsReducer,
  userDataReducer,
  helpsReducer
});

const rootReducer = (state, action) => {
  if (action.type === "RESET_APP") {
    state = undefined;
  }
  return allReducers(state, action);
};

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware))
  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

sagaMiddleware.run(messagesSaga);
sagaMiddleware.run(appointmentsSaga);
sagaMiddleware.run(usersSaga);
sagaMiddleware.run(profilesSaga);
sagaMiddleware.run(notificationsSaga);
sagaMiddleware.run(wsSaga);
sagaMiddleware.run(userDataSaga);
sagaMiddleware.run(doctorsSaga);
sagaMiddleware.run(helpsSaga);

export default store;
