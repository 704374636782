import AxiosService from "plugins/TS-lib-utils-public/services/AxiosService";

class AuthPatientsService extends AxiosService {
  constructor() {
    super("auth");
  }
  sendHelp = async (email, type = "patient") => {
    return await this.post(
      `/patients/help`,
      email
    );
  };
}

export default new AuthPatientsService();
