import React, { useState, useMemo, useRef } from 'react';
import { Table, Col, Row, Button, Input, FormGroup, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { useTranslate } from 'react-translate';
import { useSortBy, useTable, usePagination } from 'react-table';
import { useEffect } from 'react';
import history from 'historyRouter';
import LaboratoryService from "../../services/LaboratoryService";
import moment from "moment";
import LoadingLocal from 'plugins/TS-lib-utils-public/components/Loading/LoadingLocal';
import nativeSender from 'helpers/nativeSender';
import { useSelector } from 'react-redux';

const StudiesList = () => {
  const t = useTranslate('studies-list');
  const thl7 = useTranslate('hl7-status');
  const [laboratoryList, setLaboratoryList] = useState("loading");
  const refreshTable = useRef(false);
  const [pageLimit, setPageLimit] = useState(0);
  const [sortDate, setSortDate] = useState(-1);
  const { nativeWebView } = useSelector(state => state.commonReducer);

  function base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }
  // Function will execute on click of button
  const convertPDFToUrl = async (reportId, doctorId, patientId) => {
    try {
      if (nativeWebView) {
        return nativeSender.send('DOWNLOAD_REPORT', { reportId: reportId, doctorId: doctorId, patientId: patientId });
      }
      const data = await LaboratoryService.getStudyReportFile(reportId, patientId, doctorId);
      const reportFile = await data.data;

      // using Java Script method to get PDF file
      var bytes = base64ToArrayBuffer(reportFile);
      var blob = new Blob([bytes], { type: "application/pdf" });
      // Creating new object of PDF file
      const fileURL = window.URL.createObjectURL(blob);
      const fileName = `${reportId}.pdf`;


      //const linkSource = `data:application/pdf;base64,${reportFile}`;
      const downloadLink = document.createElement("a");
      downloadLink.href = fileURL;
      downloadLink.download = fileName;
      downloadLink.click();
      //return fileURL
    } catch (error) {
      console.log(error);
    }

  }
  //componente para poder mostrar el estado de carga del boton mientras se espera el pdf del llamado a la api
  const LoadingButton = ({ reportId, doctorId, patientId }) => {
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      if (isLoading) {
        convertPDFToUrl(reportId, doctorId, patientId).then(() => {
          setIsLoading(false);
        })
      }
    }, [isLoading]);

    const handleClick = () => setIsLoading(true);
    return (
      <Button
        size="sm"
        color="info"
        onClick={!isLoading ? handleClick : null}
        target="_blank"
        rel="noopener noreferrer"
        disabled={isLoading}
      //descomentar la siguiente linea si lo que se desea es que se descargue directamente
      //download={reportId + ".pdf"}
      >
        {isLoading ? "..." : <i className="icon icon-attach ml-2"></i>}

      </Button>
    )
  }

  const data = useMemo(
    () => laboratoryList && laboratoryList.length && laboratoryList != "loading" ? laboratoryList.map(p => {

      const reportData = {
        eingangsdatum: p?.created_at ? moment(p?.created_at, "YYYYMMDDhhmmss").format("DD/MM/YYYY") : "",
        status: p?.status ? `${thl7(p?.status)}` : "",
        arzt: <><small>{p.doctor?.title}</small> <br/> {p.doctor?.lastName}</>,
        c5: p?.readed ? "true" : "",
        c6: <>
          <Button
            color="info"
            onClick={() => {
              history.push(`lab-studies-detail/${p?._id}`);
            }}
          >
            {t('details')}
          </Button>
        </>,
        c7: p.reportFileExists ? <LoadingButton reportId={p._id} doctorId={p.doctor.pifs_id} patientId={p.patient.pifs_id} /> : <></>,
      };

      return { ...reportData };
    }) : [],
    //eslint-disable-next-line
    [laboratoryList, t]
  );


  const columns = useMemo(
    () => [
      {
        Header: `${t('actions')}`,
        accessor: "c6",
        disableSortBy: true
      },
      {
        Header: `${t('date-of-receipt')}`,
        accessor: "eingangsdatum",
        defaultCanSort: true
      },
      {
        Header: `Arzt`,
        accessor: "arzt",
        disableSortBy: true
      },
      {
        Header: `${t('status')}`,
        accessor: "status",
        defaultCanSort: true
      },
      {
        Header: `${t('pdf')}`,
        accessor: "c7",
        disableSortBy: true
      }
      // {
      //   Header: "Ordered",
      //   accessor: "ordered",
      //   defaultCanSort: true
      // },
      // {
      //   Header: `Doctor`,
      //   accessor: "doctor",
      //   defaultCanSort: true,
      // },
      // {
      //   Header: `Email`,
      //   accessor: "doctorEmail",
      //   defaultCanSort: true
      // },
      // {
      //   Header: `Actions`,
      //   accessor: "c6",
      //   disableSortBy: true
      // }
    ],
    //eslint-disable-next-line
    [t]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable({
    columns,
    data,
    initialState: {
      pageSize: 10,
    },
    manualPagination: true,
    pageCount: pageLimit,
    autoResetPage: !refreshTable.current
  },
    useSortBy,
    usePagination);

  useEffect(() => {
    LaboratoryService.getStudiesList(pageIndex, pageSize, sortDate)
      .then((res) => {
        refreshTable.current = true;
        setLaboratoryList(res.data ? res.data.data : []);
        setPageLimit(res.data ? res.data.lastPage + 1 : 0);
        setPageSize(res.data ? res.data.limit : 10);
      });
  }, [pageIndex, pageSize, sortDate]);

  useEffect(() => {
    refreshTable.current = false;
  }, []);

  return (
    <>
      <div className="mobile-x-overflow">
        <Table {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  column.id === "eingangsdatum" ? (
                    
                    <th
                    className={`session-table-header ${column.id === "c4" ? "sortable-header" : ""}`}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    onClick={e => {
                      setSortDate(!sortDate ? 1 : sortDate === 1 ? -1 : 0);
                    }}
                  >
                    {/* Sortable date header */}
                    {column.render('Header')}
                    <span>
                      <b className={`${sortDate != 0 ? sortDate === -1 ? "caret-desc" : "caret-asc" : "caret-default"}`}></b>
                    </span>
                  </th>
                  ) : (
                    <th
                    className={`session-table-header ${column.id === "c4" ? "sortable-header" : ""}`}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {/* Sortable status */}
                    {column.render('Header')}

                    {column.id === "status" && (
                      <span>
                        <b className={`${column.isSorted ? column.isSortedDesc ? "caret-desc" : "caret-asc" : "caret-default"}`}></b>
                      </span>
                    )}

                  </th>
                  )
                  
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {laboratoryList == "loading" ? (<tr><td><LoadingLocal /></td></tr>)
              : (
                page.map(row => {
                  prepareRow(row)
                  return (
                    <tr 
                      {...row.getRowProps((state, rowInfo) => {
                        if (rowInfo && rowInfo.row) {
                          return {
                              style: {
                                  fontWeight: !rowInfo.row.original.c5.length ? 'bold' : 'normal',
                              }
                          }
                      } else return {}
                      })}
                    >
                      {row.cells.map(cell => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            className={cell.column.id === "c1" ? `d-flex` : ""}
                          >
                            {cell.column.id === "c1" && <div className={`dot-${cell.value === "Unavailable" ? "un" : ''}available`}></div>}
                            {cell.render('Cell')}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })
              )}
          </tbody>
        </Table>
      </div>

      <Row className="text-center justify-content-center mt-3">
        <nav aria-label="Page navigation example">
          <Pagination
            className="pagination justify-content-end"
            listclassname="justify-content-end"
          >
            <PaginationItem disabled={!canPreviousPage}>
              <PaginationLink
                href="#previous-page"
                onClick={e => {
                  e.preventDefault()
                  previousPage()
                }}
                tabIndex="-1"
                className="bg-info text-white"
              >
                <i className='fa fa-angle-double-left'></i>
              </PaginationLink>
            </PaginationItem>
            <PaginationItem disabled={pageIndex < 2}>
              <PaginationLink href="#minus-two" onClick={e => {
                e.preventDefault();
                gotoPage(pageIndex - 2);
              }}>
                {canPreviousPage && pageIndex > 1 ? pageIndex - 1 : " "}
              </PaginationLink>
            </PaginationItem>
            <PaginationItem disabled={pageIndex == 0}>
              <PaginationLink href="#minus-one"
                onClick={e => {
                  e.preventDefault();
                  previousPage()
                }}>
                {!canPreviousPage ? " " : pageIndex}
              </PaginationLink>
            </PaginationItem>
            <PaginationItem active>
              <PaginationLink href="#actual-page" onClick={e => { e.preventDefault() }} className="bg-info">
                {pageIndex + 1}
              </PaginationLink>
            </PaginationItem>
            <PaginationItem disabled={!canNextPage}>
              <PaginationLink href="#plus-one" onClick={e => {
                e.preventDefault();
                nextPage();
              }}>
                {canNextPage ? pageIndex + 2 : " "}
              </PaginationLink>
            </PaginationItem>
            <PaginationItem disabled={!(pageCount - pageIndex > 2)}>
              <PaginationLink href="#plus-two" onClick={e => {
                e.preventDefault();
                gotoPage(pageIndex + 2);
              }}>
                {(pageCount - pageIndex > 2) ? pageIndex + 3 : " "}
              </PaginationLink>
            </PaginationItem>
            <PaginationItem disabled={!canNextPage}>
              <PaginationLink href="#next-page" onClick={e => {
                e.preventDefault();
                nextPage();
              }}
                className="bg-info text-white"
              >
                <i className='fa fa-angle-double-right'></i>
              </PaginationLink>
            </PaginationItem>
          </Pagination>
        </nav>
      </Row>
    </>

  );
};

export default StudiesList;
