import { takeEvery, put, call } from "redux-saga/effects";
import { toast } from "react-toastify";
//Redux Actions
import CONSTANTS from "../CONSTANTS";
import {
  actionGetNotifications,
  actionSetNotificationsMessages,
  actionSetNotificationsLaboratory,
} from "../actions/notificationsActions";
//Services
import NotificationsService from "../../services/NotificationsService.js";
import { actionGetMessages } from "store/actions/messagesActions";
//Selectors
import { select } from "redux-saga/effects";
import { getUser } from "../selectors/selectors";
import utilTranslations from "helpers/utilTranslations";

/**
 * Get notifications
 */
function* sagaGetNotifications({ module }) {
  //Translations
  const user = yield select(getUser);
  const t = utilTranslations(user.language || 'en', 'notifications-saga');
  try {
    const result = yield call(
      NotificationsService.getNotifications,
      module ? module : null
    );
    if (result.status >= 400) {
      throw new Error(t("get-notifications-failed"));
    }
    if (module === "messages") {
      yield put(actionSetNotificationsMessages(result.data));
    }
    if (module === 'laboratory') {
      yield put(actionSetNotificationsLaboratory(result.data));
    }
  } catch (error) {
    //toast.error(error.message || "Get notifications failed");
  }
}

/**
 * Read notifications
 */
function* sagaReadNotification({ id, read }) {
  //Translations
  const user = yield select(getUser);
  const t = utilTranslations(user.language || 'en', 'notifications-saga');
  try {
    const result = yield call(NotificationsService.readNotification, id, read);
    if (result.status >= 400) {
      throw new Error(t("reading-notifications-failed"));
    }
    const notification = result.data;
    if (notification.module) {
      yield put(actionGetMessages(notification.module));
      yield put(actionGetNotifications(notification.module));
    } else {
      yield put(actionGetNotifications());
    }
  } catch (error) {
    toast.error(error.message || t("reading-notifications-failed"));
  }
}

export function* notificationsSaga() {
  console.log("*Main Notifications Saga");
  yield takeEvery(CONSTANTS.NOTIFICATIONS_GET, sagaGetNotifications);
  yield takeEvery(CONSTANTS.NOTIFICATIONS_READ, sagaReadNotification);
}
