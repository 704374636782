import React from "react";
import {
  Card,
  CardSubtitle,
  CardBody,
  CardText,
  Row,
  Col,
  Button,
} from "reactstrap";
import { useTranslate } from 'react-translate';
import { toast } from "react-toastify";

var QRCode = require("qrcode.react");

const Qr2fa = React.memo((props) => {
  const { user } = props;
  const t=useTranslate('2fa');
  return (
    <Row>
      <Col md={6} sm={12}>
        <Card>
          <CardBody className="text-center">
            <CardSubtitle>{t('qr-code-scan')}</CardSubtitle>
            <QRCode
              id="123456"
              value={user.uri}
              size={200}
              level={"L"}
              includeMargin={true}
            />
          </CardBody>
        </Card>
      </Col>
      <Col md={6} sm={12} className="d-flex">
        <Card>
          <CardBody className="text-center">
            <CardText>
              {t('instruction')}
              <br></br>
              <strong>{t('instruction-save-code')}</strong>
              <br></br>
              <b>{user.key}</b>
              <br />
              <Button
                size="sm"
                color="info"
                onClick={() => {
                  navigator.clipboard.writeText(user.key);
                  toast.success(t('text-copied-clipboard'));
                }}
              >
                {t('copy-code')}
              </Button>
            </CardText>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
});

export default Qr2fa;
