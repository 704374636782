import React from "react";
import { Button } from "reactstrap";
import DoctorInfo from "components/Doctors/DoctorInfo";
import { Link } from "react-router-dom";
import { translate } from "react-translate";

const AppointmentsDoctor = ({ notes, dateChecked }) => {
  const t = translate('appointments-doctor');
  return (
    <div className="card-doctor">
      <DoctorInfo dateChecked={dateChecked} sm="12" md="12" />
      <br />
      <div className="d-block appointments-actions">
        {notes && (
          <Link className="btn btn-info btn-block" to="/admin/notes">
            Notes
          </Link>
        )}
        <Button
          block
          color="info"
          href="#pablo"
          size=""
          onClick={(e) => e.preventDefault()}
        >
          {t('new-appointment')}
        </Button>
        <Button
          block
          color="primary"
          href="#pablo"
          size=""
          onClick={(e) => e.preventDefault()}
        >
          {t('cancel-appointment')}
        </Button>
      </div>
    </div>
  );
};

export default translate('appointments-doctor')(AppointmentsDoctor);