import React from "react";
import { Button } from "reactstrap";
import { Fragment } from "react";
import { useTranslate } from 'react-translate';

const BtnAttachFile = () => {
  const attactFile = (e) => {
    e.preventDefault();
    let input = document.getElementById("note-attach-file");
    input.click();
  };
  const t = useTranslate('buttons');

  return (
    <Fragment>
      <input type="file" id="note-attach-file" className="d-none" />
      <Button size="sm" color="info" onClick={attactFile}>
        {t('attach-file')} <i className="icon-attach"></i>
      </Button>
    </Fragment>
  );
};

export default BtnAttachFile;
