import React from "react";
import PropTypes from "prop-types";
import BtnFile from "components/Messages/Actions/BtnFile";

const InboxFileList = ({ files }) => {
  return (
    <>
      {files.map((file, i) => {
        return <BtnFile key={i} file={file} />;
      })}
    </>
  );
};

InboxFileList.propTypes = {
  files: PropTypes.array,
};

export default InboxFileList;
