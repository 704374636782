/*!

=========================================================
* Now UI Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
// import SweetAlert from "react-bootstrap-sweetalert";
// reactstrap components
import { Alert, Row, Col } from 'reactstrap';
import moment from 'moment';
// import { actionDelMessage } from "store/actions/messagesActions";
// import { actionSweetAlert } from "store/actions/commonActions";
import LoadingLocal from 'plugins/TS-lib-utils-public/components/Loading/LoadingLocal';
import { Fragment } from 'react';
import { useTranslate } from 'react-translate';

const InboxMobileDetail = () => {
  const t = useTranslate('inbox-mobile-detail');
  const { message, loading_msg } = useSelector(
    (state) => state.messagesReducer
  );

  useEffect(() => {
    return () => {
      // hide:true;
    };
  }, [loading_msg]);

  if (loading_msg) {
    return <LoadingLocal />;
  }

  if (!loading_msg && !message) {
    return (
      <Row>
        <Col sm={12} className="p-5">
          <Alert color="info">{t('select-a-message')}</Alert>
        </Col>
      </Row>
    );
  }

  return (
    <Fragment>
      {message && (
        <div className="inbox-mobile-detail">
          <Row>
            <Col sm={12}>
              <div className="inbox-mobile-detail-header pt-3 pb-3">
                <div className="pt-1 pb-1 pl-2 pr-2">
                  <div className="inbox-message-from">
                    <span>{message.from.fullName}</span>
                    <span className="message-date ml-5">
                      {moment(message.date).format('L HH:mm')}
                    </span>
                  </div>
                  <div className="inbox-message-subject">{message.subject}</div>
                </div>
              </div>
            </Col>
            <Col sm={12}>
              <div className="inbox-message-text p-3 overflow-auto">
                <p className="text-justify">{message.text}</p>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </Fragment>
  );
};

export default InboxMobileDetail;
