import AxiosService from "plugins/TS-lib-utils-public/services/AxiosService";

class AppointmentsService extends AxiosService {
  constructor() {
    super("appointments");
  }
  appointments = async (userId) => {
    try {
      const response = await this.get(`/patients/${userId}/appointments`, {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      });
      return response.data;
    } catch (error) {
      return { ...error, statusCode: error.status };
    }
  };
}

export default new AppointmentsService();
