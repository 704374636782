/**
 * Store blob object
 */
export const urlsMap = new Map();
/**
 * Set a blob file in the map
 * @param {string} key
 * @param {blob} blob file
 * @returns url
 */
export const setUrl = (key, blob) => {
  let url = URL.createObjectURL(blob);
  urlsMap.set(key, url);
  return url;
};

/**
 * Get a blob file of the map
 * @param {string} key
 * @returns url
 */
export const getUrl = (key) => {
  if (urlsMap.has(key)) {
    return urlsMap.get(key);
  }
  return null;
};
/**
 * Delete key of the map
 * @param {string} key
 * @returns url
 */
export const deleteUrl = (key) => {
  if (urlsMap.has(key)) {
    let url = urlsMap.get(key);
    URL.revokeObjectURL(url);
    return urlsMap.delete(key);
  }
  return null;
};
