/*!

=========================================================
* Now UI Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useCallback, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import useAppointmentNext from "hooks/useAppointmentNext";
import moment from "moment";
//Helpers
import Dates from "helpers/Dates";
// components components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  ListGroup,
  ListGroupItem,
  Alert,
} from "reactstrap";
import LoadingLocal from "plugins/TS-lib-utils-public/components/Loading/LoadingLocal";
import Icon from "components/Icons/Icon";
import { translate } from "react-translate";

const AppointmentsList = (props) => {
  const t = translate('appointments');

  const appointments = useSelector(
    (state) => state.appointmentsReducer.appointments
  );

  const scrollRef = useRef(null);

  const calculateDays = useCallback((date) => {
    let diff = Dates.dateDiff(date, moment());
    let dateCondition =
      diff.years === 0 &&
      diff.months === 0 &&
      diff.days >= -2 &&
      diff.days <= 0;
    if (dateCondition) {
      return <span>{moment(date).fromNow()}</span>;
    } else {
      return (
        <span className="text-uppercase">
          {moment(date).format("ddd MM/DD/YY HH:mm")}
        </span>
      );
    }
  }, []);

  const nextAppointment = useAppointmentNext(appointments);

  useEffect(() => {
    if (nextAppointment && scrollRef) {
      let el = document.getElementById(nextAppointment);
      scrollRef.current.scrollTop = el.offsetTop - 100;
    }
  }, [nextAppointment]);

  if (typeof appointments === "undefined") {
    return <LoadingLocal />;
  }

  if (
    !Array.isArray(appointments) ||
    (typeof appointments !== "undefined" && appointments.length === 0)
  ) {
    return (
      <Card
        className={
          (props.mobile ? "animated fast fadeInLeft" : "") + props.className
        }
      >
        <CardHeader>
          <CardTitle tag="h2" className="text-danger">
            <Icon file="icons/appointments.svg" className="mr-2" />
            <small>{t('appointments-list')}</small>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Alert color="danger">{t('you-dont-have-appointments')}</Alert>
        </CardBody>
      </Card>
    );
  }
  return (
    <Card
      className={
        (props.mobile ? "animated fast fadeInLeft" : "") + props.className
      }
    >
      <CardHeader>
        <CardTitle tag="h2" className="text-danger">
          <Icon file="icons/appointments.svg" className="mr-2" />
          <small>{t('appointments-list')}</small>
        </CardTitle>
      </CardHeader>
      <CardBody>
        <div
          className="overflow-auto"
          style={{ maxHeight: "500px" }}
          ref={scrollRef}
        >
          <ListGroup>
            {appointments &&
              appointments.length > 0 &&
              appointments.map((appointment, key) => {
                // handleScroll(appointment, `item-${key}`);
                return (
                  <ListGroupItem
                    key={key}
                    id={`item-${key}`}
                    className="p-1"
                    onClick={() => {
                      if (props.mobile) {
                        props.setAppointmentsSwitch("detail");
                      }
                    }}
                    style={{ border: "none" }}
                  >
                    <div className="text-left text-truncate d-flex justify-content-between">
                      <strong>Dr. {appointment.physicianName}</strong>
                      {calculateDays(appointment.startDate)}
                    </div>
                    <div className="text-justify text-truncate">{t('speciality')}</div>
                  </ListGroupItem>
                );
              })}
          </ListGroup>
        </div>
      </CardBody>
    </Card>
  );
};

export default withRouter(AppointmentsList);