import CONSTANTS from "../CONSTANTS";

const initialState = {
  users: [],
};

const userDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.USERS_SET_LIST:
      return { ...state, users: action.users };
    default:
      return state;
  }
};

export default userDataReducer;
