/*!

=========================================================
* Now UI Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { translate } from "react-translate";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
// reactstrap components
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Button,
} from "reactstrap";

// core components
import bgImage from "assets/img/bg16.jpg";
import { actionRegister } from "store/actions/usersActions";

const userSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  username: yup.string().email().required(),
  password: yup.string().min(6),
  rePassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  terms: yup.boolean().required(),
});

const RegisterPage = (props) => {
  const { t } = props;
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userReducer);
  const { register, handleSubmit, errors, formState } = useForm({
    validationSchema: userSchema,
  });
  const { touched } = formState;

  const [firstNameFocus, setFirstNameFocus] = useState(false);
  const [lastNameFocus, setLastNameFocus] = useState(false);
  const [usernameFocus, setUsernameFocus] = useState(false);
  const [passwordFocus, setPasswordFocus] = useState(false);
  const [rePasswordFocus, setRePasswordFocus] = useState(false);

  useEffect(() => {
    document.body.classList.add("register-page");
    return () => {
      return document.body.classList.remove("register-page");
    };
  }, []);

  const onSubmit = async (data) => {
    if (!data.terms) {
      return toast.warning(t("you-must-accept-the-terms-of-service"));
    }
    dispatch(actionRegister(data));
  };

  const isAuth = () => {
    if (user.isAuth) {
      props.history.push("/admin/profile");
    }
  };

  const onEmailChange = (e) => {
    e.target.value = e.target.value.toLowerCase();
  }


  useEffect(() => {
    isAuth();
    return () => null;
    // eslint-disable-next-line
  }, [user.isAuth]);

  return (
    <Fragment>
      <img
        src="/General/login/background.png"
        width="100%"
        alt=""
        className="background-auth"
      />
      <div className="content content-auth">
        <div className="register-page">
          <Container>
            <div className="login-title">
              <img src={"/General/login/logo.svg"} alt="" />
            </div>
            <Row className="justify-content-center">
              <Col lg={4} md={8} xs={12}>
                <Card className="card-signup card-plain card-auth">
                  <CardHeader className="text-center">
                    <CardTitle tag="h2" className="text-info">
                      {t("register")}
                    </CardTitle>
                    <div className="social btns-mr-5">
                      <Button className="btn-icon btn-round" color="twitter">
                        <i className="fab fa-twitter" />
                      </Button>
                      <Button className="btn-icon btn-round" color="dribbble">
                        <i className="fab fa-dribbble" />
                      </Button>
                      <Button className="btn-icon btn-round" color="facebook">
                        <i className="fab fa-facebook-f" />
                      </Button>
                      <h5 className="card-description">
                        {t("register-classic")}
                      </h5>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                      <InputGroup
                        className={
                          (firstNameFocus ? "input-group-focus" : "") +
                            touched.firstName && errors.firstName
                            ? "has-danger"
                            : ""
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons users_circle-08" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <input
                          ref={register}
                          className="form-control"
                          name="firstName"
                          type="text"
                          placeholder={t("first-name") + "..."}
                          onFocus={(e) => setFirstNameFocus(true)}
                          onBlur={(e) => setFirstNameFocus(false)}
                        />
                      </InputGroup>
                      <InputGroup
                        className={
                          (lastNameFocus ? "input-group-focus" : "") +
                            touched.lastName && errors.lastName
                            ? "has-danger"
                            : ""
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons users_circle-08" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <input
                          ref={register}
                          className="form-control"
                          name="lastName"
                          type="text"
                          placeholder={t("last-name") + "..."}
                          onFocus={(e) => setLastNameFocus(true)}
                          onBlur={(e) => setLastNameFocus(false)}
                        />
                      </InputGroup>
                      <InputGroup
                        className={
                          (usernameFocus ? "input-group-focus" : "") +
                            touched.username && errors.username
                            ? "has-danger"
                            : ""
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons users_single-02" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <input
                          ref={register}
                          className="form-control"
                          name="username"
                          type="email"
                          placeholder={t("username") + "..."}
                          onChange={(e) => { onEmailChange(e); }}
                          onFocus={(e) => setUsernameFocus(true)}
                          onBlur={(e) => setUsernameFocus(false)}
                        />
                      </InputGroup>
                      <InputGroup
                        className={
                          (passwordFocus ? "input-group-focus" : "") +
                            touched.password && errors.password
                            ? "has-danger"
                            : ""
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons ui-1_lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <input
                          ref={register}
                          className="form-control"
                          name="password"
                          type="password"
                          placeholder={t("password") + "..."}
                          onFocus={(e) => setPasswordFocus(true)}
                          onBlur={(e) => setPasswordFocus(false)}
                        />
                      </InputGroup>
                      <InputGroup
                        className={
                          (rePasswordFocus ? "input-group-focus" : "") +
                            touched.rePassword && errors.rePassword
                            ? "has-danger"
                            : ""
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons ui-1_lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <input
                          ref={register}
                          className="form-control"
                          name="rePassword"
                          type="password"
                          placeholder={t("re-password") + "..."}
                          onFocus={(e) => setRePasswordFocus(true)}
                          onBlur={(e) => setRePasswordFocus(false)}
                        />
                      </InputGroup>
                      <FormGroup check>
                        <Label check>
                          <input
                            name="terms"
                            ref={register}
                            className="form-control"
                            type="checkbox"
                          />
                          <span className="form-check-sign" />
                          <span className="register-term">
                            {t("terms-1")}{" "}
                            <a href="#something">{t("terms-2")}</a>.
                          </span>
                        </Label>
                      </FormGroup>
                      <CardFooter className="text-center card-auth">
                        <Button
                          type="submit"
                          color="info"
                          size="sm"
                          className="btn-block btn-wd"
                        >
                          {t("get-started")}
                        </Button>
                      </CardFooter>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div
        className="full-page-background"
        style={{ backgroundImage: "url(" + bgImage + ")" }}
      />
    </Fragment>
  );
};

export default translate("register-page")(RegisterPage);
