import { useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { actionLogout } from 'store/actions/usersActions';
import AuthService from '../services/AuthService';

const useSessionTimer = (user) => {
  const dispatch = useDispatch();
  const { _id, session_expire, type } = user;
  const defaultTime = type === 'doctor' ? 168 * 60 * 60 : 15 * 60;
  const timer = (session_expire || defaultTime) * 1000 + 2000;

  useEffect(() => {
    let isSubscribed = true;
    let interval = null;

    const requestInterceptor = axios.interceptors.request.use(
      (config) => {
        let url = config.url;
        // Do something before request is sent
        if (isSubscribed && url.indexOf('check_session') === -1) {
          clearInterval(interval);
          if (_id) {
            interval = setInterval(async () => {
              let response = await AuthService.checkSession();
              if (
                typeof response === undefined ||
                (response && response === 401)
                // response === 403
              ) {
                dispatch(actionLogout());
                window.location.reload();
              }
            }, timer);
          }
        }
        return config;
      },
      (error) => {
        // Do something with request error
        return Promise.reject(error);
      }
    );

    const responseInterceptor = axios.interceptors.response.use(
      (response) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data

        return response;
      },
      function (error) {
        if (error.response && error.response.status === 401) {
          clearInterval(interval);
          dispatch(actionLogout());
        }
        return Promise.reject(error);
      }
    );

    return () => {
      isSubscribed = false;
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
      clearInterval(interval);
    };
  }, [timer, dispatch, _id, session_expire]);

  return timer;
};

useSessionTimer.propTypes = {
  user: PropTypes.object.isRequired,
};

export default useSessionTimer;
