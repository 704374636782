import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// reactstrap components
import { Input, FormGroup, Form, Label, Button, Row, Col } from 'reactstrap';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { actionUserEdit } from 'store/actions/usersActions';
import { useTranslate } from 'react-translate';
import SweetAlert from 'react-bootstrap-sweetalert';

const CredentialsForm = () => {
  const t = useTranslate('credentials');
  const user = useSelector((state) => state.userReducer);
  const [sweetAlert, setSweetAlert] = useState(null);

  const dispatch = useDispatch();

  const emailRex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const userNameRex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))$/;

  const userSchema = yup.object().shape({
    auth_email: yup
      .string()
      .matches(emailRex, t('error-email-invalid'))
      .required(t('error-email-required')),
    username: yup
      .string()
      .matches(userNameRex, t('error-username-invalid'))
      .required(t('error-username-required')),
  });

  const { register, handleSubmit, errors } = useForm({
    validationSchema: userSchema,
    mode: 'onChange',
    defaultValues: user,
  });

  const onEmailChange = (e) => {
    e.target.value = e.target.value.toLowerCase();
  }

  const onSubmit = (data) => {
    setSweetAlert(
      <SweetAlert
        custom
        customIcon="/General/icons/warning-sign-orange.svg"
        title={t('are-you-sure')}
        onConfirm={() => {}}
        customButtons={
          <>
            <Button
              className="mb-3"
              color="info"
              size="lg"
              onClick={() => {
                setSweetAlert(null);
                dispatch(actionUserEdit(data));
              }}
            >
              {t('btn-yes')}
            </Button>

            <Button
              className="mb-3 ml-2"
              color="danger"
              size="lg"
              onClick={() => setSweetAlert(null)}
            >
              {t('btn-no')}
            </Button>
          </>
        }
      ></SweetAlert>
    );
  };

  return (
    <div className="setting-section">
      {sweetAlert}
      <h5 className="general-card-subtitle">{t('change-credentials')}</h5>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col sm="12" md="6">
            <Label>{t('auth_email')}</Label>
            <FormGroup
              className={`has-label ${errors.email ? 'has-danger' : ''}`}
            >
              <Input type="text" disabled={true} innerRef={register} name="auth_email" onChange={(e) => {onEmailChange(e);}} />
            </FormGroup>
          </Col>
          {/*
          <Col sm="12" md="6">
            <Label>{t('username')}</Label>
            <FormGroup
              className={`has-label ${errors.username ? 'has-danger' : ''}`}
            >
              <Input 
              type="text" 
              innerRef={register} 
              name="username"
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <Button
              className="float-right"
              color="info"
              type="submit"
              size="sm"
            >
              {t('save-button')}
            </Button>
          </Col>
  */}
        </Row>
      </Form>
    </div>
  );
};

export default CredentialsForm;
