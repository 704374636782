import CONSTANTS from "../CONSTANTS";

export const actionGetAppointments = () => ({
  type: CONSTANTS.APPOINTMENTS_GET
});

export const actionSetAppointments = appointments => ({
  type: CONSTANTS.APPOINTMENTS_SET,
  appointments
});

export const actionSetAppointment = appointment => ({
  type: CONSTANTS.APPOINTMENTS_SET_AP,
  appointment
});

export const actionGetAppointment = key => ({
  type: CONSTANTS.APPOINTMENTS_SET_AP,
  key
});
