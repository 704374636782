import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Row, Col, Input, Button } from 'reactstrap';
import { useTranslate } from 'react-translate';
import { useSortBy, useTable, useAsyncDebounce, useGlobalFilter, useFilters } from 'react-table';
import moment from "moment";
import history from 'historyRouter';
import { actionGetDoctors } from 'store/actions/doctorsActions';
import { actionSetMessage } from 'store/actions/messagesActions';
import { actionSetUsersList } from 'store/actions/userDataActions';
//import DoctorDetail from './DoctorDetail';

const DoctorListComponent = () => {
    const t = useTranslate('doctors');
    const dispatch = useDispatch();
    const doctors = useSelector(state => state.doctorsReducer.doctors);
    //const practice = useSelector(state => state.practicesReducer.practice);

    //const [selectedPatient, setSelectedPatient] = useState(null);

    useEffect(() => {
        dispatch(actionGetDoctors());
        //eslint-disable-next-line
    }, []);

    const GlobalFilter = ({
        preGlobalFilteredRows,
        globalFilter,
        setGlobalFilter,
    }) => {
        const [value, setValue] = React.useState(globalFilter)
        const onChange = useAsyncDebounce(value => {
            setGlobalFilter(value || undefined);
            document.getElementById("doctorsFilter").focus();
        }, 10)

        return (
            <Input
                type="text"
                id="doctorsFilter"
                placeholder={t('search')}
                value={value || ""}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
            />
        )
    }


    const data = useMemo(
        () => doctors && doctors.length ? doctors.map((p, i) => {

            const doctorsData = {
                c1: p.title,
                c2: p.firstName,
                c3: p.lastName,
                c5: <Button
                    color="info"
                    type="button"
                    onClick={e => {
                        dispatch(actionSetUsersList([{ ...p }]));
                        dispatch(actionSetMessage({
                            message: {
                                from: {
                                    auth_user_id: p.auth_user_id,
                                    firstName: p.firstName,
                                    lastName: p.lastName
                                },
                                body: '',
                                thread: []
                            }
                        }));
                        history.push(`/admin/inbox/reply`);

                    }}>
                    <i className="now-ui-icons icon-send"></i>
                </Button>
            };

            return doctorsData;
        }) : [],
        //eslint-disable-next-line
        [doctors, t]
    );

    const columns = useMemo(
        () => [
            // {
            //     Header: `ID`,
            //     accessor: "c1",
            //     defaultCanSort: true
            // },
            {
                Header: `${t('title')}`,
                accessor: "c1",
                defaultCanSort: true
            },
            {
                Header: `${t('name')}`,
                accessor: "c2",
                defaultCanSort: true
            },
            {
                Header: `${t('last-name')}`,
                accessor: "c3",
                defaultCanSort: true
            },
            {
                accessor: "c5",
                disableSortBy: true,
            },
        ],
        //eslint-disable-next-line
        [doctors, t]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        state,
        prepareRow,
        setGlobalFilter,
        preGlobalFilteredRows
    } = useTable({ columns, data, initialState: { hiddenColumns: ["c7"] } }, useFilters, useGlobalFilter, useSortBy);


    //if (selectedPatient !== null) {
    //    return (
    //        <DoctorDetail p={selectedPatient} setSelectedPatient={setSelectedPatient} />
    //    )
    //};
    return (
        <>
            <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
            />

            <div className="mobile-x-overflow">
                <Table {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th
                                        className={`session-table-header`}
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                    >
                                        {column.render('Header')}

                                        {["c1", "c2", "c3"].includes(column.id) && (
                                            <span>
                                                <b className={`${column.isSorted ? column.isSortedDesc ? "caret-desc" : "caret-asc" : "caret-default"}`}></b>
                                            </span>
                                        )}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map(row => {
                            prepareRow(row)
                            return (
                                <tr>
                                    {row.cells.map(cell => {
                                        return (
                                            <td
                                                {...cell.getCellProps()}
                                            >
                                                {cell.render('Cell')}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
        </>
    );
};

export default DoctorListComponent;