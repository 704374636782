import { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { useTranslate } from 'react-translate';
import PushNotificationsService from 'services/PushNotificationsService';

const pushNotificationSupported =
  PushNotificationsService.isPushNotificationSupported();

const usePushNotifications = () => {
  const t = useTranslate('settings');
  const isMounted = useRef(null);
  const [userConsent, setSuserConsent] = useState('Notification' in window ? window.Notification.permission : false);
  //to manage the user consent: Notification.permission is a JavaScript native function that return the current state of the permission
  //We initialize the userConsent with that value
  const [userSubscription, setUserSubscription] = useState(null);
  //to manage the use push notification subscription
  const [pushServerSubscriptionId, setPushServerSubscriptionId] = useState();
  //to manage the push server subscription
  const [error, setError] = useState(null);
  //to manage errors
  const [loading, setLoading] = useState(true);
  //to manage async actions

  useEffect(() => {
    isMounted.current = true;
    if (pushNotificationSupported && isMounted.current) {
      setLoading(true);
      setError(false);
      PushNotificationsService.registerServiceWorker()
        .then(() => {
          if (isMounted.current) {
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log('ERROR', error.message);
        });
    }
    return () => {
      isMounted.current = false;
    };
  }, []);
  //if the push notifications are supported, registers the service worker
  //this effect runs only the first render

  useEffect(() => {
    if (isMounted.current) {
      setLoading(true);
      setError(false);
      const getExixtingSubscription = async () => {
        const existingSubscription =
          await PushNotificationsService.getUserSubscription();
        if (isMounted.current) {
          setUserSubscription(existingSubscription);
          setLoading(false);
        }
      };
      getExixtingSubscription();
    }
    return () => {
      isMounted.current = false;
    };
  }, []);
  //Retrieve if there is any push notification subscription for the registered service worker
  // this use effect runs only in the first render

  /**
   * define a click handler that asks the user permission,
   * it uses the setSuserConsent state, to set the consent of the user
   * If the user denies the consent, an error is created with the setError hook
   */
  const askUserPermission = () => {
    setLoading(true);
    setError(false);
    PushNotificationsService.askUserPermission().then((consent) => {
      setSuserConsent(consent);
      if (consent === 'granted') {
        susbribeToPushNotification();
      }
      if (consent !== 'granted') {
        setError({
          name: 'Consent denied',
          message: t('consent-denied'),
          code: 0,
        });
      }
      setLoading(false);
    });
  };

  /**
   * define a click handler that creates a push notification subscription.
   * Once the subscription is created, it uses the setUserSubscription hook
   */
  const susbribeToPushNotification = () => {
    setLoading(true);
    setError(false);
    PushNotificationsService.createNotificationSubscription()
      .then(function (subscription) {
        sendSubscriptionToPushServer(subscription);
        setUserSubscription(subscription);
        toast.success(t("subscription-success"));
        setLoading(false);
      })
      .catch((err) => {
        console.error(
          "Couldn't create the notification subscription",
          err,
          'name:',
          err.name,
          'message:',
          err.message,
          'code:',
          err.code
        );
        setError(err);
        setLoading(false);
      });
  };

  /**
   * define a click handler that sends the push susbcribtion to the push server.
   * Once the subscription ics created on the server, it saves the id using the hook setPushServerSubscriptionId
   */
  const sendSubscriptionToPushServer = (subscription = null) => {
    setLoading(true);
    setError(false);
    PushNotificationsService.pushSusbcribe(
      subscription ? subscription : userSubscription
    )
      .then(function (response) {
        setPushServerSubscriptionId(response.data.id);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  };

  /**
   * returns all the stuff needed by a Component
   */
  return {
    askUserPermission,
    susbribeToPushNotification,
    sendSubscriptionToPushServer,
    pushServerSubscriptionId,
    userConsent,
    pushNotificationSupported,
    userSubscription,
    error,
    loading,
  };
};
export default usePushNotifications;
