import AxiosService from "plugins/TS-lib-utils-public/services/AxiosService";

class Profiles extends AxiosService {
  constructor() {
    super("users");
  }
  getProfile = async (userId) => {
    const response = await this.get(`/get-full-data/${userId}`, {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
    });
    return response.data;
  };

  add = async (userId, profile) => {
    let formData = new FormData();
    Object.keys(profile).forEach((key) => {
      if (profile[key] !== 'avatar_input') {
        formData.append(key, profile[key]);
      }
    });
    /**
     * Attach file
     */
    if (profile.avatar_input) {
      formData.append('avatar', profile.avatar_input);
    }

    const response = await this.post(`/`, formData, {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
    });
    return response;
  };

  edit = async (userId, profile) => {
    let formData = new FormData();
    Object.keys(profile).forEach((key) => {
      if (key !== 'avatar_input' && key !== 'shortname_input' && !key.includes("company") && !key.includes("address")) {
        return formData.append(key, profile[key]);
      }
      /**
       * Attach file
       */
      if (key === "avatar_input") {
        return formData.append('avatar', profile.avatar_input);
      }

      /**
       * Attach shortname
       */
      if (key === "shortname_input") {
        return formData.append('nameInitials', profile.shortname_input);
      }

      /**
       * Attach company data
       */
      if (key === "company") {
        return formData.append("company", JSON.stringify(profile.company));
      }
    });
    
    const response = await this.put(`/${userId}`, formData, {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
    });
    return response;
  };
  /**
   * Add address
   *
   * @memberof Profiles
   */
  addAddress = async (address) => {
    const response = await this.post(`/address/`, address, {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
    });
    return response;
  };
  /**
   * Edit address
   *
   * @memberof Profiles
   */
  editAddress = async (address) => {
    const response = await this.put(`/address/${address._id}`, address, {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
    });
    return response;
  };
  /**
   * Delete address
   *
   * @memberof Profiles
   */
  deleteAddress = async (id) => {
    const response = await this.delete(`/address/${id}`, {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
    });
    return response;
  };
}

export default new Profiles();
