import AxiosService from "./AxiosService";

class HelpService extends AxiosService {
    constructor() {
        super("management");
    }

    getKeyList = async (system, limit = 5) => {
        try {
            let result = await this.get(`/list`, {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                },
                params: {
                    system,
                    limit
                }
            });

            return result;
        } catch (error) {
            return {
                status: error.response.status,
                statusText: error.response.statusText,
            };
        }
    };

    getLanguages = async (system, key) => {
        try {
            let result = await this.get(`/keys/list`, {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                },
                params: {
                    system,
                    key,
                }
            });

            return result;
        } catch (error) {
            return {
                status: error.response.status,
                statusText: error.response.statusText,
            };
        }
    };

    getKeyData = async (system, key) => {
        try {
            let result = await this.get(`/`, {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                },
                params: {
                    system,
                    key
                }
            });
            
            return result;
        } catch (error) {
            return {
            status: error.response.status,
            statusText: error.response.statusText,
            };
        }
    };

}

export default new HelpService();