import React from "react";
import PropTypes from "prop-types";

const Image = (props) => {
  return (
    <img
      className={props.className}
      src={props.file}
      width={props.width !== undefined ? props.width : "30px"}
      title={props.title}
      alt={props.alt ? props.alt : "..."}
      {...props}
    />
  );
};

Image.propTypes = {
  file: PropTypes.string,
};

export default Image;
