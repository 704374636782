/*!

=========================================================
* Now UI Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
// react plugin used to create a form with multiple steps
import ReactWizard from "react-bootstrap-wizard";

// // reactstrap components
import { Col, Row } from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";

import StepStudy from "./StepStudy.jsx";
import StepQuestionnary from "./StepQuestionnary.jsx";
import StepPayment from "./StepPayment.jsx";
import StepShipping from "./StepShipping.jsx";

var steps = [
  {
    stepName: "Study",
    stepIcon: "fas fa-edit",
    component: StepStudy,
  },
  {
    stepName: "Questionnary",
    stepIcon: "fas fa-question-circle",
    component: StepQuestionnary,
  },
  {
    stepName: "Shipping",
    stepIcon: "fas fa-shipping-fast",
    component: StepShipping,
  },
  {
    stepName: "Payment",
    stepIcon: "fas fa-credit-card",
    component: StepPayment,
  },
];

class Wizard extends Component {
  render() {
    return (
      <React.Fragment>
        <PanelHeader size="sm" />
        <div className="content pb-0">
          <Row>
              <Col sm={12} lg={10} className="mr-auto ml-auto">
              <ReactWizard
                steps={steps}
                navSteps
                validate
                title="Booking your device"
                description="Complete the information on the form to request your device or test for remote diagnosis"
                headerTextCenter
                color="blue"
                finishButtonClasses="btn-wd"
                nextButtonClasses="btn-wd"
                previousButtonClasses="btn-wd"
              />
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default Wizard;
