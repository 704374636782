// import countries from "world_countries_lists/data/en/countries.json";
/**
 * Returns a list of countries based on language preference per active user
 */

import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

const useCountriesByLang = (format = null) => {
  const user = useSelector((state) => state.userReducer);
  const [countries, setCountries] = useState([]);
  const language = user.language ? user.language : "en";

  const loadCountries = useCallback(async () => {
    try {
      return await import(
        //`world_countries_lists/data/${language}/countries.json`
        `../assets/countries_list/${language}/countries.json`
      );
    } catch (error) {
      //return await import(`world_countries_lists/data/en/countries.json`);
      return await import(`../assets/countries_list/${language}/countries.json`);
    }
  }, [language]);

  useEffect(() => {
    loadCountries().then((countries) => {
      let data = Object.values(countries);
      if (format === "react-select") {
        data = Object.values(countries).map((country) => ({
          value: country.id,
          label: country.name,
        }));
      }
      setCountries(data);
    });
  }, [language, format, loadCountries]);
  return countries;
};

export default useCountriesByLang;
