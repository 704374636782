import React, { Fragment, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { translate } from 'react-translate';
import { ListGroup } from 'reactstrap';
//Actions
import {
  actionGetMessages,
  actionOutboxGetMessages,
  actionGetPriorityMessages,
  actionSetSelectedMessages,
  actionGetMessagesScroll,
} from 'store/actions/messagesActions';

import InboxViewListItem from './InboxViewListItem';

import InboxMobileViewListItem from './InboxMobileViewListItem';
// import { actionSetBox } from 'store/actions/messagesActions';

const InboxViewList = ({ mobile }) => {
  const dispatch = useDispatch();

  const messageListRef = useRef();

  const { inbox, outbox, prioritybox, loading, box } = useSelector(
    (state) => state.messagesReducer
  );

  /**
   * Starter
   */
  useEffect(() => {
    dispatch(actionGetMessages());
    dispatch(actionOutboxGetMessages());
    dispatch(actionGetPriorityMessages());
    dispatch(actionSetSelectedMessages([]));
  }, [dispatch, box]);
  /**
   * Scrolling
   */
  useEffect(() => {
    let inboxR = messageListRef;
    if (inboxR.current) {
      inboxR.current.scrollTop = 0;
    }
  }, [box]);

  /**
   * For scrolling
   */
  useEffect(() => {
    const infinityScroll = (e) => {
      if (
        Math.round(e.target.offsetHeight + e.target.scrollTop) >=
        e.target.scrollHeight
      ) {
        const searcher = document.getElementById('inbox-searcher');
        const search = searcher && searcher.value ? searcher.value : null;
        dispatch(actionGetMessagesScroll(search));
      }
    };
    let inboxR = messageListRef;
    if (inboxR.current) {
      inboxR.current.addEventListener('scroll', infinityScroll);
    }
    return () => {
      if (inboxR.current) {
        inboxR.current.removeEventListener('scroll', infinityScroll);
      }
    };
  }, [dispatch, inbox, box]);

  // if (loading) {
  //   return <LoadingLocal />;
  // }

  if (box !== 'inbox' && box !== 'outbox' && box !== 'priority') {
    return <></>;
  }

  return (
    <Fragment>
      <div ref={messageListRef} className="inbox-view-list overflow-auto">
        {loading === false && inbox && inbox.length === 0 && (
          <div className="alert alert-danger">Don't you have messages</div>
        )}
        <ListGroup className="inbox-list-container mb-2">
          {box === 'inbox' &&
            inbox &&
            inbox.messages &&
            inbox.messages.length > 0 &&
            inbox.messages.map((item, key) => {
              return mobile ? (
                <InboxMobileViewListItem item={item} key={key} />
              ) : (
                <InboxViewListItem item={item} key={key} />
              );
            })}
          {box === 'outbox' &&
            outbox &&
            outbox.messages &&
            outbox.messages.length > 0 &&
            outbox.messages.map((item, key) => {
              return (
                <InboxViewListItem item={item} key={key} mobile={mobile} />
              );
            })}
          {box === 'priority' &&
            inbox &&
            prioritybox.messages &&
            prioritybox.messages.length > 0 &&
            prioritybox.messages.map((item, key) => {
              return (
                <InboxViewListItem item={item} key={key} mobile={mobile} />
              );
            })}
        </ListGroup>
      </div>
    </Fragment>
  );
};

export default translate('inbox')(InboxViewList);
