import React, { useEffect, useRef } from 'react';
import { Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useTranslate } from 'react-translate';
import AsyncSelect from 'react-select/async';
import { actionSetUsersList } from 'store/actions/userDataActions';
import PatientsService from "services/PatientsService";

const InboxAsyncRecipients = ({ register, control, focus }) => {
  const t = useTranslate('messages');
  const dispatch = useDispatch();
  const recipientsField = useRef();

  const customStyles = {
    control: (provided, state) => {
      const borderColor = state.isFocused ? '#fd7e14 !important' : '#e3e3e3';
      return {
        ...provided,
        backgroundColor: 'transparent',
        border: `1px solid ${borderColor}`,
        borderRadius: '30px',
        color: '#2c2c2c',
        lineHeight: 'normal',
        height: 'auto',
        fontSize: '0.8571em',
        transition:
          'color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out',
        boxShadow: 'none',
      };
    },
    indicatorsContainer: (provided) => {
      return {
        ...provided,
        display: 'none',
      };
    },
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    },
  };

  const loadRecipients = async (query) => {
    if (query) {
      try {
        const result = await PatientsService.searchDoctor(query);
        if (!result.data || result.data.length <= 0) {
          throw new Error('No options');
        }
        const list = result.data.map((user) => {
          return {
            label: `${user.title} ${user.firstName} ${user.lastName} (${user.practice.name})`,
            value: user.auth_user_id,
          };
        });
        return new Promise((resolve) => {
          dispatch(actionSetUsersList(result.data));
          resolve(list);
        });
      } catch (error) {
        return new Promise((resolve) => {
          dispatch(actionSetUsersList([]));
          resolve([]);
        });
      }
    }
  };

  useEffect(() => {
    if (focus) {
      recipientsField.current.focus();
    }
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <input type="hidden" name="isNew" ref={register} defaultValue={true} />
      <Controller
        as={
          <AsyncSelect
            //isMulti
            cacheOptions
            loadOptions={loadRecipients}
            styles={customStyles}
            isLoading={true}
            noOptionsMessage={() => t('recipients-not-found')}
            ref={recipientsField}
          />
        }
        control={control}
        name="to"
        placeholder={`${t('recipients')}`}
      />
    </>
  );
};

export default InboxAsyncRecipients;
