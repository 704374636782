import React from 'react';
import { useTranslate } from 'react-translate';
import { Button } from 'reactstrap';
const BtnNew = ({ mobile, setShowForm, handleNewMessage }) => {
  const t = useTranslate('messages');
  return (
    <Button
      className="btn-new"
      color="info"
      name="btn-menu-new"
      onClick={(e) => {
        handleNewMessage();
        setShowForm(true);
      }}
    >
      {!mobile && t('btn-new')} <i className="fas fa-plus"></i>
    </Button>
  );
};

export default BtnNew;
