//Page for edition user data

import React from 'react';
// reactstrap components
import { Card, CardHeader, CardBody, CardTitle } from 'reactstrap';
import { useTranslate } from 'react-translate';
import DoctorListComponent from 'components/Doctors/DoctorListComponent';

const DoctorList = () => {
    const t = useTranslate('doctors');
    return (
        <>
            {/*<PanelHeader size="sm" /> */}

                    <Card className="mb-0 profile">

                        <CardHeader>
                            <CardTitle className="d-flex align-content-center">
                                <div className="general-card-title-img text-primary mr-2">
                                    <i className="icon-user-active" />
                                </div>
                                <div className="general-card-title text-primary">
                                    {t('doctors')}
                                </div>
                            </CardTitle>
                        </CardHeader>

                        <CardBody>
                            <DoctorListComponent />
                        </CardBody>
                    </Card>

        </>
    );
};

export default DoctorList;