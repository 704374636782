import moment from "moment";
class MomentjsI18n {
  constructor() {
    this.lang = "de";
    this.format = "YYYY/MM/DD";
    moment.updateLocale(this.lang, this[this.lang]);
  }

  setFormat = (format) => {
    this.format = format;
    moment.updateLocale(this.lang, this[this.lang]());
  };

  setLang = (lang) => {
    moment.updateLocale(lang, this[lang]());
  };

  en = () => {
    return {
      longDateFormat: {
        L: this.format,
      },
    };
  };

  de = () => {
    return {
      months: "Januar_Februar_März_April_Mai_Juni_Juli_August_September_Oktober_November_Dezember".split(
        "_"
      ),
      monthsShort: "Jan._Feb._März_Apr._Mai_Juni_Juli_Aug._Sept._Okt._Nov._Dez.".split(
        "_"
      ),
      monthsParseExact: true,
      weekdays: "dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi".split(
        "_"
      ),
      weekdaysShort: "dim._lun._mar._mer._jeu._ven._sam.".split("_"),
      weekdaysMin: "Di_Lu_Ma_Me_Je_Ve_Sa".split("_"),
      weekdaysParseExact: true,
      longDateFormat: {
        LT: "HH:mm",
        LTS: "HH:mm:ss",
        L: this.format,
        LL: "D MMMM YYYY",
        LLL: "D MMMM YYYY HH:mm",
        LLLL: "dddd D MMMM YYYY HH:mm",
      },
      calendar: {
        sameDay: "[Heute um] LT",
        nextDay: "[Morgen um] LT",
        nextWeek: "dddd [um] LT",
        lastDay: "[Gestern um] LT",
        lastWeek: "dddd [bestanden] LT",
        sameElse: "L",
      },
      relativeTime: {
        future: "innerhalb von %s",
        past: "vor %s",
        s: "ein paar sekunden",
        m: "einer minute",
        mm: "%d minuten",
        h: "einer stunde",
        hh: "%d stunden",
        d: "einem tag",
        dd: "%d tagen",
        M: "einem monat",
        MM: "%d monaten",
        y: "einem jahr",
        yy: "%d jahren",
      },
      dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
      ordinal: function (number) {
        return number + (number === 1 ? "er" : "e");
      },
      meridiemParse: /PD|MD/,
      isPM: function (input) {
        return input.charAt(0) === "M";
      },
      // In case the meridiem units are not separated around 12, then implement
      // this function (look at locale/id.js for an example).
      // meridiemHour : function (hour, meridiem) {
      //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
      // },
      meridiem: function (hours, minutes, isLower) {
        return hours < 12 ? "PD" : "MD";
      },
      week: {
        dow: 1, // Monday is the first day of the week.
        doy: 4, // Used to determine first week of the year.
      },
    };
  };

  fr = () => {
    return {
      months: "janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre".split(
        "_"
      ),
      monthsShort: "janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.".split(
        "_"
      ),
      monthsParseExact: true,
      weekdays: "dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi".split(
        "_"
      ),
      weekdaysShort: "dim._lun._mar._mer._jeu._ven._sam.".split("_"),
      weekdaysMin: "Di_Lu_Ma_Me_Je_Ve_Sa".split("_"),
      weekdaysParseExact: true,
      longDateFormat: {
        LT: "HH:mm",
        LTS: "HH:mm:ss",
        L: this.format,
        LL: "D MMMM YYYY",
        LLL: "D MMMM YYYY HH:mm",
        LLLL: "dddd D MMMM YYYY HH:mm",
      },
      calendar: {
        sameDay: "[Aujourd’hui à] LT",
        nextDay: "[Demain à] LT",
        nextWeek: "dddd [à] LT",
        lastDay: "[Hier à] LT",
        lastWeek: "dddd [dernier à] LT",
        sameElse: "L",
      },
      relativeTime: {
        future: "dans %s",
        past: "il y a %s",
        s: "quelques secondes",
        m: "une minute",
        mm: "%d minutes",
        h: "une heure",
        hh: "%d heures",
        d: "un jour",
        dd: "%d jours",
        M: "un mois",
        MM: "%d mois",
        y: "un an",
        yy: "%d ans",
      },
      dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
      ordinal: function (number) {
        return number + (number === 1 ? "er" : "e");
      },
      meridiemParse: /PD|MD/,
      isPM: function (input) {
        return input.charAt(0) === "M";
      },
      // In case the meridiem units are not separated around 12, then implement
      // this function (look at locale/id.js for an example).
      // meridiemHour : function (hour, meridiem) {
      //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
      // },
      meridiem: function (hours, minutes, isLower) {
        return hours < 12 ? "PD" : "MD";
      },
      week: {
        dow: 1, // Monday is the first day of the week.
        doy: 4, // Used to determine first week of the year.
      },
    };
  };

  ru = () => {
    return {
      months: "Январь_Февраль_Март_Апрель_Май_Июнь_Июль_Август_Сентябрь_Октябрь_Ноябрь_Декабрь".split(
        "_"
      ),
      monthsShort: "Янв._Фев._Мар._Апр._May_Июн._Июл._Авг._Сен._Окт._Ноя._Дек.".split(
        "_"
      ),
      monthsParseExact: true,
      weekdays: "dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi".split(
        "_"
      ),
      weekdaysShort: "dim._lun._mar._mer._jeu._ven._sam.".split("_"),
      weekdaysMin: "Di_Lu_Ma_Me_Je_Ve_Sa".split("_"),
      weekdaysParseExact: true,
      longDateFormat: {
        LT: "HH:mm",
        LTS: "HH:mm:ss",
        L: this.format,
        LL: "D MMMM YYYY",
        LLL: "D MMMM YYYY HH:mm",
        LLLL: "dddd D MMMM YYYY HH:mm",
      },
      calendar: {
        sameDay: "[cегодня в] LT",
        nextDay: "[завтра в] LT",
        nextWeek: "dddd [в] LT",
        lastDay: "[вчера в] LT",
        lastWeek: "dddd [прошел в] LT",
        sameElse: "L",
      },
      relativeTime: {
        future: "в течение %s",
        past: "%s назад",
        s: "несколько секунд",
        m: "минуту",
        mm: "%d минуты",
        h: "час",
        hh: "%d часа",
        d: "День",
        dd: "%d дней",
        M: "один месяц",
        MM: "%d месяцев",
        y: "год",
        yy: "%d лет",
      },
      dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
      ordinal: function (number) {
        return number + (number === 1 ? "er" : "e");
      },
      meridiemParse: /утра|вечера/,
      isPM: function (input) {
        return input.charAt(0) === "в";
      },
      // In case the meridiem units are not separated around 12, then implement
      // this function (look at locale/id.js for an example).
      // meridiemHour : function (hour, meridiem) {
      //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
      // },
      meridiem: function (hours, minutes, isLower) {
        return hours < 12 ? "утра" : "вечера";
      },
      week: {
        dow: 1, // Monday is the first day of the week.
        doy: 4, // Used to determine first week of the year.
      },
    };
  };

  es = () => {
    return {
      months: "enero_febrero_marzo_abril_mayo_junio_julio_agosto_septiembre_octubre_noviembre_diciembre".split(
        "_"
      ),
      monthsShort: "ene._feb._mar_abr._may_jun_jul._ago_sep._oct._nov._dec.".split(
        "_"
      ),
      monthsParseExact: true,
      weekdays: "domingo_lunes_martes_miércoles_jueves_viernes_sábado".split(
        "_"
      ),
      weekdaysShort: "dom._lun._mar._mie._jue._vie._sab.".split("_"),
      weekdaysMin: "Do_Lu_Ma_Mi_Ju_Vi_Sa".split("_"),
      weekdaysParseExact: true,
      longDateFormat: {
        LT: "HH:mm",
        LTS: "HH:mm:ss",
        L: this.format,
        LL: "D MMMM YYYY",
        LLL: "D MMMM YYYY HH:mm",
        LLLL: "dddd D MMMM YYYY HH:mm",
      },
      calendar: {
        sameDay: "[Hoy a las] LT",
        nextDay: "[Mañana] LT",
        nextWeek: "dddd [a las] LT",
        lastDay: "[Ayer a las] LT",
        lastWeek: "dddd [pasado a las] LT",
        sameElse: "L",
      },
      relativeTime: {
        future: "dentro de %",
        past: "Hace %s",
        s: "unos segundos",
        m: "un minuto",
        mm: "%d minutos",
        h: "una hora",
        hh: "%d horas",
        d: "Un día",
        dd: "%d días",
        M: "un mes",
        MM: "%d meses",
        y: "un año",
        yy: "%d años",
      },
      dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
      ordinal: function (number) {
        return number + (number === 1 ? "er" : "e");
      },
      meridiemParse: /PD|MD/,
      isPM: function (input) {
        return input.charAt(0) === "M";
      },
      // In case the meridiem units are not separated around 12, then implement
      // this function (look at locale/id.js for an example).
      // meridiemHour : function (hour, meridiem) {
      //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
      // },
      meridiem: function (hours, minutes, isLower) {
        return hours < 12 ? "AM" : "PM";
      },
      week: {
        dow: 1, // Monday is the first day of the week.
        doy: 4, // Used to determine first week of the year.
      },
    };
  };
}

export default new MomentjsI18n();
