import React, { useState, useRef } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import history from 'historyRouter';
import moment from 'moment';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { tokenName } from "configs/app.json";
import { getSex } from 'services/SexPicker';
import { useParams } from 'react-router-dom';
import { translate } from "react-translate";
const CryptoJS = require("crypto-js");

const IFrameComponent = () => {
    const baseUrl = "https://termin-service.org/ifr_manager.php";
    const [controlUrl, setControlUrl] = useState('');
    
    const { doctor } = useParams();

    //Modal state
    const [modal, setModal] = useState(false);
    //const [redirection, setRedirection] = useState("");

    const toggleModal = () => {
        setModal(!modal);
    }

    const user = useSelector((state) => state.userReducer);
    const { profile } = useSelector((state) => state.profilesReducer);

    const t = translate('iFrameComponent');

    //const referencia = useRef();

    useEffect(() => {
        if (user && user.auth_user_id && Object.keys(profile).length > 0) {
            const data = {
                tpId: user.auth_user_id,
                pifsId: user.patient_pifs_id ? user.patient_pifs_id : "",
                PatEmail: user.auth_email,
                PatVorname: user.firstName,
                PatNachname: user.lastName,
                PatTitle: user.title ? user.title : '',
                PatAnrede: getSex(user.gender),
                PatSex: getSex(user.gender),
                PatBirth: user.birthday ? user.birthday : '',
                PatOrt: profile.address?.length ? profile.address[0].city : '',
                PatPLZ: profile.address?.length ? profile.address[0].postalCode : '',
                PatStr: profile.address?.length ? profile.address[0].street : '',
                patHouse: profile.address?.length ? profile.address[0].streetNumber : '',
                PhoneNum: user.phone,
                PatInsName: profile.company?.name,
                PatInsType: profile.company?.type === "Private" ? 1 : profile.company?.type === 'Public' ? 2 : 0,
                PatPhoneArt: user.phone //mobile, land net
            };

            let encrypted = CryptoJS.AES.encrypt(
                JSON.stringify(data),
                CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_KEY),
                { iv: CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_IV), mode: CryptoJS.mode.CBC }
            ).toString();

            encrypted = encrypted.replace(/\/]/g, '_').replace(/[+]/g, '-');

            if (doctor && doctor !== ":doctor") {
                setControlUrl(`https://termin-service.org/ifr_s0_ta.php?base=${encrypted}&fapifsid=${doctor}`)
            } else {
                setControlUrl(`${baseUrl}?base=${encrypted}`);
            }
        }
    }, [user, profile]);

    /*

    const unblock = history.block((tx) => {
        //Check if the next redirection is not logout
        if (referencia.current && !modal && localStorage.getItem(tokenName) && profile.is_ready) {
            setRedirection(tx.pathname);
            setModal(true);
        }
        return (!referencia.current || !localStorage.getItem(tokenName) || !profile.is_ready);
    });

    useEffect(() => {
        referencia.current = true;
        return () => {
            unblock()
            referencia.current = false
        }
    }, []);
    */

    return (
        <>
            {/*
        <Row>
            <Col sm="6">
                <Input
                    type='text'
                    id="url-input"
                    onChange={e => {
                        setUrl(e.target.value);
                    }}
                    defaultValue={controlUrl}
                />
            </Col>
            <Col sm="6">
                <Button
                    size="sm" color="info" 
                    onClick={e => {
                        e.preventDefault();
                        setControlUrl(url);
                    }}
                >
                    Update
                </Button>
            </Col>
        </Row>
                */}
            <iframe src={controlUrl} className="iframe-demo">
                <p>{t('not-supported')}</p>
            </iframe>

            <Modal isOpen={modal} toggle={toggleModal}>
                <ModalBody>{`Ihre Daten werden nicht gespeichert, wenn Sie die Seite verlassen.`}</ModalBody>
                <ModalFooter>
                    <Button color="info" onClick={() => {
                        toggleModal();
                        //unblock();
                        //history.push(redirection);
                    }}>
                        {"Seite verlassen"}
                    </Button>{' '}
                    <Button color="secondary" onClick={() => {
                        toggleModal();
                    }}>
                        {"Auf Seite bleiben"}
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
};

export default translate('iFrameComponent')(IFrameComponent);
