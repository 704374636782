import React, { Fragment } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import NotesHeader from "../../components/Notes/NotesHeader";
import NotesBody from "../../components/Notes/NotesBody";

const Notes = () => {
  return (
    <Fragment>
      <div className="content">
        <Card>
          <CardBody>
            <Row>
              <Col>
                <NotesHeader />
              </Col>
            </Row>
            <Row>
              <Col>
                <NotesBody />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </Fragment>
  );
};

export default Notes;
