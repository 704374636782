import React from "react";
import { Button } from "reactstrap";
import { translate } from "react-translate";

function AppointmentsNext() {
  const t = translate('appointments-next');
  return (
    <div className="appointments-next">
      <Button color="info" block outline>
        {t('next-appointment')}
      </Button>
    </div>
  );
}

export default translate('appointments-next')(AppointmentsNext);