/*!

=========================================================
* Now UI Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Row, Col, Card, CardHeader, CardTitle, CardBody } from "reactstrap";
import AppointmentsCalendar from "./AppointmentsCalendar";
import AppointmentsDoctor from "./AppointmentsDoctor";
import Icon from "components/Icons/Icon";
import ContactsHotizontal from "../Contacts/ContactsHorizontal";
import { translate } from "react-translate";

const AppointmentsMyDoctors = (props) => {
  const t = translate('appointments-my-doctors');
  return (
    <Card className={"animated fast fadeIn" + props.className}>
      <CardHeader>
        <CardTitle tag="h2" className="text-danger">
          <Icon file="icons/appointments.svg" className="mr-2" />
          <small>{t('filter-by-doctors')}</small>
        </CardTitle>
      </CardHeader>
      <CardBody>
        <ContactsHotizontal />
        <Row>
          <Col md="12" lg="3">
            <AppointmentsDoctor notes dateChecked="16/12/2019 09:45 HS." />
          </Col>
          <Col md="12" lg="9" className="appointments-calendar-container">
            <AppointmentsCalendar />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default translate('appointments-my-doctors')(AppointmentsMyDoctors);