/*!

=========================================================
* Now UI Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, Fragment } from "react";
import moment from "moment";
//Components
import { Calendar, momentLocalizer } from "react-big-calendar";
import CustomToolbar from "components/CustomCalendar/CustomToolbar";
import CustomHeaderMonth from "components/CustomCalendar/CustomHeaderMonth";
import CustomHeaderWeek from "components/CustomCalendar/CustomHeaderWeek";
import CustomEventWrapperWeek from "components/CustomCalendar/CustomEventWrapperWeek";
import CustomEventWrapperMonth from "components/CustomCalendar/CustomEventWrapperMonth";
//Data faker
import { events } from "variables/general";
import AppointmentsNext from "./AppointmentsNext";

const localizer = momentLocalizer(moment);

const AppointmentsCalendar = () => {
  const [eventsThisMonth, setEventsThisMonth] = useState(null);

  const countEvents = (range) => {
    let thisMonth = events.filter((event) => {
      let eventStart = moment(event.start);
      let between = eventStart.isBetween(range.start, range.end);
      return between ? event : null;
    });
    setEventsThisMonth(thisMonth.length);
  };

  if (eventsThisMonth === null) {
    let range = {
      start: moment().date(1),
      end: moment().add(1, "months").date(0),
    };
    countEvents(range);
  }

  return (
    <Fragment>
      {eventsThisMonth === 0 && <AppointmentsNext />}
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        views={{ month: true, week: true }}
        step={30}
        timeslots={1}
        min={new Date(0, 0, 0, 15, 0, 0)}
        max={new Date(0, 0, 0, 18, 0, 0)}
        onRangeChange={countEvents}
        components={{
          toolbar: CustomToolbar,
          header: CustomHeaderMonth,
          week: {
            header: CustomHeaderWeek,
            eventWrapper: CustomEventWrapperWeek,
          },
          month: {
            eventWrapper: CustomEventWrapperMonth,
          },
        }}
      />
    </Fragment>
  );
};

export default AppointmentsCalendar;
