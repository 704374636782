import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import StudiesList from 'components/Laboratories/StudiesList';
import HistoryList from "components/Laboratories/HistoryList";
import classnames from 'classnames';
import LoadingLocal from 'plugins/TS-lib-utils-public/components/Loading/LoadingLocal';
import { useSelector } from "react-redux";
import { useTranslate } from "react-translate";


const LaboratoryStudies = () => {
  const user = useSelector((state) => state.userReducer);
  const [consent, setConsent] = useState("loading");
  const [activeTab, setActiveTab] = useState('1');
  const t = useTranslate('laboratory-studies');

  useEffect(() => {
    setConsent(true)
  }, [])

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className="content dashboard">

          <Card>
            <CardHeader>
                <CardTitle className="d-flex align-content-center">
                    <div className="general-card-title-img text-primary mr-2">
                        <i className="icon-microscope" />
                    </div>
                    <div className="general-card-title text-primary">
                        {t('laboratory-studies')}
                    </div>
                </CardTitle>
            </CardHeader>

            {consent == "loading" ? <LoadingLocal /> : (
                <CardBody>
                  <Nav pills className="nav-pills-primary mb-4">
                    <NavItem className="profile-item">
                      <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        name="nav_studies_list"
                        onClick={() => {
                          toggle('1');
                        }}>
                        {t('my-studies')}
                      </NavLink>
                    </NavItem>
                    <NavItem className="profile-item">
                      <NavLink
                        className={classnames({ active: activeTab === '2' })}
                        name="nav_history_list"
                        onClick={() => {
                          toggle('2');
                        }}>
                        {t('history')}
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      {activeTab === '1' && (
                        <StudiesList />
                      )}
                    </TabPane>
                    <TabPane tabId="2">
                      {activeTab === '2' && (
                        <HistoryList />
                      )}
                    </TabPane>
                  </TabContent>
                </CardBody>
            )
            }

          </Card>

    </div>
  );
};

export default LaboratoryStudies;
