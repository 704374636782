import React from 'react'
import { useTranslate } from 'react-translate'
import { CardSubtitle, CardText, CardTitle } from 'reactstrap'

const TermsCardContent = () => {
    let tc = useTranslate("terms-card")
    return (
        <>
            <CardTitle tag="h3" className="d-flex justify-content-center text-info text-center">§1 Allgemeines</CardTitle>
            <CardText className="text-wrap text-break text-dark paragraph">
                Die TelePraxen Plattform, das Online-Portal für Medizin + Gesundheit, ist ein Service- und Informationsportal für Patienten und Ärzte, Zahnärzte, Therapeuten und Gesundheitseinrichtungen. Diese stellen sich und Ihre Leistungen in Ihrer Online-Praxis vor.
                <br />
                <br />
                Die TelePraxen APP dient dem sicheren Datenaustausch innerhalb und zwischen Gesundheitseinrichtungen, sowie zwischen Gesundheitseinrichtungen und Patienten.
                <br />
                <br />
                Die Buchungs-Online-System (BOS) bietet Patienten die Möglichkeit, über Online-Termine mit Ärzten, Zahnärzten, Therapeuten oder Gesundheitseinrichtungen zu vereinbaren.
                <br />
                <br />
                Die TelePraxen sind ein Projekt der
                <br /><br />
                {tc("GENERAL-P3")}<br />
                {tc("GENERAL-P4")}<br />
                {tc("GENERAL-P5")}<br />
                {tc("GENERAL-P6")}<br />
                {tc("GENERAL-P7", { VAT: "DE 179 221 234" })}<br />
                {tc("GENERAL-P8")} <a className="btn-link text-success p-0 m-0" href="mailto:info@telepraxen.de">info@telepraxen.de</a>
            </CardText>
            <CardTitle tag="h3" className="d-flex justify-content-center text-info text-center">{tc("2-SCOPE-TITLE")}</CardTitle>
            <CardSubtitle tag="h5" className="text-success mb-2 fw-bolder">{tc("SCOPE-SUBTITLE-1")}</CardSubtitle>
            <ul>
                <li>Die TelePraxen erbringen selber keine medizinischen Leistungen, sondern vermitteln Leistungsangebote von Ärzten, Zahnärzten, Therapeuten und Gesundheitseinrichtungen.</li>
                <li>Nicht medizinische Dienstleistungen werden von den TelePraxen gegenüber dem Nutzer erbracht.</li>
                <li>Leistungen können nur gegenüber Nutzern erbracht werden, die älter als 18 Jahre sind.</li>
            </ul>
            <CardSubtitle tag="h5" className="text-success mb-2 fw-bolder">
                Registrierung
            </CardSubtitle>
            <CardText>
                Die Registrierung für die TelePraxen Plattform ist nicht notwendig, wird aber empfohlen. Als registrierter Nutzer profitieren Sie von einem erleichterten Zugang zu den angebotenen Leistungen.
                <br /><br />
                Für die Nutzung der TelePraxen APP ist eine Registrierung Pflicht.
            </CardText>
            <CardSubtitle tag="h5" className="text-success mb-2 fw-bolder">Vertragsschluss und Kosten für Heilbehandlungen / ärztliche Leistungen</CardSubtitle>
            <CardText>
                Durch die verbindliche Bestellung einer nicht medizinischen Dienstleistung erfolgt ein Vertragsschluss zwischen Nutzer und TelePraxen.<br />
                <br />
                Die nicht medizinische Leistungen sind kostenfrei, sofern diese nicht gesondert als kostenpflichtig ausgewiesen werden.
                <br /><br />
                Jede kostenpflichtige Bestellung ist ein eigener Vertrag. Die anfallenden Kosten werden bei der Bestellung konkret beziffert und sind wie folgt fällig:
            </CardText>
            <ul>
                <li>Nicht registrierter Patient: Sofort bei Buchung über den internationalen Dienstleister PayPal (Visa Card, Master Card, Lastschriftverfahren PayPal-Konto).</li>
                <li>Registrierter Patient: Beratung / Behandlung gegen Rechnung</li>
            </ul>
            <CardText>
                Zugesandtes Diagnostikgeräte sind nur für den persönlichen Gebrauch bestimmt und bleiben Eigentum TelePraxen. Die TelePraxen beauftragen nach Nutzung des von Einmaldiagnostikgeräten den Nutzer mit der Entsorgung entsprechend den örtlichen Vorschriften. Mehrfach zu verwendende Diagnostikgeräte sind nach Nutzung zur hygienischen Aufbereitung den TelePraxen zurückzusenden.
            </CardText>
            <CardSubtitle tag="h5" className="text-success mb-2 fw-bolder">Vertragsschluss und Kosten für Heilbehandlungen / ärztliche Leistungen</CardSubtitle>
            <CardText>
                Durch die verbindliche Buchung einer ärztlichen Leistung erfolgt ein Vertragsschluss zwischen Patient und Arzt. Jede Buchung ist ein eigener Vertrag. Das Honorar richtet sich nach der Gebührenordnung für Ärzte (GOÄ), der Gebührenordnung für Zahnärzte (GOZ), bzw. der gesetzlichen Vorschriften der Therapeuten und der Gesundheitseinrichtungen. Die anfallenden Kosten werden bei der Buchung konkret beziffert und sind wie folgt fällig:
            </CardText>
            <ul>
                <li>{tc("SCOPE-LI-5")}</li>
                <li>Registrierter Patient: Beratung / Behandlung gegen Rechnung</li>
            </ul>
            Die Kosten für die ärztlichen Leistungen oder eine entsprechende Sicherheitsleistung (Anzahlung) werden vor Abschluss bei jeder Buchung kenntlich gemacht. Die Rechnungsstellung erfolgt durch den Arzt. Für die Erstellung der Rechnung kann der Arzt die TelePraxen und/oder eine privatärztliche Verrechnungsstelle beauftragen. Mit der verbindlichen Buchung willigt der Patient in die Verarbeitung der hierzu notwendigen Daten durch die TelePraxen und die Verrechnungsstelle ein. Die Mitarbeiter der TelePraxen und die Verrechnungsstelle unterliegen wie ein Arzt der Schweigepflicht und den Bestimmungen des Datenschutzes.
            <br /><br />
            Zugesandtes Diagnostik- und Therapiematerial ist nur für den persönlichen Gebrauch bestimmt und bleibt Dienstleisters. Der Dienstleister beauftragt nach Nutzung des Materials den Patienten mit der Entsorgung entsprechend den örtlichen Vorschriften.
            <br />
            <br />
            <CardSubtitle tag="h5" className="text-success mb-2 fw-bolder">{tc("SCOPE-SUBTITLE-5")}</CardSubtitle>
            <CardText>
                {tc("SCOPE-P6")} <a className="btn-link text-success p-0 m-0" href="https://telepraxen.de/infos/stornierungsbedingungen/" target='_blank'>{tc("CANCELLATION-POLICY")}</a>
            </CardText>
            <CardTitle tag="h3" className="d-flex justify-content-center text-info text-center">
                {tc("3-QUALITY-ASSURANCE-TITLE")}
            </CardTitle>
            <CardText>
                Im Rahmen der Qualitätssicherung der TelePraxen werden Patienten bzw. Nutzer stichprobenweise befragt. Die Teilnahme ist vertraulich und freiwillig. Konkrete Beschwerden richten Sie an <a className="btn-link text-success p-0 m-0" href="mailto:beschwerde@telepraxen.de">beschwerde@telepraxen.de</a>.
            </CardText>
            <CardTitle tag="h3" className="d-flex justify-content-center text-info text-center">{tc("4-CHANGES-TITLE")}</CardTitle>
            <CardText>
                {tc("CHANGES-P1")}
            </CardText>
            <CardTitle tag="h3" className="d-flex justify-content-center text-info text-center">{tc("5-TERMINATION-TITLE")}</CardTitle>
            <CardText>
                {tc("TERMINATION-P1")}<br /> <a className="btn-link text-success p-0 m-0" href="mailto:info@telepraxen.de">info@telepraxen.de</a>.
                <br />
                <br />
                {tc("TERMINATION-P2")}
            </CardText>
            <ul>
                <li>{tc("TERMINATION-LI-1")}</li>
                <li>{tc("TERMINATION-LI-2")}</li>
            </ul>
            <CardText>{tc("TERMINATION-P3")}</CardText>
            <CardTitle tag="h3" className="d-flex justify-content-center text-info text-center">§6 Datensicherung</CardTitle>
            <CardText>
                Wir übernehmen keine allgemeine Datensicherungsgarantie für die von Ihnen übermittelten Daten. Wir empfehlen in regelmäßigen Abständen Backups Ihrer Daten zu erstellen.
            </CardText>
            <CardTitle tag="h3" className="d-flex justify-content-center text-info text-center">§7 Haftungsvorbehalt</CardTitle>
            <CardText>
                Die Haftung beschränkt sich auf den vorhersehbaren, vertragstypischen Schaden.
            </CardText>
            <CardTitle tag="h3" className="d-flex justify-content-center text-info text-center">§8 Streitbeilegung</CardTitle>
            <CardText>
                Die EU-Kommission hat eine Internetplattform zur Online-Beilegung von Streitigkeiten geschaffen. Die Plattform dient als Anlaufstelle zur außergerichtlichen Beilegung von Streitigkeiten betreffend vertragliche Verpflichtungen, die aus Online-Kaufverträgen erwachsen. Nähere Informationen sind unter dem folgenden Link verfügbar:
                <br />
                <a className="btn-link text-success p-0 m-0" href="https://ec.europa.eu/consumers/odr" target='_blank'>https://ec.europa.eu/consumers/odr</a>. Zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle sind wir weder bereit noch verpflichtet.
            </CardText>
            <CardTitle tag="h3" className="d-flex justify-content-center text-info text-center">{tc("6-FINAL-PROVISIONS-TITLE")}</CardTitle>
            <CardText>
                {tc("FINAL-PROVISIONS-P1")}<br />
                {tc("FINAL-PROVISIONS-P2")}
                <br /><br />
                Gültig ab 24.11.2022
            </CardText>
        </>
    )
}

export default TermsCardContent;