import React, { Fragment } from "react";
import { Card, CardBody } from "reactstrap";

//components
import AppointmentsList from "components/Appointments/AppointmentsList";
import AppointmentsMyDoctors from "components/Appointments/AppointmentsMyDoctors";

const AppointmentsView = () => {
  return (
    <Fragment>
      <div className="content">
        <Card>
          <CardBody>
            <div className="card-deck row">
              <AppointmentsList className="col-sm-12 col-lg-4 pr-0 pl-0" />
              <AppointmentsMyDoctors className="col-sm-12 col-lg-8 pr-0 pl-0" />
            </div>
          </CardBody>
        </Card>
      </div>
    </Fragment>
  );
};

export default AppointmentsView;
