import AxiosService from "plugins/TS-lib-utils-public/services/AxiosService";

class UsersService extends AxiosService {
  constructor() {
    super("notifications");
  }
  getNotifications = async (module = null) => {
    const response = await this.get(
      `/?limit=10${module ? "&module=" + module : ""}`,
      {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      }
    );
    return response;
  };

  readNotification = async (id, read) => {
    const response = await this.put(
      `/${id}/read/${read}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      }
    );
    return response;
  };
}

export default new UsersService();
