import React from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";

const questionnary = [
  {
    question:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta beatae accusantium commodi, illo pariatur, numquam ab temporibus laborum ratione quae eaque sapiente dolorem ea voluptatum dolore inventore ut totam a!",
    answers: [
      {
        text: "Answer 1",
        important: false,
      },
      {
        text: "Answer 2",
        important: false,
      },
      {
        text: "Answer 3",
        important: true,
      },
    ],
  },
  {
    question:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem, blanditiis.",
    answers: [
      {
        text: "Answer 4",
        important: false,
      },
      {
        text: "Answer 5",
        important: false,
      },
      {
        text: "Answer 6",
        important: true,
      },
    ],
  },
  {
    question:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Molestiae cumque debitis soluta eum aliquid aliquam!",
    answers: [
      {
        text: "Answer 7",
        important: false,
      },
      {
        text: "Answer 8",
        important: false,
      },
      {
        text: "Answer 9",
        important: true,
      },
    ],
  },
  {
    question:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Molestiae cumque debitis soluta eum aliquid aliquam!",
    answers: [
      {
        text: "Answer 10",
        important: false,
      },
      {
        text: "Answer 11",
        important: false,
      },
      {
        text: "Answer 12",
        important: true,
      },
    ],
  },
];

const QuestionnaryForm = () => {
  return (
    <React.Fragment>
      <h4>You must complete the following questionnaire</h4>
      <Row>
        {questionnary.map((q, i) => (
          <Col sm={12} md={6} key={i}>
            <FormGroup check className="form-check-radio">
              <p>{q.question}</p>
              <dl>
                {q.answers.map((answer, j) => (
                  <dt key={j}>
                    <Label check>
                      <Input
                        defaultValue={answer.text}
                        id={`answer-${i}.${j}`}
                        name={`question-${i}`}
                        type="radio"
                      />
                      <span className="form-check-sign" />
                      {answer.text}
                    </Label>
                  </dt>
                ))}
              </dl>
            </FormGroup>
          </Col>
        ))}
      </Row>
    </React.Fragment>
  );
};

export default QuestionnaryForm;
