/**
 * Get param value of url
 * @param {string} param search in url
 */
const useUrlParams = (param) => {
  /**
   * Search param
   * @param {string} name param name
   */
  const getUrlParameter = (name) => {
    // name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    name = name.replace(/[[]/, "[").replace(/[\]]/, "\\]");
    let regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    let results = regex.exec(window.location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  };
  /**
   * return param name
   */
  return getUrlParameter(param);
};

export default useUrlParams;
